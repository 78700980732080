import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {doGet, doPost, doDelete, doPut,doPatch} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import SubDetail from './SubDetail.js'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const FormEdit =(props)=> {
  const {row, userActions, title, user, handleClose, handleSave, pageAction} = props
  const classes = useStyles();
  const [state, setstate] = useState(null)

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  useEffect(() => {
      if(row){
      	getRowComplete()
      }
  }, [row])

  const getRowComplete =async()=>{
    let params = {}
    
    const response = await doGet(endpoint.role_id_relation.replace("id",row.id), params, getDefaultHeader())
    if(response){
      //const modifiedData = response.data.data.map(row=>({...row, selected:false}))
      //setDataTable(modifiedData)
      setstate(response.data)

    }
  }

  const toggleMenu = async (roleId, menuId, toggle)=>{
    const payload = {
      roleId:roleId,
      menuId:menuId,
      toggle:toggle
    }
    
    const response = await doPatch(endpoint.role_menu, payload,"toggle menu" ,getDefaultHeader())
    getRowComplete()
  }

  const toggleAction= async(roleId, actionId, toggle)=>{
    const payload = {
      roleId:roleId,
      actionId:actionId,
      toggle:toggle
    }
    
    const response = await doPatch(endpoint.role_action, payload,"toggle action" ,getDefaultHeader())
    getRowComplete()
  }

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    if(fieldName==="status"){
      value = eventValue.target.checked? 1:0      
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const submit = async()=>{
    const response = await handleSave(state)
    handleClose()
  }

  if(state){
    return (
      <>
        <DialogTitle id="form-dialog-title">Edit</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={2} justify='space-between' className={classes.root}>
              <Grid container item xs={12} sm={12} md={4} lg={4} justify='flex-start' alignItems='flex-start' alignContent="flex-start" className={classes.left}>
                <Grid container alignContent="center" justify="center" className={classes.header}>
                  <Typography variant="h6">Data {title}</Typography>
                </Grid>
                <Grid container justify='center' alignItems='flex-start' className={classes.content}>
                  <Grid container justify='center' alignItems='flex-start'>
                    <DebouncedTextField
                      margin="dense"
                      id="Id"
                      label="Id"
                      fullWidth
                      value={state.id}
                      readOnly
                    />
                  </Grid>
                  <Grid container justify='center' alignItems='flex-start'>
                    <DebouncedTextField
                      margin="dense"
                      id="Name"
                      label="Name"
                      fullWidth
                      value={state.name}
                      onChange={(e)=>handleChange("name",e)}
                    />
                  </Grid>

                  <Grid container justify='center' alignItems='flex-start'>
                    <DebouncedTextField
                      margin="dense"
                      id="Code"
                      label="Code"
                      fullWidth
                      value={state.code}
                      onChange={(e)=>handleChange("code",e)}
                    />
                  </Grid>

                  <Grid container justify='center' alignItems='flex-start'>
                    <DebouncedTextField
                      margin="dense"
                      id="Description"
                      label="Description"
                      fullWidth
                      value={state.description}
                      onChange={(e)=>handleChange("description",e)}
                    />
                  </Grid>

                  <Grid container justify='flex-start' alignItems='center'>
                    <FormControlLabel
                      value="status"
                      control={
                          <Switch 
                          color="primary" 
                          checked={Number(state.status)===1} 
                          onChange={(e)=>handleChange("status",e)}                        
                          />
                        }
                      label="Status"
                      labelPlacement="start"
                      style={{marginLeft:0}}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item item xs={12} sm={12} md={7} lg={7} justify='center' alignItems="flex-start" alignContent="flex-start" className={classes.right}>
                  <SubDetail
                    userActions={userActions}
                    pageAction={pageAction}
                    row={state}
                    user={user}
                    toggleAction={toggleAction}
                    toggleMenu={toggleMenu}/>
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Kembali
          </Button>
          <Button variant="contained" onClick={submit} color="primary">
            Simpan
          </Button>
        </DialogActions>
    </>

      );

  }else{
    return null
  }

}

export default FormEdit;

const useStyles = makeStyles((theme) => ({
  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )',
    borderTop: '1px solid',
    borderColor:theme.borderColor
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
