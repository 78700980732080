import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenu } from "reduxs/actions";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon'


const HomeContent =(props)=> {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const dispatch = useDispatch()

  const menuClick =(menu)=>{
    dispatch(setActiveMenu(menu))
    props.history.push(menu.webPath)
  }

  return (
    <Grid container justify='center'>
      <Grid container item xs={12} sm={12} md={10} lg={10} spacing={2} justify='center' alignItems='center' alignContent="center">
        {
          user && user.menus.map(menu=>(
            <Grid onClick={()=>menuClick(menu)} container item xs={5} sm={5} md={3} lg={3} className={classes.tile} alignContent="stretch">
              <Grid justify="center" container alignItems='center' alignContent="center">
                <Icon>{menu.icon}</Icon>
              </Grid>
              <Grid container justify="center" alignItems='center' alignContent="stretch">
                <Typography variant="h6">{menu.display}</Typography>
              </Grid>
            </Grid>

          ))
        }
      </Grid>
    </Grid>
    );
}

export default HomeContent;



export const useStyles = makeStyles((theme) => ({
  tile:{
    color:"#ffffff",
    margin:theme.spacing(2),
    height:100,
    borderRadius:theme.borderRadius,
    background:'#3ae374',
    boxShadow:'3px 3px 5px 0px rgba(50, 50, 50, 0.35)',
    cursor:'pointer'
  }

}));
