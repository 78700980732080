import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { doGet, doPost, doDelete, doPut } from 'utils/apiUtil.js'
import { endpoint } from 'utils/constants.js'
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Protected from 'components/Protected.js';
import Typography from '@material-ui/core/Typography';
import { selectCustomZindex } from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { FormControlLabel, Switch } from '@material-ui/core';
import AsyncSelect from 'react-select/async';
import { generalListOptionMapper, userListOptionMapper } from 'utils/mappers';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { format, parse } from 'date-fns'



const ClientDetail = (props) => {
  const { row = {}, userActions = [], closeMainDialog, getData } = props
  const setting = useSelector(state => state.setting);
  const user = useSelector(state => state.user);
  const classes = useStyles();
  const initial = {
    id: undefined,
    code: null,
    name: undefined,
    webPath: undefined,
    mobileRoute: undefined,
    icon: undefined,
    isActive: 1,
    productionDate: null,
    paidStudent: 0,
    subscribePrice:0
  }
  const [state, setstate] = useState(initial)
  const [nameKeyWord, setnameKeyWord] = useState('');
  const [pageAction, setpageAction] = useState('')


  const getDefaultHeader = () => {
    return { "Authorization": `Bearer ${user.token}` }
  }

  const generateKey = async () => {
    const response = await doPost(endpoint.client_key + "/" + row.id, {}, "generateKey " + setting.active_menu.display, getDefaultHeader())
    getData()
    return response
  }

  const handleChange = (fieldName, eventValue) => {
    let newState = { ...state }
    let value = eventValue

    newState[fieldName] = value
    setstate(newState)
  }

  const insert = async (payload) => {
    const response = await doPost(endpoint.client, payload, "insert " + setting.active_menu.display, getDefaultHeader())
    if (response && response.data) {
      getData()
    } else {

    }
  }

  const update = async (payload) => {
    const response = await doPut(endpoint.client, payload, "update " + setting.active_menu.display, getDefaultHeader())
    if (response && response.data) {

      getData()
    } else {

    }
  }

  const submit = async () => {
    const params = {
      ...state,
      user: state.user.id ? state.user.id : state.user,
      productionDate: state.productionDate ? format(state.productionDate, 'yyyy-MM-dd') : null,
      paidStudent:Number(state.paidStudent),
      subscribePrice:Number(state.subscribePrice)
    }

    if (pageAction === "CCLIE") {
      insert(params)
    } else {
      update(params)
    }
  }

  const getRowDetail = async () => {
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row,
      productionDate:parse(row.productionDate, "yyyy-MM-dd", new Date()),
      
    })
  }

  const getUserOptions = async (keyword) => {
    const params = {
      keyword: keyword
    }
    const response = await doGet(endpoint.user_options, params, getDefaultHeader())

    if (response) {
      return userListOptionMapper(response.data)
    } else {
      return []
    }
  }

  const loadOptions = async (inputValue, callback) => {
    callback(await getUserOptions(inputValue))
  };

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    setnameKeyWord(inputValue)
    return inputValue;
  };

  const onSelectUser = (user) => {
    setstate({ ...state, user: user })
  }

  useEffect(() => {
    setpageAction(props.pageAction)

  }, [props.pageAction])

  useEffect(() => {
    if (row && pageAction !== 'CCLIE') {
      getRowDetail()
    } else {
      setstate(initial)
    }
  }, [row])



  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2} justify='space-between' className={classes.root}>
          <Grid container justify='center' alignItems='flex-start' className={classes.content}>
            <Grid container justify='center' alignItems='flex-start'>
              <DebouncedTextField
                margin="dense"
                id="Id"
                label="Id"
                fullWidth
                value={state.id}
              />
            </Grid>


            <Conditional condition={pageAction === "READ"}>
              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="user"
                  label="user"
                  fullWidth
                  value={state.user && state.user.username}
                />
              </Grid>
            </Conditional>

            <Conditional condition={pageAction !== "READ"}>
              <Grid item xs={12}>
                <AsyncSelect
                  placeholder="Select user"
                  cacheOptions
                  value={state.user}
                  loadOptions={loadOptions}
                  onInputChange={handleInputChange}
                  onChange={onSelectUser}
                />
              </Grid>
            </Conditional>

            <Grid container justify='center' alignItems='flex-start'>
              <DebouncedTextField
                margin="dense"
                id="name"
                label="name"
                fullWidth
                value={state.name}
                onChange={(e) => handleChange("name", e)}
              />
            </Grid>

            <Grid container justify='center' alignItems='flex-start'>
              <DebouncedTextField
                margin="dense"
                id="type"
                label="type"
                fullWidth
                value={state.type}
                onChange={(e) => handleChange("type", e)}
              />
            </Grid>

            <Grid container justify='center' alignItems='flex-start'>
              <DebouncedTextField
                margin="dense"
                id="subPrice"
                label="Subscription Price"
                fullWidth
                type="number"  
                value={state.subscribePrice}
                onChange={(e) => handleChange("subscribePrice", e)}
              />
            </Grid>

            <Grid container justify='center' alignItems='flex-start'>
              <DebouncedTextField
                margin="dense"
                id="userstudent"
                label="total student"
                fullWidth
                value={state.userstudent}
                onChange={(e) => handleChange("userstudent", e)}
              />
            </Grid>

            <Grid container justify='center' alignItems='flex-start'>
              <DebouncedTextField
                margin="dense"
                id="paidStudent"
                label="paid student"
                fullWidth
                type="number"  
                value={state.paidStudent}
                onChange={(e) => handleChange("paidStudent", e)}
              />
            </Grid>

            <Grid container justify='center' alignItems='flex-start'>
              <DebouncedTextField
                margin="dense"
                id="userstaff"
                label="user staff"
                fullWidth
                value={state.userstaff}
                onChange={(e) => handleChange("userstaff", e)}
              />
            </Grid>

            <Grid container justify='center' alignItems='flex-start'>
              <DebouncedTextField
                margin="dense"
                id="address"
                label="address"
                fullWidth
                value={state.address}
                onChange={(e) => handleChange("address", e)}
              />
            </Grid>

            <Grid container justify='center' alignItems='flex-start'>
              <DebouncedTextField
                margin="dense"
                id="email"
                label="email"
                fullWidth
                value={state.email}
                onChange={(e) => handleChange("email", e)}
              />
            </Grid>

            <Grid container justify='center' alignItems='flex-start'>
              <DebouncedTextField
                margin="dense"
                id="phone"
                label="phone"
                fullWidth
                value={state.phone}
                onChange={(e) => handleChange("phone", e)}
              />
            </Grid>
            <Conditional condition={pageAction !== "CCLIE"}>
              <Grid container spacing={1} justify='space-between' alignItems='center'>
                <Grid item xs={8} >
                  <DebouncedTextField
                    margin="dense"
                    id="key"
                    label="key"
                    fullWidth
                    value={state.key}
                    readOnly
                  />
                </Grid>
                <Conditional condition={pageAction === "ECLIE"}>
                  <Grid item xs={4}>
                    <Button onClick={generateKey} variant="contained" color="primary" size='small' className={classes.smallButton}>
                      generate
                    </Button>
                  </Grid>
                </Conditional>
              </Grid>
            </Conditional>


            <Grid container justify='center' alignItems='flex-start'>
              <DebouncedTextField
                margin="dense"
                id="mobilePhone"
                label="mobilePhone"
                fullWidth
                value={state.mobilePhone}
                onChange={(e) => handleChange("mobilePhone", e)}
              />
            </Grid>

            <Grid container justify='center' alignItems='flex-start'>
              <DebouncedTextField
                margin="dense"
                id="host"
                label="host"
                fullWidth
                value={state.host}
                onChange={(e) => handleChange("host", e)}
              />
            </Grid>

            <Grid container justify='center' alignItems='flex-start'>
              <DebouncedTextField
                margin="dense"
                id="serverAuth"
                label="serverAuth"
                fullWidth
                value={state.serverAuth}
                onChange={(e) => handleChange("serverAuth", e)}
              />
            </Grid>

            <Conditional condition={pageAction !== "CCLIE"}>
              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="code"
                  label="code"
                  fullWidth
                  value={state.code}
                  onChange={(e) => handleChange("code", e)}
                />
              </Grid>
            </Conditional>

            <Grid container>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={state.productionDate}
                  onChange={(e) => handleChange("productionDate", e)}
                  label="Production Date"
                  variant="inline"
                />
              </MuiPickersUtilsProvider>
            </Grid>

          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Conditional condition={pageAction === "READ"}>
          <Protected user={user} userActions={userActions} allowedCodes={['ECLIE']} activeMenu={setting.active_menu}>
            <Button onClick={() => setpageAction("ECLIE")} color="primary">
              Edit
            </Button>
          </Protected>
        </Conditional>
        <Button onClick={closeMainDialog} color="secondary">
          Kembali
        </Button>
        <Conditional condition={pageAction !== "READ"}>
          <Button variant="contained" onClick={submit} color="primary">
            Simpan
          </Button>
        </Conditional>
      </DialogActions>
    </>

  );
}

export default ClientDetail;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 0'
  },
  dialogContent: {
    background: 'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )'
  },
  smallButton: {
    height: 30,
    padding: '0 4px 0 4px',
    fontSize: '0.7em',
    margin: '0 4px 0 4px'
  },
  margin1: {
    margin: theme.spacing(1)
  },
  content: {
    backgroundColor: "#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width: 'inherit',
  },
  header: {
    borderRadius: theme.borderRadius,
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
