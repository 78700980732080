import React,{useState, useEffect, useRef} from 'react';
import { Redirect,useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { useDebounce } from 'react-use';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Cancel from '@material-ui/icons/Cancel';
import Send from '@material-ui/icons/Send';
import FilterList from 'components/FilterList';
import FilterComponent   from 'components/FilterComponent.js';
import DeleteConfirmation   from 'components/DeleteConfirmation.js';
import Popover from '@material-ui/core/Popover';
import Select from 'react-select'
import Dialog from '@material-ui/core/Dialog';
import {generalListOptionMapper} from 'utils/mappers.js'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination   from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import StatusChip from 'components/StatusChip';
import ActionDisplay from 'components/ActionDisplay.js';
import Protected from 'components/Protected.js';
import AsyncSelect from 'react-select/async';
import Conditional from 'components/Conditional';
import ClientPaymentChannelForm from './ClientPaymentChannelForm';
import { DialogActions } from '@material-ui/core';


const ClientPaymentChannel =(props)=> {
  const {row={},userActions=[], closeMainDialog} = props  
  const isInitialMount = useRef(true);
  let { path, url } = useRouteMatch();
  const user = useSelector(state => state.user);
  const setting = useSelector(state => state.setting);  
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const [filters, setFilters] = useState({keyword:''})
  const [filtersAvailable, setfiltersAvailable] = useState([
    {name:'status', display:'status', type:'select'},
    {name:'jenjang', display:'jenjang', type:'select'}
  ])
  const [filterBy, setfilterBy] = useState([])
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [anchorFilter, setanchorFilter] = useState(null);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  const popupFilterClose = () => {
    setanchorFilter(null);
  };

  const addFilter =(field)=>{
    let filtersTemp = {...filters}
    filtersTemp[field.name] = null
    setFilters(filtersTemp)
    let newFiltersAvailable = filtersAvailable.filter(f=>f.name!==field.name)
    setfiltersAvailable(newFiltersAvailable)
    setfilterBy([...filterBy,field])
  }


  const getPaymentChannelOfClient =async()=>{
    let params = {
      clientId:row.id
    }
    
    const response = await doGet(endpoint.client_payment_channel, params, getDefaultHeader())
    
    if(response && response.data){
      const modifiedData = response.data.map(row=>({
        ...row
      }))
      setDataTable(modifiedData)      
    }
  }


  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    let rowPaymentChannel=undefined
    if(rowParam){
      rowPaymentChannel = rowParam
    }else{
      rowPaymentChannel = selectedRow
    }
    setMaxWidth('md');
    setdialogContent(
      <ClientPaymentChannelForm
      row={row}
      paymentChannel={rowPaymentChannel}
      getPaymentChannelOfClient={getPaymentChannelOfClient}
      closeSubDialog={closeSubDialog}
      />
    );

    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeSubDialog}
        handleDelete={handleDeletePaymentChannel}
        selectedIds={selectedIds}
        title="PaymentChannel"
      />
    );

    setopenDialog(true);
  };


  const closeSubDialog =()=>{
    setopenDialog(false)
    if(pageAction==='READ'){
      setselectedRow(null)
      setselectedIds([])
    }
  }  

  const handleDeletePaymentChannel = async () => {
    const params = {
      client_id:row.id,
      channel_ids: selectedIds
    };
    const response = await doDelete(endpoint.client_payment_channel, params,"delete "+setting.active_menu.display ,getDefaultHeader())
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      getPaymentChannelOfClient();
    }else{
    }
  };

  const toggleSelectRow =(row)=>{
    if(selectedIds.includes(row.id)){
      const ids = selectedIds.filter(item=>item!==row.id)
      setselectedIds(ids)
      
      if(ids.length===1){
        const existingRow = dataTable.filter(data=>(data.id===ids[0]))
        setselectedRow(existingRow[0])
      }
      else{
        setselectedRow(null)
      }      
      
    }else{      
      setselectedIds([...selectedIds,row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow=()=>{
    if(selectedIds.length === dataTable.length){
      setselectedIds([])
    }else{
      setselectedIds(dataTable.map(row=>row.id))
    }
  }

    
  useEffect(() => {
    getPaymentChannelOfClient()
  }, [])

  useDebounce(
    () => {
      if (isInitialMount.current) {
          isInitialMount.current = false;
      } else {
        getPaymentChannelOfClient()        
      }        
    },
    700,
    [filters.keyword]
  );   

    return (
      <>
        <Grid container justify='flex-start' alignItems='flex-start' alignContent='flex-start' className={classes.root}>
            <Grid container justify="flex-end" alignItems="center" className={classes.toolbarContainer}>                            
              {
                selectedIds.length>0 &&
                <Protected user={user}  userActions={userActions} allowedCodes={['DCHANNEL']} activeMenu={setting.active_menu}>
                    <Button onClick={showDeleteConfirmation} variant='contained' color='secondary' size='small' className={classes.smallButton}>
                      <ActionDisplay userActions={userActions} code='DCHANNEL'/>
                    </Button>
                  </Protected>
              }
      
              <Protected user={user}  userActions={userActions} allowedCodes={['ACHANNEL']} activeMenu={setting.active_menu}>
                <Button onClick={()=>showDialog('ACHANNEL')} variant='contained' color='primary' size='small' className={classes.smallButton}>
                  <ActionDisplay userActions={userActions} code='ACHANNEL'/>
                </Button>
              </Protected>
            </Grid>
            <Grid container className={classes.tableContainer}>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          inputProps={{ 'aria-label': 'select all' }}
                          onChange={toggleSelectAllRow}
                          checked={selectedIds.length === dataTable.length && dataTable.length>0}
                        />
                      </TableCell>
                      <TableCell >Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataTable.map((row) => (
                      <TableRow key={row.id} onClick={()=>toggleSelectRow(row)}>
                        <TableCell>
                        <Checkbox
                            checked={selectedIds.includes(row.id)}                            
                            color="primary"
                            inputProps={{ 'aria-label': 'select all' }}
                          />
                        </TableCell>
                        <TableCell onClick={()=>showDialog('READ',row)} className={classes.clickable}>{row.name}</TableCell>                                                                
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                
              </TableContainer>
            </Grid>

        </Grid>

        <DialogActions>
          <Button onClick={closeMainDialog} color="secondary">
            Kembali
          </Button>
        </DialogActions>

        <Dialog
        open={openDialog}
        maxWidth={maxWidth}
        onClose={closeSubDialog}
        fullWidth
        fullScreen={isDialogFull}
        scroll="body"
        >
          {dialogContent}
        </Dialog>

        <Popover
          id='popUpFilter'
          open={Boolean(anchorFilter)}
          anchorEl={anchorFilter}
          onClose={popupFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          className={classes.popUpFilter}
          style={
            {marginTop:8}
          }
        >
          <FilterList fields={filtersAvailable} filters={filters} onItemClick={addFilter} onClose={popupFilterClose}/>
        </Popover>


        </>
    );
  }
  

export default ClientPaymentChannel;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
 toolbarContainer: {
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(1)
  },
  filterContainer: {
    marginBottom:theme.spacing(1)
  },
  tableContainer: {
    minHeight:400,
  },
  clickable:{
    cursor:'pointer',
    fontWeight:'bold',
    color:theme.palette.primary.main
  },
  formContainer: {
    minHeight:'80vh',
    marginTop:theme.spacing(1)
  },
  buttonContainer:{
    marginTop:theme.spacing(2)
  },
  formPaper:{
    width:'inherit',
    padding:theme.spacing(2)
  },
  paginatonContainer: {
    height:40,
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  marginB1:{
    marginBottom:theme.spacing(1)
  },
  reactSelect:{
    width:'100%'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  closeButton:{
    top:-20,
    marginLeft:'auto',
    marginRight:0
  },
  tableContainer: {
    minHeight:400,
  },
  clickable:{
    cursor:'pointer',
    fontWeight:'bold',
    color:theme.palette.primary.main
  },
  buttonContainer:{
    marginTop:theme.spacing(2)
  },

  paginatonContainer: {
    height:40,
  }

}));

const selectCustomZindex = {
    control: (base, state) => ({
        ...base,
        minWidth: '150px',
        margin: '0 4px'
    }),
    container: (base, state) => {
        return {
            ...base,
            flex: 1,
            zIndex: state.isFocused ? '1100' : '1' //Only when current state focused
        };
    }
};