import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const FormEdit =(props)=> {
  const {row, userActions, title, user, handleClose, handleSave} = props
  const classes = useStyles();
  const [state, setstate] = useState({})

  useEffect(() => {
      if(row){
      	setstate(row)
      }
  }, [row])

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    newState[fieldName] = value
    setstate(newState)
  }

  const submit = async()=>{
    const response = await handleSave(state)
    handleClose()
  }


  return (
    <>
      <DialogTitle id="form-dialog-title">Edit {title}</DialogTitle>
      <DialogContent>
        <Grid container item xs={12} justify='center'>
          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="clientId"
              label="clientId"
              fullWidth
              value={state.clientId}
              onChange={(e)=>handleChange("clientId",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="amount"
              label="amount"
              fullWidth
              value={state.amount}
              onChange={(e)=>handleChange("amount",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="beneficiaryName"
              label="beneficiaryName"
              fullWidth
              value={state.beneficiaryName}
              onChange={(e)=>handleChange("beneficiaryName",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="beneficiaryAccount"
              label="beneficiaryAccount"
              fullWidth
              value={state.beneficiaryAccount}
              onChange={(e)=>handleChange("beneficiaryAccount",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="beneficiaryBank"
              label="beneficiaryBank"
              fullWidth
              value={state.beneficiaryBank}
              onChange={(e)=>handleChange("beneficiaryBank",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="notes"
              label="notes"
              fullWidth
              value={state.notes}
              onChange={(e)=>handleChange("notes",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="referenceNo"
              label="referenceNo"
              fullWidth
              value={state.referenceNo}
              onChange={(e)=>handleChange("referenceNo",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="createBy"
              label="createBy"
              fullWidth
              value={state.createBy}
              onChange={(e)=>handleChange("createBy",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="created_at"
              label="created_at"
              fullWidth
              value={state.created_at}
              onChange={(e)=>handleChange("created_at",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="status"
              label="status"
              fullWidth
              value={state.status}
              onChange={(e)=>handleChange("status",e)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>
</>

    );
}

export default FormEdit;

const useStyles = makeStyles((theme) => ({

}));
