import React,{useState} from 'react';
import AlkaSnackbar from 'components/AlkaSnackbar'
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import clsx from "clsx";
import Drawer from '@material-ui/core/Drawer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Route,Redirect,Switch,useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, setActiveMenu,hideSnackbar } from "reduxs/actions";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Dashboard from "features/dashboard/Dashboard.js"
import Menu from "features/menu/Menu.js"
import Parameter from "features/parameter/Parameter.js"
import Role from "features/role/Role.js"
import Product from "features/product/Product.js"
import Billing from "features/billing/Billing.js"
import Pays from "features/pays/Pays.js"
import User from "features/user/User.js"
import Client from "features/client/Client.js"
import Module from "features/module/Module.js"
import Transaction from "features/transaction/Transaction.js"
import PaymentChannel from "features/channel/PaymentChannel.js"
import BankAccount from "features/bank/BankAccount.js"
import Wallet from "features/wallet/Wallet.js"
import Employee from "features/employee/Employee.js"
import Discount from "features/discount/Discount.js"
import Vendor from "features/vendor/Vendor.js"
import Gateway from "features/gateway/Gateway.js"
import HomeContent from "./HomeContent.js"
import Tester from "features/tester/Tester.js"
import Seller from "features/seller/Seller.js"
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
import Person from '@material-ui/icons/Person';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Popover from '@material-ui/core/Popover';
import Profile from './Profile.js';
import Sidebar from './Sidebar.js';
import LinearProgress from '@material-ui/core/LinearProgress';
import Conditional   from 'components/Conditional.js';
import { useLocalStorage } from 'react-use';



const Home =(props)=> {
  const [persistedUser, persistUser, clearPersistedUser] = useLocalStorage('user', {});
  const classes = useStyles()
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  let { path, url } = useRouteMatch();
  const dispatch = useDispatch()
  const closeSnackBar = () => {dispatch(hideSnackbar())}
  const ui = useSelector(state => state.ui);
  const user = useSelector(state => state.user);
  const setting = useSelector(state => state.setting);
  const [anchorProfile, setAnchorProfile] = React.useState(null);
  const [openMenu, setopenMenu] = React.useState(false);


  const closeMenu = () => {
    setopenMenu(false);
  };
  
  const profileClick = (event) => {
    setAnchorProfile(event.currentTarget);
  };

  const profileClose = () => {
    setAnchorProfile(null);
  };

  const onLogout = () => {
    clearPersistedUser()
    dispatch(logout());
  };

  const redirect =(menu)=>{
    closeMenu()
    dispatch(setActiveMenu(menu))
    props.history.push(menu.webPath)
  }

  const goHome =(path)=>{
    props.history.push("/home")
  }



  if(user && user.token){
    return (
      <Grid container justify="center" className={classes.root}>
        <Hidden mdDown>
          <Grid container alignItems="flex-start" alignContent="flex-start" item lg={2} className={classes.sideBar}>
            <Grid container justify="center" alignContent="center" className={classes.sideBarHeader}>
              <Typography onClick={goHome} color="primary" variant="h6" style={{fontWeight:900, cursor:'pointer'}}>
                System
              </Typography>
            </Grid>
            <Grid container justify="center" alignItems='flex-start' alignContent="flex-start" className={classes.sideBarContent}>
              <Sidebar justify="center" user={user} itemClick={redirect} activeMenu={setting.active_menu}/>
            </Grid>
          </Grid>
        </Hidden>  

        <Grid container item xs={12} sm={12} md={12} lg={10} className={classes.main}>                    
          <Grid container alignContent="center" className={clsx({
              [classes.mainAppBarFull] : !lg,
              [classes.mainAppBar] : lg
          })}
          >
            <Grid xs={6} sm={6} md={6} lg={6} container alignItems="center" alignContent="center" className={classes.sideBarHeader}>
              <Hidden lgUp>
                <IconButton
                onClick={()=>setopenMenu(true)}
                size="medium"
                aria-label="menu"
                style={{margin:0}}>
                  <MenuIcon color="primary" />
                </IconButton>
                  
                <Typography onClick={goHome} color="primary" variant="h6" style={{fontWeight:900, cursor:'pointer'}}>
                  System
                </Typography>
              </Hidden>
            </Grid>

            <Grid xs={6} sm={6} md={6} lg={6} container justify="flex-end" alignContent="center">
              <Avatar onClick={profileClick} className={classes.avatarProfile}>
                <Person />
              </Avatar>
            </Grid> 

            <Grid container className={classes.progressBarWrapper}>
              <Conditional condition={ui&&ui.loading===true}>
                <LinearProgress className={classes.progressBar}/>
              </Conditional>
            </Grid>           
          </Grid>

          <Grid container className={classes.mainContent}>
            <Switch>
              <Route exact path={`${url}/`} component={HomeContent}/>
              <Route path={`${url}/dashboard`} component={Dashboard}/>
              <Route path={`${url}/menu`} component={Menu}/>
              <Route path={`${url}/parameter`} component={Parameter}/>
              <Route path={`${url}/role`} component={Role}/>
              <Route path={`${url}/product`} component={Product}/>
              <Route path={`${url}/billing`} component={Billing}/>
              <Route path={`${url}/user`} component={User}/>
              <Route path={`${url}/client`} component={Client}/>
              <Route path={`${url}/module`} component={Module}/>
              <Route path={`${url}/pays`} component={Pays}/>
              <Route path={`${url}/transaction`} component={Transaction}/>
              <Route path={`${url}/channel`} component={PaymentChannel}/>
              <Route path={`${url}/bank-account`} component={BankAccount}/>
              <Route path={`${url}/wallet`} component={Wallet}/>
              <Route path={`${url}/discount`} component={Discount}/>
              <Route path={`${url}/employee`} component={Employee}/>
              <Route path={`${url}/vendor`} component={Vendor}/>
              <Route path={`${url}/gateway`} component={Gateway}/>
              <Route path={`${url}/tester`} component={Tester}/>
              <Route path={`${url}/seller`} component={Seller}/>
            </Switch>
          </Grid>
        </Grid>

        <AlkaSnackbar
          show={ui.snack_show}
          variant={ui.snack_var}
          txt={ui.snack_txt}
          close={closeSnackBar}
        />

        <Popover
          id="popUpProfile"
          open={Boolean(anchorProfile)}
          anchorEl={anchorProfile}
          onClose={profileClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className={classes.popUpProfile}
          style={
            {marginTop:8}
          }
        >
          <Profile user={user} logout={onLogout}/>
        </Popover>

        <Drawer anchor="top" open={openMenu} onClose={closeMenu}>
          <Sidebar onClose={closeMenu} justify="flex-start" user={user} itemClick={redirect} activeMenu={setting.active_menu}/>
        </Drawer>

      </Grid>
    );
  }else{
    return <Redirect to={{ pathname: "/login" }} />
  }
}

export default Home




export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:theme.palette.defaultBackground,
    height:'100vh',
    overflowY:'hidden'
  },
  sideBar:{
    height:'100vh',
    borderRight:'1px #e1e2e4 solid'
  },
  sideBarHeader:{
    height:'3.5rem',
  },
  sideBarContent:{
    height:'91vh',
    overflowY:'scroll',
  },
  main:{
    height:'100vh',
    overflowY:'scroll'
  },
  mainAppBar:{
    position:'fixed',
    display: 'flex',
    padding:theme.spacing(2),
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    height:'3.5rem',
    left:'16.7%',
    width:'83.2%',
    backgroundColor: 'rgb(255, 255, 255)',
    boxShadow:'rgba(25, 42, 70, 0.13) 20px 8px 20px 0px',
    zIndex:1100
  },
  mainAppBarFull:{
    position:'fixed',
    display: 'flex',
    padding:theme.spacing(2),
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    height:'3.5rem',    
    backgroundColor: 'rgb(255, 255, 255)',
    boxShadow:'rgba(25, 42, 70, 0.13) 20px 8px 20px 0px',
    zIndex:1100
  },
  mainContent:{
    minHeight:'91vh',
    marginTop:'3.5rem'
  },

  avatarProfile:{

  },

  role:{
    padding:4,
    border:'1px solid',
    borderColor:theme.borderColor,
    margin:'0 2px 0 2px',
    borderRadius:4
  },
  popUpProfile:{
    top:theme.spacing(2),
  },
  progressBar:{
    zIndex:1300,
    width:'inherit'
  },
  progressBarWrapper:{
    height:1
  }

}));
