import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {endpoint} from 'utils/constants.js'
import {doGet} from 'utils/apiUtil.js'
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Conditional from 'components/Conditional.js';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import Protected   from 'components/Protected.js';
import SmallConfirmation   from 'components/SmallConfirmation.js';
import DebouncedTextField from 'components/DebouncedTextField.js';


const RoutesDetail = (props)=>{
  const {user, userActions, gateway, addRoute, removeRoute, updateRoute, activeMenu, pageAction} = props  
  const classes = useStyles()
  const [anchorSmallConfirmation, setanchorSmallConfirmation] = useState(null);
  const [route, setroute] = useState(null)
  const [todo, settodo] = useState(null)

  const addNewClick=()=>{
    settodo("add")
    setroute({
      id:gateway.routes.length+1,
      name:"",
      path:"",
      gateway:gateway?gateway.id:undefined
    })
  }

  const editClick=(route)=>{
    settodo("edit")
    setroute(route)
  }

  const cancelClick=()=>{
    settodo(null)
    setroute(null)
  }
  
  const removeClick=(route,e)=>{
    settodo("delete")
    setroute(route)
    setanchorSmallConfirmation(e.currentTarget)
  }

  const closeSmallConfirmation=()=>{
    setanchorSmallConfirmation(null)
    settodo(null)
    setroute(null)
  }

  const execute=()=>{

    if(todo==="add"){
      addRoute(route)
    }
    else if(todo==="edit"){
        updateRoute(route)
    }
    else if(todo==="delete"){
        removeRoute(route)
    }
    settodo(null)
    setroute(null)
  }  

  const routeChange=(field,e)=>{
    setroute({...route,[field]:e})
  }

    
  return(
    <>
    <Grid container style={{marginBottom:8}}>
        <Grid container alignContent="center" justify="center" className={classes.header}>
          <Typography variant="h6">Data Routes</Typography>
        </Grid>
        <Grid container spacing={1} alignItems="center" justify="flex-end" style={{marginBottom:8}}>
        {
          route &&
          <>        
        <Grid item xs={12} sm={12} md={4} lg={4} >
          <DebouncedTextField
            margin="dense"
            id="name"
            label="name"
            variant="outlined"
            fullWidth
            value={route.name}
            onChange={(e)=>routeChange("name",e)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <DebouncedTextField
            margin="dense"
            id="path"
            label="path"
            variant="outlined"
            fullWidth
            value={route.path}
            onChange={(e)=>routeChange("path",e)}
          />
        </Grid>

        </>
      }
      
        <Conditional condition={!["edit","add"].includes(todo) && pageAction!=="detail"}>
            <Grid container justify="flex-end" alignContent="center" style={{marginTop:8}}>              
                <Protected user={user} userActions={userActions} allowedCodes={['AROUTE']} >
                  <Button size="small" variant="contained" color="primary" className={classes.smallButton} onClick={addNewClick}>Tambah</Button>
                </Protected>                            
            </Grid>
        </Conditional>
        <Conditional condition={["edit","add"].includes(todo) && pageAction!=="detail"}>
            <Grid container item xs={12} sm={12} md={4} lg={4} justify="flex-end" alignContent="center">              
                <Protected user={user} userActions={userActions} allowedCodes={['EROUTE']} >
                  <Button size="small" variant="contained" color="primary" className={classes.smallButton} onClick={execute}>Simpan</Button>
                </Protected>              
                <Protected user={user} userActions={userActions} allowedCodes={['EROUTE']} >
                  <Button size="small" variant="contained" color="secondary" className={classes.smallButton} onClick={cancelClick}>Cancel</Button>
                </Protected>                            
            </Grid>
        </Conditional>
      </Grid>
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead className={classes.backgroundPrimary}>
          <TableRow>
            <TableCell align="center" style={{color:"#ffffff"}}>ID</TableCell>
            <TableCell align="center" style={{color:"#ffffff"}}>Name</TableCell>
            <TableCell align="center" style={{color:"#ffffff"}}>path</TableCell>
            <TableCell align="center" style={{color:"#ffffff"}}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {gateway.routes && gateway.routes.map((r) => (
            <TableRow key={r.id}>
              <TableCell align="center" >{r.id}</TableCell>
              <TableCell align="center" >{r.name}</TableCell>
              <TableCell align="center">{r.path}</TableCell>              
              <TableCell align="center">
                <Conditional condition={["edit","add"].includes(pageAction)}>                  
                  <Protected user={user} userActions={userActions} allowedCodes={['EROUTE']} >
                    <IconButton
                    onClick={()=>editClick(r)}
                    size="small"
                    aria-label="close"
                    className={clsx(classes.margin1,classes.closeButton)}>
                      <Edit fontSize="inherit" />
                    </IconButton>
                  </Protected>
                  <Protected user={user} userActions={userActions} allowedCodes={['DROUTE']} >
                    <Conditional condition={todo!=="edit"}>
                      <IconButton
                      onClick={(e)=>removeClick(r,e)}
                      size="small"
                      aria-label="close"
                      className={clsx(classes.margin1,classes.closeButton)}>
                        <Cancel fontSize="inherit" />
                      </IconButton>
                    </Conditional>
                  </Protected>
                </Conditional>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>
  <SmallConfirmation
    anchor={anchorSmallConfirmation}
    handleClose={closeSmallConfirmation}
    execute={execute}
    actionName={todo}/>
  </>
  )
}

export default RoutesDetail

const useStyles = makeStyles((theme) => ({
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  backgroundPrimary:{
    backgroundColor:theme.palette.primary.main,
    color:"#ffffff"
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
  },
}));
