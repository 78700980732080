import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const FormEdit =(props)=> {
  const {row, userActions, title, user, handleClose, handleSave} = props
  const classes = useStyles();
  const [state, setstate] = useState({})

  useEffect(() => {
      if(row){
      	setstate(row)
      }
  }, [row])

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    if(fieldName==="status"){
      value = eventValue.target.checked? 1:0      
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const submit = async()=>{
    const response = await handleSave(state)
    handleClose()
  }


  return (
    <>
      <DialogTitle id="form-dialog-title">Edit {title}</DialogTitle>
      <DialogContent>
        <Grid container item xs={12} justify='center'>
          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="Id"
              label="Id"
              fullWidth
              value={state.id}
              readOnly
            />
          </Grid>
          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="Name"
              label="Name"
              fullWidth
              value={state.name}
              onChange={(e)=>handleChange("name",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="Code"
              label="Code"
              fullWidth
              value={state.code}
              onChange={(e)=>handleChange("code",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="Value"
              label="Value"
              fullWidth
              value={state.value}
              onChange={(e)=>handleChange("value",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="Group"
              label="Group"
              fullWidth
              value={state.group}
              onChange={(e)=>handleChange("group",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="Description"
              label="Description"
              fullWidth
              value={state.description}
              onChange={(e)=>handleChange("description",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="KeyName"
              label="KeyName"
              fullWidth
              value={state.keyName}
              onChange={(e)=>handleChange("keyName",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="Key"
              label="Key"
              fullWidth
              value={state.key}
              onChange={(e)=>handleChange("key",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="Role"
              label="Role"
              fullWidth
              value={state.roleId}
              onChange={(e)=>handleChange("roleId",e)}
            />
          </Grid>

          <Grid container alignItems='flex-start' style={{marginTop:16}}>
            <FormControlLabel
                value="status"
                control={
                    <Switch 
                    color="primary" 
                    checked={Number(state.status)===1} 
                    onChange={(e)=>handleChange("status",e)}
                    />
                  }
                label="Status"
                labelPlacement="start"
                style={{marginLeft:0}}
              />
          </Grid>
        </Grid>  
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>
</>

    );
}

export default FormEdit;

const useStyles = makeStyles((theme) => ({

}));
