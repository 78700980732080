import React from 'react'

const ActionDisplay = ({userActions, code}) => {

    if(userActions){
      const action =  userActions.filter(a=>(a.code===code))[0];
      if(action){
        return action.display
      }else{
        return null
      }      
    }else{
      return null
    }
    
}

export default ActionDisplay;
