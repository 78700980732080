import React,{useState, useEffect} from 'react';
import { Redirect,useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setActions, setActiveMenu,hideSnackbar } from "reduxs/actions";
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { useDebounce } from 'react-use';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Cancel from '@material-ui/icons/Cancel';
import Send from '@material-ui/icons/Send';
import FilterList from 'components/FilterList';
import FormAdd   from './FormAdd.js';
import FormDetail   from './FormDetail.js';
import FormEdit   from './FormEdit.js';
import DataTable   from './DataTable.js';
import FilterComponent   from 'components/FilterComponent.js';
import Confirmation   from 'components/Confirmation.js';
import ActionDisplay   from 'components/ActionDisplay.js';
import Conditional from 'components/Conditional';
import Protected   from 'components/Protected.js';
import Popover from '@material-ui/core/Popover';
import Select from 'react-select'
import Dialog from '@material-ui/core/Dialog';
import {generalListOptionMapper, parameterListOptionMapper} from 'utils/mappers.js'



const Billing =(props)=> {
  
  const [userActions, setuserActions] = useState([])
  let { path, url } = useRouteMatch();
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const setting = useSelector(state => state.setting);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [filters, setFilters] = useState({keyword:""})
  const [filtersAvailable, setfiltersAvailable] = useState([
    {name:'status',display:'status', type:"select"}
  ])
  const [filterBy, setfilterBy] = useState([])
  const [statusOptions, setstatusOptions] = useState([{label:"aktif", value:1}, {label:"nonaktif", value:0}])
  const [action, setaction] = useState(null)
  const [openAddForm, setOpenAddForm] = useState(false);
  const [openForm, setopenForm] = useState(false);
  const [isDialogFull, setisDialogFull] = useState(false);
  const [openDetailForm, setopenDetailForm] = useState(false);
  const [openDeleteConfirmation, setopenDeleteConfirmation] = useState(false);
  const [anchorFilter, setanchorFilter] = useState(null);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [selectedRows, setselectedRows] = useState([])
  const [clientOptions, setclientOptions] = useState([])
  const [categoryOptions, setcategoryOptions] = useState([])
  const [discountOptions, setdiscountOptions] = useState([])
  const [countbyOptions, setcountbyOptions] = useState([])
  
  

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  const getUserActions =async(menu, roles)=>{   
    let params={
      menu:menu,
      roles:roles
    } 
    const response = await doGet(endpoint.action_menu_role, params, getDefaultHeader())
    if(response && response.data){
      setuserActions(response.data)
    }
  }
  
  const popupFilterShow = (event) => {
    if(filtersAvailable.length>0){
      setanchorFilter(event.currentTarget);
    }
  };

  const popupFilterClose = () => {
    setanchorFilter(null);
  };

  const addFilter =(field)=>{
    let filtersTemp = {...filters}
    filtersTemp[field.name] = null
    setFilters(filtersTemp)
    let newFiltersAvailable = filtersAvailable.filter(f=>f.name!==field.name)
    setfiltersAvailable(newFiltersAvailable)
    setfilterBy([...filterBy,field])
  }

  const removeFilter =(field)=>{
    let filtersTemp = {...filters}
    delete filtersTemp[field.name]
    setFilters(filtersTemp)
    let newFilterBy = filterBy.filter(f=>f.name!==field.name)
    setfilterBy(newFilterBy)
    setfiltersAvailable([...filtersAvailable,field])
  }

  const filterChange =(fieldName, value)=>{
    let filtersTemp = {...filters}
    filtersTemp[fieldName] = value
    setFilters(filtersTemp)
  }

  useDebounce(
        () => {
            getData()
        },
        700,
        [filters.keyword]
      );

  useEffect(() => {
      getData()
      getClientOptions()
      getCategoryOptions()
      getdiscountOptions()
      getcountbyOptions()
  }, [page,rowsPerPage])

  useEffect(() => {
    if(user && setting)  {
      getUserActions(setting.active_menu.id, user.roles.map(r=>r.id))
    }    
  }, [user, setting])


  const getData =async()=>{
    let params = {
      keyword:filters.keyword,
      status:filters.status?filters.status.value:undefined,
      page:page,
      rowsPerPage:rowsPerPage
    }
    
    const response = await doGet(endpoint.billing, params, getDefaultHeader())
    if(response && response.data && response.data.data){
      //const modifiedData = response.data.data.map(row=>({...row, selected:false}))
      //setDataTable(modifiedData)
      setDataTable(response.data.data)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    }
  }

  const getcountbyOptions =async()=>{
    let params = {         
    }    
    const response = await doGet(endpoint.parameter_group+'/bill_count_by', params, getDefaultHeader())
    if(response){
      setcountbyOptions(parameterListOptionMapper(response.data))
    }
  }
  
  const getClientOptions =async()=>{
    let params = {      
    }
    const response = await doGet(endpoint.client_options, params, getDefaultHeader())
    if(response){
      setclientOptions(generalListOptionMapper(response.data))
    }
  }

  const getdiscountOptions =async()=>{
    let params = {      
    }
    const response = await doGet(endpoint.discount_options, params, getDefaultHeader())
    if(response){
      setdiscountOptions(generalListOptionMapper(response.data))
    }
  }

  const getCategoryOptions =async()=>{
    let params = {      
    }
    const response = await doGet(endpoint.transaction_category_options, params, getDefaultHeader())
    if(response){
      setcategoryOptions(generalListOptionMapper(response.data))
    }
  }

  const getDataByFilter =()=>{
    getData()
  }


  const changeKeyword = (event)=> {
      setFilters({...filters, keyword:event.target.value})
  }

  const changePage=(event, newPage)=>{
      setPage(newPage+1)
  }

  const changeRowsPerPage = (event)=> {
      setRowsPerPage(+event.target.value);
      setPage(1);
  }

  const showAddForm =()=>{
    setaction(userActions.filter(a=>(a.code==='CBILL'))[0])
    setopenForm(true)
    setisDialogFull(false)
  }
  const showEditForm =()=>{
    setaction(userActions.filter(a=>(a.code==='EBILL'))[0])
    setopenForm(true)
    setisDialogFull(false)
  }
  const showDetail =(row)=>{
    setaction(userActions.filter(a=>(a.code==='RBILL'))[0])
    toggleSelectRow(row)
    setselectedRow(row)    
    setopenForm(true)
    setisDialogFull(false)
  }

  const showDeleteConfirmation =()=>{
    setaction(userActions.filter(a=>(a.code==='DBILL'))[0])
    setopenForm(true)
    setisDialogFull(false)
  }

  const showChargeConfirmation =()=>{
    setaction(userActions.filter(a=>(a.code==='TBILL'))[0])
    setopenForm(true)
    setisDialogFull(false)
  }

  const showPaidConfirmation =()=>{
    const paidBills = selectedRows.map(bill=>(Number(bill.paymentstatus.value))).filter(status=> status === 1 )
    
    if(paidBills.length > 0){
      setaction({code:"already_paid"})
      setopenForm(true)
      setisDialogFull(false)
    }else{
      setaction(userActions.filter(a=>(a.code==='PBILL'))[0])
      setopenForm(true)
      setisDialogFull(false)
    }
    
  }

  const handleCloseForm =()=>{
    setopenForm(false)
    
    if(action.code==='RBILL'){
      setselectedRow(null)
      setselectedRows([])
      setselectedIds([])
    }

  }

  const insert =async(payload)=>{
    const response = await doPost(endpoint.billing, payload,"insert "+setting.active_menu.display ,getDefaultHeader())
    getData()
    return response
  }

  const update =async(payload)=>{
    const response = await doPut(endpoint.billing, payload,"update "+setting.active_menu.display ,getDefaultHeader())
    getData()
    return response
  }

  const handleDelete =async()=>{
    const payload = {
      ids:selectedIds
    }
    const response = await doDelete(endpoint.billing, payload,"delete "+setting.active_menu.display ,getDefaultHeader())
    setselectedIds([])
    setselectedRows([])
    getData()
  }

  const handleCharge =async()=>{
    const payload = {
      ids:selectedIds
    }
    const response = await doPost(endpoint.billing_charge, payload,"charge "+setting.active_menu.display ,getDefaultHeader())
    setselectedIds([])
    setselectedRows([])
    getData()
  }

  const handlePaid =async()=>{
    const payload = {
      ids:selectedIds,
      creator: user.id,
      username:user.username     
    }
    const response = await doPost(endpoint.billing_paid, payload,"paid "+setting.active_menu.display ,getDefaultHeader())
    setselectedIds([])
    setselectedRows([])
    getData()
  }

  const toggleSelectRow =(row)=>{
    if(selectedIds.includes(row.id)){
        setselectedIds(selectedIds.filter(item=>item!==row.id))
        setselectedRow(null)
        setselectedRows(selectedRows.filter(item=>item.id!==row.id))
    }else{
        setselectedIds([...selectedIds,row.id])
        setselectedRow(row)
        setselectedRows([...selectedRows,row])
    }
  }

  const toggleSelectAllRow=()=>{
    if(selectedIds.length === dataTable.length){
      setselectedIds([])
      setselectedRows([])
    }else{
      setselectedIds(dataTable.map(row=>row.id))
      setselectedRows(dataTable)
    }

  }

  if(user && user.token){
    return (
      <>
        <Grid container justify="flex-start" alignItems="flex-start" alignContent="flex-start" className={classes.root}>
            <Grid container className={classes.toolbarContainer}>
              <Grid container item alignContent="center" xs={12} sm={12} md={6} lg={6} className={classes.toolbarLeft}>
                <Typography color="primary" component="h5" variant="h5">
                  {setting.active_menu.display}
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={12} md={6} lg={6} spacing={1}  direction="row-reverse" justify="flex-start" className={classes.toolbarRight}>                  
                <Protected user={user} userActions={userActions} allowedCodes={['CBILL']} activeMenu={setting.active_menu}>
                  <Button onClick={showAddForm} variant="contained" color="primary" size="small" className={classes.smallButton}>
                    <ActionDisplay userActions={userActions} code='CBILL'/>
                  </Button>
                </Protected>

                  <Conditional condition={selectedIds.length===1}>
                    <Protected user={user} userActions={userActions} allowedCodes={['EBILL']} activeMenu={setting.active_menu}>
                      <Button onClick={showEditForm} variant="contained" size="small" className={classes.smallButton}>
                        <ActionDisplay userActions={userActions} code='EBILL'/>
                      </Button>
                    </Protected>
                  </Conditional>
                                
                  <Conditional condition={selectedIds.length>0}>
                    <Protected user={user} userActions={userActions} allowedCodes={['DBILL']} activeMenu={setting.active_menu}>
                      <Button onClick={showDeleteConfirmation} variant="contained" color="secondary" size="small" className={classes.smallButton}>
                        <ActionDisplay userActions={userActions} code='DBILL'/>
                      </Button>
                    </Protected>
                    <Protected user={user} userActions={userActions} allowedCodes={['TBILL']} activeMenu={setting.active_menu}>
                      <Button onClick={showChargeConfirmation} variant="contained" size="small" color="primary" className={classes.smallButton}>
                        <ActionDisplay userActions={userActions} code='TBILL'/>
                      </Button>
                    </Protected>
                    <Protected user={user} userActions={userActions} allowedCodes={['PBILL']} activeMenu={setting.active_menu}>
                      <Button onClick={showPaidConfirmation} variant="contained" size="small" className={classes.successButton}>
                        <ActionDisplay userActions={userActions} code='PBILL'/>
                      </Button>
                    </Protected>
                  </Conditional>                
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.filterContainer}>
              {
               filters && filters.keyword!==undefined &&
               <Grid item xs={12} sm={12} md={2} lg={2}>
                  <TextField
                    fullWidth
                    value={filters.keyword}
                    onChange={changeKeyword}
                    className={classes.margin}
                    id="search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              }
              {
                filterBy && filterBy.map(field=>(
                  <FilterComponent
                  options = {statusOptions}
                  removeFilter = {removeFilter}
                  filterChange = {filterChange}
                  field = {field}
                  />
                ))
              }
              <Grid xs={12} sm={12} md={2} lg={2} item container justify="space-between">
                <Grid xs={6} item container justify="flex-start">
                  <IconButton onClick={popupFilterShow} aria-label="delete" className={classes.margin}>
                    <FilterListIcon color="primary"/>
                  </IconButton>
                </Grid>

                <Grid xs={6} item container justify="flex-end">
                  <IconButton onClick={getDataByFilter} aria-label="delete" className={classes.margin}>
                    <Send color="primary"/>
                  </IconButton>
                </Grid>                
              </Grid> 
            </Grid>
            <Grid container className={classes.tableContainer}>
              <DataTable
                user = {user}
                userActions={userActions}
                toggleSelectRow={toggleSelectRow}
                toggleSelectAllRow={toggleSelectAllRow}
                showDetail={showDetail}
                selectedIds={selectedIds}
                dataTable={dataTable}
                totalRows={totalRows}
                page={page}
                rowsPerPage={rowsPerPage}
                changePage={changePage}
                changeRowsPerPage={changeRowsPerPage}
              />
            </Grid>

        </Grid>

        <Dialog open={openForm} fullScreen={isDialogFull} onClose={handleCloseForm} aria-labelledby="form-dialog-title">
          <FormContent
          user={user}
          userActions={userActions}
          action={action}
          title={setting.active_menu.display}
          insert={insert}
          handleClose={handleCloseForm}
          handleDelete={handleDelete}
          handleCharge={handleCharge}
          handlePaid={handlePaid}
          selectedIds={selectedIds}
          selectedRow={selectedRow}
          showEditForm={showEditForm}
          update={update}
          setting={setting}
          clientOptions={clientOptions}
          categoryOptions = {categoryOptions}
          discountOptions={discountOptions}
          countbyOptions ={countbyOptions}
          />
        </Dialog>

        <Popover
          id="popUpFilter"
          open={Boolean(anchorFilter)}
          anchorEl={anchorFilter}
          onClose={popupFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          className={classes.popUpFilter}
          style={
            {marginTop:8}
          }
        >
          <FilterList fields={filtersAvailable} filters={filters} onItemClick={addFilter} onClose={popupFilterClose}/>
        </Popover>


        </>
    );
  }
  else{
    return <Redirect to={{ pathname: '/login' }} />
  }

}

export default Billing;

const FormContent=(props)=>{
  const {user, userActions, countbyOptions, discountOptions, clientOptions, categoryOptions, action, title, insert, update, handleClose, handleDelete, handleCharge, handlePaid, selectedIds, selectedRow, showEditForm, setting} =  props

  if(action.code==="RBILL"){
      return(<FormDetail
        user={user}
        userActions={userActions}
        handleClose={handleClose}
        row = {selectedRow}
        title={title}
        showEditForm={showEditForm}
        setting={setting}
        />
      )

  }
  else if(action.code==="CBILL"){
    return(
        <FormAdd
        handleClose={handleClose}
        userActions={userActions}
        handleSave={insert}
        title={title}
        clientOptions = {clientOptions}
        categoryOptions = {categoryOptions}
        discountOptions = {discountOptions}
        countbyOptions = {countbyOptions}
        user={user}
        />
      )

  }
  else if(action.code==="EBILL"){
    return(<FormEdit
        user={user}
        userActions={userActions}
        handleClose={handleClose}
        row = {selectedRow}
        title={title}
        handleSave={update}
        categoryOptions = {categoryOptions}
        setting={setting}
        />
      )

  }
  else if(action.code==="DBILL"){
    return( <Confirmation
        close = {handleClose}        
        action={handleDelete}
        actionName={action.display}
        title={action.name}
        selectedIds={selectedIds}
        message={`apakah anda yakin menghapus ${selectedIds.length} ${title} ?`}
        />
      )
  }
  else if(action.code==="TBILL"){
    return( <Confirmation
        close = {handleClose}
        action={handleCharge}
        actionName={action.display}
        title={action.name}
        selectedIds={selectedIds}
        message={`apakah anda yakin menagih ${selectedIds.length} ${title} ?`}
        />
      )
  }
  else if(action.code==="PBILL"){
    return( <Confirmation
        close = {handleClose}
        action={handlePaid}
        actionName={action.display}
        title={action.name}
        selectedIds={selectedIds}
        message={`apakah anda yakin ${title} sudah  lunas ?`}
        />
      )
  }
  else if(action.code==="already_paid"){    
    return( <Confirmation
      close = {handleClose}
      action={handleClose}
      actionName="OK"
      title="Sudah Lunas"
      selectedIds={[]}
      message={"mohon pilih tagihan yang belum dibayar"}
      />
    )    
}

  else return null
}



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    background:'linear-gradient(180deg, rgba(243,243,255,1) 100%, rgba(238,239,255,1) 0%)'
  },
 toolbarContainer: {
    marginTop:theme.spacing(1)
  },
  filterContainer: {
    marginBottom:theme.spacing(1)
  },
  tableContainer: {
    minHeight:400,
  },
  clickable:{
    cursor:'pointer',
    fontWeight:'bold',
    color:theme.palette.primary.main
  },
  formContainer: {
    minHeight:'80vh',
    marginTop:theme.spacing(1)
  },
  buttonContainer:{
    marginTop:theme.spacing(2)
  },
  formPaper:{
    width:'inherit',
    padding:theme.spacing(2)
  },
  paginatonContainer: {
    height:40,
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  successButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px',
    backgroundColor:"#4caf50",
    color:"white"
  },
  marginB1:{
    marginBottom:theme.spacing(1)
  },
  reactSelect:{
    width:"100%"
  },
  margin1:{
    margin: theme.spacing(1)
  },
  closeButton:{
    top:-20,
    marginLeft:"auto",
    marginRight:0
  }

}));

const selectCustomZindex = {
    control: (base, state) => ({
        ...base,
        minWidth: "150px",
        margin: "0 4px"
    }),
    container: (base, state) => {
        return {
            ...base,
            flex: 1,
            zIndex: state.isFocused ? "1100" : "1" //Only when current state focused
        };
    }
};
