/*
author alka@2019
*/
import produce from "immer";
const initial  = {
    user:null,
    ui:{
        snack_show:false,
        snack_txt:'',
        snack_var:'info',
        loading:false,
        error:''
    },
    setting:{
        active_menu:{},                
    },
    

}

export default function appReducer(prev=initial,action){
    switch (action.type) {
        case 'setUserLogin':
        return produce(prev, state =>{
            state.user= action.payload
        })

        case 'logout':
        return produce(prev, state =>{
            state.user= null
            state.ui={
                snack_show:false,
                snack_txt:'',
                snack_var:'info',
                loading:false,
                error:''
            }
            state.setting= {
              active_menu:{}
            }
        })        
        
        case 'showSnackbar':
        return produce(prev, state =>{
            state.ui= {
                ...state.ui,
                snack_show:true,
                snack_txt:action.payload.t,
                snack_var:action.payload.v}
        })

        case 'hideSnackbar':
        return produce(prev, state =>{
            state.ui= {
                ...state.ui,
                snack_show:false}
        })

        case 'loading':
        return produce(prev, state =>{
            state.ui= {
                ...state.ui,
                loading:action.payload}
        })

        case 'setActiveMenu':
        return produce(prev, state =>{
            state.setting= {
                ...state.setting,
                active_menu:action.payload}
        })

        case 'setGlobalError':
        return produce(prev, state =>{
            state.ui= {
                ...state.ui,
              error:action.payload}
        })
        default:
            return prev
    }
}
