import React,{useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const NumberWidget =(props)=> {  
  const {user, widget} = props
  const classes = useStyles();
  const [value, setvalue] = useState(0)

  const getValue =()=>{
    const response = {data:100}
    setvalue(response.data)
  }

  useEffect(() => {
    if(user){
      getValue()
    }
  }, [user])

  if(user && user.token){
    return (
      <Grid container item xs={widget.size} style={{backgroundColor:widget.color, color:"white"}} alignContent="stretch" className={classes.root}>
        <Grid justify="center" container alignItems='center' alignContent="center">
        <Typography variant="h4">{value}</Typography>
        </Grid>
        <Grid container justify="center" alignItems='center' alignContent="stretch">
          <Typography variant="h6">{widget.label}</Typography>
        </Grid>
      </Grid>
    );
  }
  else{
    return null
  }

}

export default NumberWidget;

export const useStyles = makeStyles((theme) => ({
  root: {
    margin:4,
    borderRadius:theme.borderRadius
  },
}));