import React,{ useState, useEffect }  from 'react';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserLogin } from "reduxs/actions";
import {doPost, doSilentPost} from "utils/apiUtil.js"
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useStyles } from './styles.js';
import { useLocalStorage } from 'react-use';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        ziadsistem.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>

  );
}

export default function Login() {
  const [persistedUser, persistUser, clearPersistedUser] = useLocalStorage('user', {});
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const submit = async()=>{
    const payload = {"username":userName, "password":password}
    const response = await doSilentPost("login",payload)
    if(response && response.data && response.data.user){
        let user = response.data.user
        user.token = response.data.access_token
        persistUser(user)      
        dispatch(setUserLogin(user))
    }
  }

  const userNameChange = e => {
    setUserName(e.target.value);
  };

  const passwordChange = e => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    if(persistedUser){
      dispatch(setUserLogin(persistedUser))  
    }
  }, [persistedUser])

  if(user && user.token){
      return <Redirect to={{ pathname: "/home" }} />
  }else{

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="User Name"
            name="username"
            autoComplete="username"
            autoFocus
            value={userName}
            onChange={userNameChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={passwordChange}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
  }
}
