import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Conditional from 'components/Conditional.js';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import Protected   from 'components/Protected.js';
import SmallConfirmation   from 'components/SmallConfirmation.js';

const ActionDetail = (props)=>{
  const {user, userActions, menu, actions, addAction, removeAction, activeMenu, pageAction, updateAction} = props
  const classes = useStyles()
  const [anchorSmallConfirmation, setanchorSmallConfirmation] = useState(null);
  const [newAction, setnewAction] = useState(null)
  const [todo, settodo] = useState(null)

  const addNewClick=()=>{
    settodo("add")
    setnewAction({
      id:actions.length+1,
      code:"",
      name:"",
      display:"",
      menuId:menu?menu.id:undefined
    })
  }

  const editClick=(action)=>{
    settodo("edit")
    setnewAction(action)
  }
  const removeClick=(action,e)=>{
    settodo("delete")
    setnewAction(action)
    setanchorSmallConfirmation(e.currentTarget)
  }

  const closeSmallConfirmation=()=>{
    setanchorSmallConfirmation(null)
  }

  const cancelClick=()=>{
    settodo(null)
    setnewAction(null)
  }

  const execute=()=>{

      if(todo==="add"){
          addAction(newAction)
      }
      else if(todo==="edit"){
          updateAction(newAction)
      }
      else if(todo==="delete"){
          removeAction(newAction)
      }

      setnewAction(null)

  }

  const newActionChange=(field,e)=>{
    setnewAction({...newAction,[field]:e})
  }

  return(
    <>
    <Grid container>
        <Grid container alignContent="center" justify="center" className={classes.header}>
              <Typography variant="h6">Data Action</Typography>
        </Grid>
        <Grid container alignItems="center" spacing={1} justify="flex-end" style={{marginBottom:8}}>
        {
          newAction &&
          <>
        <Grid xs={12} sm={12} md={3} lg={3} >
          <DebouncedTextField
            margin="dense"
            id="code"
            label="code"
            variant="outlined"
            fullWidth
            value={newAction.code}
            onChange={(e)=>newActionChange("code",e)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} >
          <DebouncedTextField
            margin="dense"
            id="name"
            label="name"
            variant="outlined"
            fullWidth
            value={newAction.name}
            onChange={(e)=>newActionChange("name",e)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} >
          <DebouncedTextField
            margin="dense"
            id="display"
            label="display"
            variant="outlined"
            fullWidth
            value={newAction.display}
            onChange={(e)=>newActionChange("display",e)}
          />
        </Grid>

        </>
      }
      <Conditional condition={newAction===null}>
          <Grid container justify="flex-end" alignContent="center">
          <Conditional condition={pageAction!=="detail"}>
            <Protected user={user} userActions={userActions} allowedCodes={['CACTION']} >
              <Button size="small" variant="contained" color="primary" className={classes.smallButton} onClick={addNewClick}>Tambah</Button>
            </Protected>
          </Conditional>
          </Grid>
      </Conditional>
      <Conditional condition={newAction!==null}>
          <Grid container item xs={12} sm={12} md={3} lg={3} justify="flex-end" alignContent="center">
            <Button size="small" variant="contained" color="primary" className={classes.smallButton} onClick={execute}>Simpan</Button>
            <Button size="small" variant="contained" color="secondary" className={classes.smallButton} onClick={cancelClick}>Cancel</Button>
          </Grid>
          
      </Conditional>
      </Grid>
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead className={classes.backgroundPrimary}>
          <TableRow>
            <TableCell align="center" style={{color:"#ffffff"}}>ID</TableCell>
            <TableCell align="center" style={{color:"#ffffff"}}>Code</TableCell>
            <TableCell align="center" style={{color:"#ffffff"}}>Name</TableCell>
            <TableCell align="center" style={{color:"#ffffff"}}>Display</TableCell>
            <TableCell align="center" style={{color:"#ffffff"}}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actions && actions.map((action) => (
            <TableRow key={action.id}>
              <TableCell align="center" >{action.id}</TableCell>
              <TableCell align="center" >{action.code}</TableCell>
              <TableCell align="center">{action.name}</TableCell>
              <TableCell align="center">{action.display}</TableCell>
              <TableCell align="center">
                <Conditional condition={["edit","add"].includes(pageAction)}>
                  <Protected user={user} userActions={userActions} allowedCodes={['EACTION']} >
                    <IconButton
                    onClick={()=>editClick(action)}
                    size="small"
                    aria-label="close"
                    className={clsx(classes.margin1,classes.closeButton)}>
                      <Edit fontSize="inherit" />
                    </IconButton>
                  </Protected>
                  <Protected user={user} userActions={userActions} allowedCodes={['DACTION']} >
                    <IconButton
                    onClick={(e)=>removeClick(action,e)}
                    size="small"
                    aria-label="close"
                    className={clsx(classes.margin1,classes.closeButton)}>
                      <Cancel fontSize="inherit" />
                    </IconButton>
                  </Protected>
                </Conditional>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>
  <SmallConfirmation
    anchor={anchorSmallConfirmation}
    handleClose={closeSmallConfirmation}
    execute={execute}
    actionName={todo}/>
  </>
  )
}

export default ActionDetail

const useStyles = makeStyles((theme) => ({
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  backgroundPrimary:{
    backgroundColor:theme.palette.primary.main,
    color:"#ffffff"
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  }
}));
