import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {endpoint} from 'utils/constants.js'
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import NumberFormatCustom from 'components/NumberFormatCustom.js';
import Conditional from 'components/Conditional.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Protected   from 'components/Protected.js';
import Select from 'react-select'
import MSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NumberFormat from 'react-number-format';
import TextField from "@material-ui/core/TextField";
import { format,parse } from 'date-fns'
import SmallConfirmation   from 'components/SmallConfirmation.js';
import StatusChip from 'components/StatusChip';
import {generalOptionMapper, parameterOptionMapper, generalListOptionMapper} from 'utils/mappers.js'


const TransactionCategory =(props)=> {
  const {title, userActions, user, setting, handleClose, pageAction, updateCategories} = props
  const classes = useStyles();
  const [newCategory, setnewCategory] = useState(null)
  const [categories, setcategories] = useState([])
  const [walletOptions, setWalletOptions] = useState([])

  const [flows, setflows] = useState([
    {label:"Pemasukan",value:1},
    {label:"Pengeluaran",value:0}
  ])

  const [payFrequencies, setpayFrequencies] = useState([
    {label:"Sekali Bayar",value:1},
    {label:"Bulanan",value:2}
  ])

  const [anchorSmallConfirmation, setanchorSmallConfirmation] = useState(null);  
  const [todo, settodo] = useState(null)

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  

  useEffect(() => {
    getWalletOptions()
    gettransactionCategories()  
  }, [])

  

  const addCategory= async(payload)=>{    
    
    
    await doPost(endpoint.transaction_category, payload,"insert "+setting.active_menu.display ,getDefaultHeader())
    setnewCategory(null)
    gettransactionCategories()
  }

  const removeCategory=async(payload)=>{
        
    
    const response = await doDelete(`${endpoint.transaction_category}`, {ids:[payload.id]},"delete"+setting.active_menu.display ,getDefaultHeader())
    gettransactionCategories()
  }

  const updateCategory= async(payload)=>{
    
    await doPut(endpoint.transaction_category, payload,"update "+setting.active_menu.display ,getDefaultHeader())
    setnewCategory(null)
    gettransactionCategories()
  }  
  
  const addNewClick=()=>{
    settodo("add")
    setnewCategory({
      name:"",
      flow: flows[0],
      recurrence:payFrequencies[0],
      partial:{value:0},
      wallet:null
    })
  }

  const editClick=(payload)=>{
    
    settodo("edit")
    let recurrenceDate = null
    if(payload.recurrenceDate){
      recurrenceDate = Number(format(payload.recurrenceDate, 'dd'))
    }
    
    const modified = {
      ...payload,      
      recurrenceDate:recurrenceDate
    }    

    setnewCategory(modified)

  }
  const removeClick=(payload,e)=>{
    settodo("delete")
    let recurrenceDate = null
    if(payload.recurrenceDate){
      recurrenceDate = Number(format(payload.recurrenceDate, 'dd'))
    }
    
    const modified = {
      ...payload,
      recurrenceDate:recurrenceDate
    }
    setnewCategory(modified)
    setanchorSmallConfirmation(e.currentTarget)
  }

  const closeSmallConfirmation=()=>{
    setanchorSmallConfirmation(null)
  }

  const newCategoryChange=(fieldName,eventValue)=>{
        
    let newnewCategory = {...newCategory}
    let value = eventValue
    if(fieldName==="partial"){
      value = eventValue.target.checked? 1:0
      value = {value:value}      
    }
    else if(fieldName==="recurrenceDate"){
      value = eventValue.target.value
    }
    else if(fieldName==="recurrence"){      
      if(eventValue.value===1){
        newnewCategory["recurrenceDate"]=1
      }       
    }
    else if(fieldName==="wallet"){
      value = eventValue
      newnewCategory.mode = getCashMode(eventValue.cash)
    }
    newnewCategory[fieldName] = value
    //console.log(newnewCategory);
    setnewCategory(newnewCategory)
  }

  const getCashMode=(value)=>{
    if(value===1){
      return {value:1, name:'cash'}
    }
    else{
      return {value:2, name:'transfer'}
    }
  }

  const gettransactionCategories =async()=>{
    let params = {}
    
    const response = await doGet(endpoint.transaction_category, params, getDefaultHeader())    
    if(response){
      const modified = response.data.map(item=>({
        ...item, 
        recurrenceDate : parse(item.recurrenceDate, "yyyy-MM-dd", new Date()),
        flow:parameterOptionMapper(item.flow),
        partial:parameterOptionMapper(item.partial),
        wallet:generalOptionMapper(item.wallet),
        recurrence:parameterOptionMapper(item.recurrence)      
      }))
      setcategories(modified)
      updateCategories(modified)
    }
  }

  const getWalletOptions =async()=>{
    let params = {}
    
    const response = await doGet(endpoint.wallet_options, params, getDefaultHeader())
    
    if(response){      
      setWalletOptions(generalListOptionMapper(response.data))
    }
  }

  const execute=()=>{

    const payload = {
      ...newCategory, 
      wallet:newCategory.wallet? Number(newCategory.wallet.value):null,
      mode:newCategory.mode? newCategory.mode.value : null,
      charge:Number(newCategory.charge),
      partial:newCategory.partial ? newCategory.partial.value:null,
      recurrence:newCategory.recurrence ? newCategory.recurrence.value:null,
      flow:newCategory.flow ? Number(newCategory.flow.value):null,
      recurrenceDate:"1999-01-"+(newCategory.recurrenceDate?newCategory.recurrenceDate:1)      
    }    

    //console.log(payload);    
    
    if(todo==="add"){
        addCategory(payload)
    }
    else if(todo==="edit"){
        updateCategory(payload)
    }
    else if(todo==="delete"){
        removeCategory(payload)
    }

    setnewCategory(null)

}

  const cancel=()=>{
    setnewCategory(null)
  }

  const Options=(props)=>{
    let numbers = []
    for (let i = 1; i < props.number; i++) {
      numbers.push(i)
    }
    return (
      numbers.map(item=>(
        <option value={item}>{item}</option>
      ))
    )
  }

  if(user && setting){
    return (
      <>
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>Transaction Category</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} justify='space-between' className={classes.root}>
            <Grid container spacing={1} justify='space-between' className={classes.form}>
              <Grid container item xs={12} sm={12} md={10} lg={10} spacing={1} justify='flex-start'>
                {
                  newCategory &&
                <>
                <Grid item xs={12} sm={12} md={2} lg={2} container alignContent='center' >
                  <Select
                    name="jenis transaksi"
                    placeholder = "jenis transaksi"
                    options={flows}
                    onChange={(e)=>newCategoryChange("flow",e)}
                    value={newCategory.flow}
                    styles={selectCustomZindex}
                  />                
                </Grid> 
                <Grid item xs={12} sm={12} md={2} lg={2} container alignContent='center' >
                  <Select
                    name="wallet"
                    placeholder = "wallet"
                    options={walletOptions}
                    onChange={(e)=>newCategoryChange("wallet",e)}
                    value={newCategory.wallet}
                    styles={selectCustomZindex}
                  />                
                </Grid>

                <Grid item xs={12} sm={12} md={1} lg={1} container alignContent='center' >
                  <DebouncedTextField
                    margin="dense"
                    id="mode"
                    label="mode"
                    variant="outlined"
                    fullWidth
                    value={newCategory.mode ? newCategory.mode.name : ""}
                    readOnly
                  />                
                </Grid>

                <Grid item xs={12} sm={12} md={2} lg={2} >
                  <DebouncedTextField
                    margin="dense"
                    id="name"
                    label="name"
                    variant="outlined"
                    fullWidth
                    value={newCategory.name}
                    onChange={(e)=>newCategoryChange("name",e)}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2} lg={2} >
                  <DebouncedTextField
                    margin="dense"
                    id="code"
                    label="code"
                    variant="outlined"
                    fullWidth
                    value={newCategory.code}
                    onChange={(e)=>newCategoryChange("code",e)}
                  />
                </Grid>
  
                                
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <TextField
                    margin="dense"
                    id="Biaya"
                    label="Biaya"
                    variant="outlined"
                    name="charge"
                    fullWidth
                    value={newCategory.charge}
                    onChange={newCategoryChange}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2} lg={2} container alignContent='center'>
                  <Select
                    name="jenis transaksi"
                    placeholder = "jenis transaksi"
                    options={payFrequencies}
                    onChange={(e)=>newCategoryChange("recurrence",e)}
                    value={newCategory.recurrence}
                    styles={selectCustomZindex}
                  />                    
                </Grid>

                <Conditional condition={newCategory.recurrence.value!==1}>
                  <Grid item xs={12} sm={12} md={2} lg={2}>                      
                      <FormControl style={{width:"100%"}} >
                        <InputLabel htmlFor="recurrenceDate" style={{width:"100%"}}>Tanggal Bayar</InputLabel>
                        <MSelect
                          native
                          value={newCategory.recurrenceDate}
                          onChange={(e)=>newCategoryChange("recurrenceDate",e)}
                          inputProps={{
                            name: 'recurrenceDate',
                            id: 'recurrenceDate',
                          }}
                        >                          
                          <Options number={31} />                         
                        </MSelect>
                      </FormControl>
                  </Grid>
                </Conditional>                   

                <Grid item xs={12} sm={12} md={2} lg={2} container alignContent='center' style={{width:120}}>
                  <FormControlLabel
                      value="partial"
                      control={<Switch color="primary" checked={newCategory.partial.value===1} onChange={(e)=>newCategoryChange("partial",e)}/>}
                      label="Dicicil"
                      labelPlacement="start"
                      />                
                </Grid>     
                </>
              }
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} container justify="flex-end" alignContent="center">
                <Conditional condition={newCategory===null}>                                      
                      <Protected user={user} userActions={userActions} allowedCodes={['CCTGR']} activeMenu={setting.active_menu}>
                        <Button size="small" variant="contained" color="primary" className={classes.smallButton} onClick={addNewClick}>Tambah</Button>
                      </Protected>                    
                </Conditional>
                <Conditional condition={newCategory!==null}>                  
                      <Button size="small" variant="contained" className={classes.smallButton} onClick={cancel}>Cancel</Button>                  
                      <Button size="small" variant="contained" color="primary" className={classes.smallButton} onClick={execute}>Simpan</Button>                  
                </Conditional>
              </Grid>
            </Grid>
            <Grid container>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead className={classes.backgroundPrimary}>
                  <TableRow>
                    <TableCell style={{color:"#ffffff"}}>ID</TableCell>
                    <TableCell style={{color:"#ffffff"}}>Nama</TableCell>
                    <TableCell style={{color:"#ffffff"}}>Kode</TableCell>
                    <TableCell style={{color:"#ffffff"}}>Transaksi</TableCell>
                    <TableCell style={{color:"#ffffff"}}>Biaya</TableCell>
                    <TableCell style={{color:"#ffffff"}}>Wallet</TableCell>
                    <TableCell style={{color:"#ffffff"}}>Mode</TableCell>
                    <TableCell style={{color:"#ffffff"}}>Cicil</TableCell>                  
                    <TableCell style={{color:"#ffffff"}}>Penagihan</TableCell>
                    <TableCell style={{color:"#ffffff"}}>Tanggal Bayar</TableCell>
                    <TableCell style={{color:"#ffffff"}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories && categories.map((category) => (
                    <TableRow key={category.id}>
                      <TableCell>{category.id}</TableCell>
                      <TableCell>{category.name}</TableCell>
                      <TableCell>{category.code}</TableCell>
                      <TableCell>{category.flow && category.flow.name}</TableCell>
                      <TableCell>
                        <NumberFormat value={category.charge} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} />
                      </TableCell>
                      <TableCell>{category.wallet && category.wallet.name}</TableCell>
                      <TableCell>{category.mode && category.mode.name}</TableCell>
                      <TableCell>{category.partial && category.partial.name}</TableCell>                      
                      <TableCell>{category.recurrence && category.recurrence.label}</TableCell>
                      <TableCell>{format(category.recurrenceDate,"d")}</TableCell>
                      <TableCell align="center">                        
                          <Protected user={user} userActions={userActions} allowedCodes={['ECTGR']} activeMenu={setting.active_menu}>
                            <IconButton
                            onClick={()=>editClick(category)}
                            size="small"
                            aria-label="close"
                            className={clsx(classes.margin1,classes.closeButton)}>
                              <Edit fontSize="inherit" />
                            </IconButton>
                          </Protected>
                          <Protected user={user} userActions={userActions} allowedCodes={['DCTGR']} activeMenu={setting.active_menu}>
                            <IconButton
                            onClick={(e)=>removeClick(category,e)}
                            size="small"
                            aria-label="close"
                            className={clsx(classes.margin1,classes.closeButton)}>
                              <Cancel fontSize="inherit" />
                            </IconButton>
                            <SmallConfirmation
                              anchor={anchorSmallConfirmation}
                              handleClose={closeSmallConfirmation}
                              execute={execute}
                              actionName={todo}
                            />                            
                          </Protected>                        
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </Grid>
          
  
          </Grid>
        </DialogContent>
        <DialogActions>        
          <Button variant="contained" onClick={handleClose} color="primary">
            Kembali
          </Button>
        </DialogActions>
  </>
  
      );
  
  }else{
    return null
  }

  }

export default TransactionCategory;

const useStyles = makeStyles((theme) => ({
  dialogTitle:{    
           
  },

  dialogContent:{
    paddingTop:theme.spacing(2),
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )',    
    borderTop: '1px solid',
    borderColor:theme.borderColor,
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
  backgroundPrimary:{
    backgroundColor:theme.palette.primary.main,
    color:"#ffffff"
  },
  form:{
    marginBottom:8
  }
}));

const selectCustomZindex = {
  control: (base, state) => ({
      ...base,
      minWidth: "150px",
      margin: "0 4px"
  }),
  container: (base, state) => {
      return {
          ...base,
          flex: 1,
          zIndex: state.isFocused ? "1100" : "1" //Only when current state focused
      };
  }
};

