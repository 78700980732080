import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Protected   from 'components/Protected.js';
import Conditional   from 'components/Conditional.js';
import ActionDetail from './ActionDetail.js'

const FormEdit =(props)=> {
  const {row, userActions, title, user, handleClose, handleSave, setting, pageAction} = props
  const classes = useStyles();
  const [state, setstate] = useState({})
  const [actions, setactions] = useState([])

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  useEffect(() => {
      if(row && user){
        const modified = {...row, status:Number(row.status.value)}
        setstate(modified)
        getActions()
      }
  }, [row])

  useEffect(() => {

  }, [])

  const getActions =async()=>{
    let params = {
    }
    
    const response = await doGet(endpoint.menu_id_action.replace("id",row.id), params, getDefaultHeader())
    if(response){
      //const modifiedData = response.data.data.map(row=>({...row, selected:false}))
      //setDataTable(modifiedData)
      setactions(response.data)

    }
  }

  const addAction= async (payload)=>{
    
    const response = await doPost(endpoint.action, payload,"insert action" ,getDefaultHeader())
    getActions()
  }

  const removeAction= async(action)=>{
    let payload = null
    
    const response = await doDelete(`${endpoint.action}/${action.id}`, payload,"delete action" ,getDefaultHeader())
    getActions()
  }

  const updateAction= async(payload)=>{
    
    const response = await doPut(endpoint.action, payload,"update action" ,getDefaultHeader())
    getActions()
  }


  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue
    if(fieldName==="status"){
      value = eventValue.target.checked? 1:0
    }
    newState[fieldName] = value
    setstate(newState)

  }

  const submit = async()=>{
    const response = await handleSave(state)
    if(response.data.message==="success"){
      handleClose()
    }
  }


  return (
    <>
      <DialogTitle id="form-dialog-title">Edit</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} justify='space-between' className={classes.root}>
            <Grid container item xs={12} sm={12} md={4} lg={4} justify='flex-start' alignItems='flex-start' alignContent="flex-start" className={classes.left}>
              <Grid container alignContent="center" justify="center" className={classes.header}>
                <Typography variant="h6">Data {title}</Typography>
              </Grid>
              <Grid container justify='center' alignItems='flex-start' className={classes.content}>
                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="Id"
                    label="Id"
                    fullWidth
                    value={state.id}
                    readOnly
                  />
                </Grid>
                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="parentId"
                    label="parentId"
                    fullWidth
                    value={state.parentId}
                    onChange={(e)=>handleChange("parentId",e)}
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="categoryId"
                    label="categoryId"
                    fullWidth
                    value={state.categoryId}
                    onChange={(e)=>handleChange("categoryId",e)}
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="code"
                    label="code"
                    fullWidth
                    value={state.code}
                    onChange={(e)=>handleChange("code",e)}
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="display"
                    label="display"
                    fullWidth
                    value={state.display}
                    onChange={(e)=>handleChange("display",e)}
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="webPath"
                    label="webPath"
                    fullWidth
                    value={state.webPath}
                    onChange={(e)=>handleChange("webPath",e)}
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="androidActivity"
                    label="androidActivity"
                    fullWidth
                    value={state.androidActivity}
                    onChange={(e)=>handleChange("androidActivity",e)}
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="iosClass"
                    label="iosClass"
                    fullWidth
                    value={state.iosClass}
                    onChange={(e)=>handleChange("iosClass",e)}
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="icon"
                    label="icon"
                    fullWidth
                    value={state.icon}
                    onChange={(e)=>handleChange("icon",e)}
                  />
                </Grid>

                <Grid container justify='flex-start' alignItems='center'>
                  <FormControlLabel
                    value="status"
                    control={
                        <Switch 
                        color="primary" 
                        checked={Number(state.status)===1} 
                        onChange={(e)=>handleChange("status",e)}
                        />
                      }
                    label="Status"
                    labelPlacement="start"
                    style={{marginLeft:0}}
                  />
                </Grid>
              </Grid>
      </Grid>

      <Grid container item xs={12} sm={12} md={7} lg={7} justify='center' alignItems="flex-start" alignContent="flex-start" className={classes.right}>
        <ActionDetail
          userActions={userActions}
          menu={row}
          pageAction={pageAction}
          user={user}
          activeMenu={setting.active_menu}
          actions={actions}
          addAction={addAction}
          removeAction={removeAction}
          updateAction={updateAction}
          />
      </Grid>

      </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>
</>

    );
}

export default FormEdit;

const useStyles = makeStyles((theme) => ({
  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )',
    borderTop: '1px solid',
    borderColor:theme.borderColor
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
