import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const FormEdit =(props)=> {
  const {row, userActions, title, user, handleClose, handleSave} = props
  const classes = useStyles();
  const [state, setstate] = useState({})

  useEffect(() => {
      if(row){
      	setstate(row)
      }
  }, [row])

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    newState[fieldName] = value
    setstate(newState)
  }

  const submit = async()=>{
    const response = await handleSave(state)
    handleClose()
  }


  return (
    <>
      <DialogTitle id="form-dialog-title">Edit {title}</DialogTitle>
      <DialogContent style={{width:500}}>
        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="billingId"
            label="billingId"
            fullWidth
            value={state.billingId}
            onChange={(e)=>handleChange("billingId",e)}
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="clientId"
            label="clientId"
            fullWidth
            value={state.clientId}
            onChange={(e)=>handleChange("clientId",e)}
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="amount"
            label="amount"
            fullWidth
            value={state.amount}
            onChange={(e)=>handleChange("amount",e)}
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="type"
            label="type"
            fullWidth
            value={state.type}
            onChange={(e)=>handleChange("type",e)}
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="createdBy"
            label="createdBy"
            fullWidth
            value={state.createdBy}
            onChange={(e)=>handleChange("createdBy",e)}
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="status"
            label="status"
            fullWidth
            value={state.status}
            onChange={(e)=>handleChange("status",e)}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>
</>

    );
}

export default FormEdit;

const useStyles = makeStyles((theme) => ({

}));
