import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import {doGet} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme.js'
import DateFnsUtils from '@date-io/date-fns';
import {
  DateTimePicker,MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { format } from 'date-fns'
import {generalListOptionMapper} from 'utils/mappers.js'
import NumberFormatCustom2 from 'components/NumberFormatCustom2.js';

const FormAdd =(props)=> {
  const {title, user, countbyOptions, discountOptions, clientOptions, categoryOptions, handleClose, handleSave} = props
  const classes = useStyles();
  const [productOptions, setproductOptions] = useState([])
  const [client, setclient] = useState(null)
  const [product, setproduct] = useState(null)
  const [countby, setcountby] = useState(null)
  const [state, setstate] = useState({
    countby:null,
    client:null,
    product:null,
    creator:user.id,
    dueDate:new Date(),
    discount:null,
    discountValue:0,
    totalDiscount:0,
    quantity:0,
    price:0,
    total:0
  })

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    if(fieldName==="client"){      
      getClientDetail(eventValue.value)      
      if(state.countby){
        if(state.countby.value===2){
          newState['quantity'] = eventValue.userstudent
        } 
      }
    }

    if(fieldName==="product"){
      if(client.products.length>0){
        const selectedProduct = client.products.filter(p=>(p.id===eventValue.value))[0]
        setproduct(selectedProduct)  
        newState['price'] = selectedProduct.price

        if(state.countby){
          if(state.countby.value===1){
            newState['quantity'] = selectedProduct.pivot.quantity
          } 
        }                
        
      }else{        
        newState['price'] = 0        
        setproduct(null)
      }
    }

    if(fieldName==="countby"){
      if(eventValue){
        if(eventValue.value===1){
          if(state.product){
            newState['quantity'] = state.product.pivot.quantity        
          }else{
            newState['quantity'] = 0        
          }
        }else{
          if(state.client){
            newState['quantity'] = state.client.userstudent
          }else{
            newState['quantity'] = 0        
          }
        }        
      }
      
    }


    newState[fieldName] = value
    setstate(newState)
  }

  
  const getClientDetail =async(id)=>{
    let params = {   }
    
    const response = await doGet(endpoint.client_id_relation.replace('id',id), params, getDefaultHeader())
    if(response){
      const clientRes = response.data
      setclient(clientRes)
      if(clientRes && clientRes.products){
        setproductOptions(generalListOptionMapper(clientRes.products))
      }      
    }
  }
  


  useEffect(() => {
    if(state.quantity && state.price && state.countby){      
      let total = 0
      let price = state.price
      let discountValue = 0
      let totalDiscount = 0

      if(state.discount){

        if(state.discount.type===3){
          if((state.quantity*price) > state.discount.discount){
            total = state.discount.discount          
            discountValue = state.discount.discount
            
            if(state.countby.value===1){                                   
              totalDiscount = (state.quantity*price) - total
            }else{            
              totalDiscount = (state.client.userstudent*price) - total
            }

          }else{
            if(state.countby.value===1){
              total = state.quantity*price          
            }else{
              total = state.client.userstudent *price          
            }           
          }
          

        }else{
          if(state.discount.type===1){
            discountValue = state.discount.discount
            price = price - discountValue
          }
          else
          if(state.discount.type===2){
            discountValue = (state.price * state.discount.discount) / 100
            price = price - discountValue
          }
        }      

      }
      else{
        if(state.countby.value===1){
          total = state.quantity*price          
        }else{
          total = state.client.userstudent *price          
        } 
      }

          
      
      setstate({...state, total:total, discountValue:discountValue,totalDiscount:totalDiscount})
    }
    
    
  }, [state.quantity, state.price, state.discount, state.countby])

  useEffect(() => {
    setstate({...state, product:null})
}, [state.client])


  const submit = async()=>{    
    if(state.client && state.product){
      const payload = {
        ...state,
        category: state.category.value,
        client:state.client.value,
        product:state.product.value,
        dueDate:format(state.dueDate, 'yyyy-MM-dd HH:mm:ss'),
        countby:state.countby.value,
        discount:state.discount ? state.discount.value:null,
        discountValue:state.discountValue
      }
      const response = await handleSave(payload)
      if(response.data.message==="success"){
        reset()
      }
    }    
  }


  const reset=()=>{
    setstate({})
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">Tambah {title}</DialogTitle>
      <DialogContent>
        <Grid container item xs={12} justify='center'>
          <Grid item container style={{margin:'0 0 16px 0'}}>
            <Select
              name="category"
              placeholder = "transaction category..."
              options={categoryOptions}
              onChange={(e)=>handleChange("category",e)}
              value={state.category}
              styles={selectCustomZindex}
            />                
          </Grid>

          <Grid item container style={{margin:'0 0 16px 0'}}>
            <Select
              name="countby"
              placeholder = "count bill by..."
              options={countbyOptions}
              onChange={(e)=>handleChange("countby",e)}
              value={state.countby}
              styles={selectCustomZindex}
            />                
          </Grid>
          
          <Grid item container style={{margin:'0 0 16px 0'}}>
            <Select
                placeholder = "select client..."
                options={clientOptions}
                onChange={(e)=>handleChange("client",e)}
                value = {state.client}
                styles={selectCustomZindex}
              />  
          </Grid>
          <Grid container style={{margin:'0 0 16px 0'}} >
            <Select
                placeholder = "select product..."
                options={productOptions}
                onChange={(e)=>handleChange("product",e)}
                value = {state.product}
                styles={selectCustomZindex}
              />            
          </Grid>

          <Grid item container style={{margin:'0 0 16px 0'}}>
            <Select
                placeholder = "select discount..."
                options={discountOptions}
                onChange={(e)=>handleChange("discount",e)}
                value = {state.discount}
                styles={selectCustomZindex}
                isClearable={true}
              />  
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>                         
            <TextField
              type="number"              
              margin="dense"
              id="quantity"
              label={state.countby && state.countby.label}
              fullWidth
              value={state.quantity}  
              readOnly  
            />
          </Grid>
          <Grid container justify='center' alignItems='flex-start'>
            {
            product && 
              <TextField
                margin="dense"
                id="price"                                                          
                name="price"
                label="price"
                fullWidth
                value={product.price}
                InputProps={{
                  inputComponent: NumberFormatCustom2,
                }}
                readOnly
              />              
              }
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
          {
            state.discount && 
              
              <TextField
                type="number"              
                margin="dense"
                id="discount"
                label="discount"
                fullWidth
                value={state.discount.discount}  
                readOnly  
              />
              }
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
          {
            product && 
              <TextField
                margin="dense"
                id="total"                                                          
                name="total"
                label="total"
                fullWidth                
                InputProps={{
                  inputComponent: NumberFormatCustom2,
                }}
                value={state.total}  
                readOnly
              /> 
              }
          </Grid>

          <Grid container>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                ampm={false}
                value={state.dueDate}
                onChange={(e)=>handleChange("dueDate",e)}
                label="Due Date"
                variant="inline"
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>
</>

    );
}

export default FormAdd;

const useStyles = makeStyles((theme) => ({

}));
