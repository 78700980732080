import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {doGet} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Protected   from 'components/Protected.js';
import SubDetail from './SubDetail.js' 


const FormDetail =(props)=> {
  const {title, userActions, user, row, handleClose, changepageAction, pageAction, setting} = props
  const classes = useStyles();
  const [state, setstate] = useState(null)

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  useEffect(() => {
      if(row){
      	getRowComplete()
      }
  }, [row])

  const getRowComplete =async()=>{
    let params = {}
    
    const response = await doGet(endpoint.user_id_relation.replace("id",row.id), params, getDefaultHeader())
    if(response){      
      setstate(response.data)
    }
  }

  if(row)
    return (
    <>
      <DialogTitle id="form-dialog-title">Detail</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} justify='space-between' className={classes.root}>
            <Grid container item xs={12} sm={12} md={4} lg={4} justify='flex-start' alignItems='flex-start' alignContent="flex-start" className={classes.left}>
              <Grid container alignContent="center" justify="center" className={classes.header}>
                <Typography variant="h6">Data {title}</Typography>
              </Grid>
              <Grid container justify='center' alignItems='flex-start' className={classes.content}>
                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="Id"
                    label="Id"
                    fullWidth
                    value={row.id}
                    readOnly
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="username"
                    label="Username"
                    fullWidth
                    value={row.username}
                    readOnly
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="type"
                    label="Type"
                    fullWidth
                    value={row.type && row.type.name}
                    readOnly
                  />
                </Grid>
                
              </Grid>
            </Grid>

      <Grid container item xs={12} sm={12} md={7} lg={7} justify='flex-end' alignItems="flex-start" alignContent="flex-start" className={classes.right}>
          <SubDetail row={state} user={user} pageAction={pageAction}/>
      </Grid>
    </Grid>
  </DialogContent>


      { user && setting &&
        <DialogActions>
          <Protected user={user} userActions={userActions} allowedCodes={['EUSER']} activeMenu={setting.active_menu}>
            <Button onClick={()=>changepageAction("edit")} color="primary">
              Edit
            </Button>
          </Protected>
          <Button variant="contained" onClick={handleClose} color="primary">
            Kembali
          </Button>
        </DialogActions>
      }

  </>

    )
    else{
      return null
    }
}

export default FormDetail;

const useStyles = makeStyles((theme) => ({
  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )',
    borderTop: '1px solid',
    borderColor:theme.borderColor
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
