import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const FormAdd =(props)=> {
  const {title, user, handleClose, handleSave} = props
  const classes = useStyles();
  const [state, setstate] = useState({})

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    newState[fieldName] = value
    setstate(newState)
  }

  const submit = async()=>{
    const response = await handleSave(state)
    if(response.data.message==="success"){
      reset()
    }
  }


  const reset=()=>{
    setstate({})
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">Tambah {title}</DialogTitle>
      <DialogContent>
      <Grid container item xs={12} justify='center'>
        <Grid container justify='center' alignItems='flex-start' >
          <DebouncedTextField
            margin="dense"
            id="Name"
            label="Name"
            fullWidth
            value={state.name}
            onChange={(e)=>handleChange("name",e)}
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="cash"
            label="cash"
            fullWidth
            value={state.cash}
            onChange={(e)=>handleChange("cash",e)}
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="client"
            label="client"
            fullWidth
            value={state.client}
            onChange={(e)=>handleChange("client",e)}
          />
        </Grid>    

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="balance"
            label="balance"
            fullWidth
            value={state.balance}
            onChange={(e)=>handleChange("balance",e)}
          />
        </Grid>     
      </Grid>
        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>
</>

    );
}

export default FormAdd;

const useStyles = makeStyles((theme) => ({

}));
