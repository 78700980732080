import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination   from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import StatusChip from 'components/StatusChip';

const DataTable =(props)=> {
  const { user, showDetail, toggleSelectRow, toggleSelectAllRow, selectedIds, dataTable, totalRows, page, rowsPerPage, changePage, changeRowsPerPage} = props
  const classes = useStyles()

  return (
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Checkbox
                  color="primary"
                  inputProps={{ 'aria-label': 'select all' }}
                  onChange={toggleSelectAllRow}
                  checked={selectedIds.length === dataTable.length && dataTable.length>0}
                />
              </TableCell>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">Username</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Number</TableCell>
              <TableCell align="center">joinDate</TableCell>
              <TableCell align="center">resignDate</TableCell>   
              <TableCell align="center">status</TableCell>              
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedIds && dataTable && dataTable.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                <Checkbox
                    checked={selectedIds.includes(row.id)}
                    onChange={()=>toggleSelectRow(row)}
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                  />
                </TableCell>
                <TableCell >{row.id}</TableCell>
                <TableCell >{row.user && row.user.username}</TableCell>
                <TableCell onClick={()=>showDetail(row)} className={classes.clickable}>{row.name}</TableCell>
                <TableCell align="center">{row.empNumber}</TableCell>
                <TableCell align="center">{row.joinDate}</TableCell>
                <TableCell align="center">{row.resignDate}</TableCell>
                <TableCell align="center"><StatusChip status={Number(row.status)}/></TableCell>                
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Grid container justify="flex-end" className={classes.paginationContainer}>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page-1}
              backIconButtonProps={{
                  'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                  'aria-label': 'next page',
              }}
              onChangePage={changePage}
              onChangeRowsPerPage={changeRowsPerPage}
          />
        </Grid>
      </TableContainer>
    );
}

export default DataTable;


const useStyles = makeStyles((theme) => ({

  tableContainer: {
    minHeight:400,
  },
  clickable:{
    cursor:'pointer',
    fontWeight:'bold',
    color:theme.palette.primary.main
  },
  buttonContainer:{
    marginTop:theme.spacing(2)
  },

  paginatonContainer: {
    height:40,
  }

}));
