import React,{useState} from 'react';
import {endpoint} from 'utils/constants.js'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme.js'
import SearchListAsync   from 'components/SearchListAsync.js';
import {userOptionMapper} from 'utils/mappers.js'
import Conditional from 'components/Conditional.js';
import IconButton from '@material-ui/core/IconButton';
import Cancel from '@material-ui/icons/Cancel';
import SmallConfirmation   from 'components/SmallConfirmation.js';

const FormAdd =(props)=> {
  const {title, user, handleClose, handleSave, typeOptions} = props
  const classes = useStyles();
  const [state, setstate] = useState({
    user:null,
    type:null
  })
  const [anchorSmallConfirmation, setanchorSmallConfirmation] = useState(null);
  

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    newState[fieldName] = value
    setstate(newState)
  }

  const addUser= async (userOption)=>{
    const user = {
      id:userOption.value,
      username:userOption.label
    }
    handleChange('user',user)
  }

  const removeUser=async()=>{
    handleChange('user',null)
  }

  const removeClick=(e)=>{    
    setanchorSmallConfirmation(e.currentTarget)
  }

  const closeSmallConfirmation=()=>{
    setanchorSmallConfirmation(null)    
  }

  const submit = async()=>{
    const modifiedData = {
      ...state,
      type:state.type ? state.type.value : null,
      user:state.user ? state.user.id : null
    }
    const response = await handleSave(modifiedData)
    if(response.data.message==="success"){
      reset()
    }
  }


  const reset=()=>{
    setstate({})
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">Tambah {title}</DialogTitle>
      <DialogContent>
      <Grid container item xs={12} justify='center'>
        <Grid container justify='center' alignItems='flex-start' >
          <DebouncedTextField
            margin="dense"
            id="Name"
            label="Name"
            fullWidth
            value={state.name}
            onChange={(e)=>handleChange("name",e)}
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
        <Select
            name="type"
            placeholder = "vendor type"
            options={typeOptions}
            onChange={(e)=>handleChange("type",e)}
            value={state.type}
            styles={selectCustomZindex}
          />
        </Grid>

        <Conditional condition={state.user===null}>
          <Grid container alignItems='flex-start'>
            <SearchListAsync 
              user={user} 
              mapper={userOptionMapper}
              path={endpoint.user_options} 
              action={addUser} 
              placeholder="search username..."
              />
          </Grid>
          </Conditional>
          <Conditional condition={state.user !==null}>
          <Grid container alignItems='center'>
            <Grid item xs={11} container justify="flex-start">
              <DebouncedTextField
                margin="dense"
                id="user"
                label="user"
                fullWidth
                value={state.user && state.user.username}
                readOnly
              />
            </Grid>
            <Grid item xs={1} container justify="flex-end" alignItems="center">
              <IconButton
              onClick={removeClick}
              size="small"
              aria-label="close"
              className={clsx(classes.margin1,classes.closeButton)}>
                <Cancel fontSize="inherit" />
              </IconButton>  
            </Grid>  
            </Grid>                   
          </Conditional>   
        
      </Grid>
        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>

      <SmallConfirmation
          anchor={anchorSmallConfirmation}
          handleClose={closeSmallConfirmation}
          execute={removeUser}
          actionName={"delete"}/>
</>

    );
}

export default FormAdd;

const useStyles = makeStyles((theme) => ({

}));
