import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination   from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';

const DataTable =(props)=> {
  const { user, userActions, showDetail, toggleSelectRow, toggleSelectAllRow, selectedIds, dataTable, totalRows, page, rowsPerPage, changePage, changeRowsPerPage} = props
  const classes = useStyles()

  
  if(userActions.filter(action=>action.code==='RBILL')[0]){
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Checkbox
                  color="primary"
                  inputProps={{ 'aria-label': 'select all' }}
                  onChange={toggleSelectAllRow}
                  checked={selectedIds.length === dataTable.length && dataTable.length>0}
                />
              </TableCell>
              <TableCell >ID</TableCell>
              <TableCell >Category</TableCell>
              <TableCell >Client</TableCell>
              <TableCell >Product</TableCell>
              <TableCell > <Typography display="inline"> issued_date</Typography> </TableCell>
              <TableCell >dueDate</TableCell>
              <TableCell >creator</TableCell>
              <TableCell >invoiceNumber</TableCell>
              <TableCell >paymentstatus</TableCell>
              <TableCell >issuedstatus</TableCell>
              <TableCell >status</TableCell>
              <TableCell >quantity</TableCell>
              <TableCell >price</TableCell>
              <TableCell >discount</TableCell>
              <TableCell >total_discount</TableCell>
              <TableCell >total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedIds && dataTable && dataTable.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                <Checkbox
                    checked={selectedIds.includes(row.id)}
                    onChange={()=>toggleSelectRow(row)}
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                  />
                </TableCell>
                <TableCell >{row.id}</TableCell>                                
                <TableCell onClick={()=>showDetail(row)} className={classes.clickable}>{row.category && row.category.name}</TableCell>                
                <TableCell >{row.client && row.client.name}</TableCell>
                <TableCell >{row.product && row.product.name}</TableCell>
                <TableCell >{row.issuedDate}</TableCell>
                <TableCell >{row.dueDate}</TableCell>
                <TableCell >{row.creator.username}</TableCell>
                <TableCell >{row.invoiceNumber}</TableCell>
                <TableCell >{row.paymentstatus.name}</TableCell>
                <TableCell >{row.issuedstatus.name}</TableCell>
                <TableCell >{row.status.name}</TableCell>
                <TableCell >{row.quantity}</TableCell>
                <TableCell >
                  <NumberFormat value={row.price} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} /> 
                </TableCell>
                <TableCell >
                  <NumberFormat value={row.discountValue} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} /> 
                </TableCell>
                <TableCell >
                  <NumberFormat value={row.totalDiscount} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} /> 
                </TableCell>
                <TableCell >
                  <NumberFormat value={row.total} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} /> 
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Grid container justify="flex-end" className={classes.paginationContainer}>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page-1}
              backIconButtonProps={{
                  'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                  'aria-label': 'next page',
              }}
              onChangePage={changePage}
              onChangeRowsPerPage={changeRowsPerPage}
          />
        </Grid>
      </TableContainer>
    );
  }else{
    return null
  }
  
}

export default DataTable;


const useStyles = makeStyles((theme) => ({

  tableContainer: {
    minHeight:400,
  },
  clickable:{
    cursor:'pointer',
    fontWeight:'bold',
    color:theme.palette.primary.main
  },
  buttonContainer:{
    marginTop:theme.spacing(2)
  },

  paginatonContainer: {
    height:40,
  }

}));
