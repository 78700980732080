export const dummyuserTypes = {
    data:[
        {
            name:"Client",
            value:1
        },
        {
            name:"Employee",
            value:2
        },
        {
            name:"Vendor",
            value:3
        }
    ]
}

export const dummyBills = {
    data:[
        {
            
            id:123,
            name:"SPP bulan Agustus",
            category:{
                id:1,
                 name: "SPP" ,
                 flow:1,
                 charge:500000,
                 partial:1,
                 minPay:50000,
                 payTimes:10,
                 recurrence:1,
                 recurrenceDate:1
            },
            price:1000000,
            total:1000000,
            paidAmount:400000,
            discount:{},

        },
        {
            
            id:124,
            name:"SPP bulan September",
            category:{
                id:1,
                 name: "SPP" ,
                 flow:1,
                 charge:500000,
                 partial:1,
                 minPay:50000,
                 payTimes:10,
                 recurrence:1,
                 recurrenceDate:1
            },
            price:1000000,
            total:1000000,
            paidAmount:400000,
            discount:{},
        },
        {
            
            id:125,
            name:"SPP bulan Oktober",
            category:{
                id:1,
                 name: "SPP" ,
                 flow:1,
                 charge:500000,
                 partial:1,
                 minPay:50000,
                 payTimes:10,
                 recurrence:1,
                 recurrenceDate:1
            },
            price:1000000,
            total:1000000,
            paidAmount:400000,
            discount:{},
        },
        {
            id:321,
            name:"Seragam",
            category:{
                id:2,
                 name: "Bangunan" ,
                 flow:1,
                 charge:500000,
                 partial:1,
                 minPay:50000,
                 payTimes:10,
                 recurrence:1,
                 recurrenceDate:1
            },
            price:1000000,
            total:1000000,
            paidAmount:400000,
            discount:{},
        }
    ]
}

export const dummyCardQueues = {
    data:[
        {
            no:123,
            name:"alkawero"
        },
        {
            no:321,
            name:"tulus"
        }
    ]
}

export const dummyTransactionCategories = {
    data:[
        {
            id:1,
             name: "SPP" ,
             flow:1,
             charge:500000,
             partial:1,
             minPay:50000,
             payTimes:10,
             recurrence:1,
             recurrenceDate:1
        },
        {
            id:2,
             name: "BUKU" ,
             flow:1,
             charge:100000,
             partial:1,
             minPay:10000,
             payTimes:10,
             recurrence:0,
             recurrenceDate:null
        },
        {
            id:3,
             name: "ANAK YATIM" ,
             flow:0,
             charge:1000000,
             partial:1,
             minPay:1000000,
             payTimes:1,
             recurrence:1,
             recurrenceDate:new Date()
        },
        {
            id:4,
             name: "GAJI" ,
             flow:0,
             charge:1000000,
             partial:1,
             minPay:1000000,
             payTimes:1,
             recurrence:1,
             recurrenceDate:new Date()
        },
        {
            id:5,
             name: "BUKU" ,
             flow:1,
             charge:100000,
             partial:1,
             minPay:10000,
             payTimes:10,
             recurrence:0,
             recurrenceDate:null
        },
        {
            id:6,
             name: "system" ,
             flow:0,
             charge:1000000,
             partial:0,
             minPay:1000000,
             payTimes:1,
             recurrence:0,
             recurrenceDate:null
        }
    ]
}