import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const Confirmation =(props)=> {
  const {close, action, actionName, message, title} = props

  const onAction = ()=>{
    action()
    close()
  }

  return (
  <>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  onClick={onAction} color="secondary">
          {actionName}
        </Button>
        <Button variant="contained" onClick={close} color="primary">
          Batal
        </Button>
      </DialogActions>
  </>

    );
}

export default Confirmation;
