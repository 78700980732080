import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination   from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import { format,parse, fromUnixTime } from 'date-fns'

const PaymentTable =(props)=> {
  const { user, showDetail, toggleSelectRow, toggleSelectAllRow, selectedIds, dataTable, totalRows, page, rowsPerPage, changePage, changeRowsPerPage} = props
  const classes = useStyles()

  return (
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Checkbox
                  color="primary"
                  inputProps={{ 'aria-label': 'select all' }}
                  onChange={toggleSelectAllRow}
                  checked={selectedIds.length === dataTable.length && dataTable.length>0}
                />
              </TableCell>
              <TableCell >amount</TableCell>
              <TableCell >fee</TableCell>
              <TableCell >grossAmount</TableCell>
              <TableCell >orderId</TableCell>
              <TableCell >vaNumber</TableCell>
              <TableCell >expired</TableCell>
              <TableCell >client</TableCell>
              <TableCell >Status</TableCell>                            
              <TableCell >channel</TableCell>
              <TableCell >transactionTime</TableCell>
              <TableCell >statusCode</TableCell>
              <TableCell >transactionId</TableCell>
              <TableCell >fraudStatus</TableCell>
              <TableCell >statusMessage</TableCell>
              <TableCell >billKey</TableCell>
              <TableCell >billerCode</TableCell>
              <TableCell >approvalCode</TableCell>
              <TableCell >paymentType</TableCell>              
              <TableCell >bank</TableCell>              
              <TableCell >store</TableCell>              
              <TableCell >creator</TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedIds && dataTable && dataTable.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                <Checkbox
                    checked={selectedIds.includes(row.id)}
                    onChange={()=>toggleSelectRow(row)}
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                  />
                </TableCell>
                <TableCell>{row.amount}</TableCell>
                <TableCell>{row.fee}</TableCell>
                <TableCell onClick={()=>showDetail(row)} className={classes.clickable}>{row.grossAmount}</TableCell>
                <TableCell>{row.orderId}</TableCell>
                <TableCell>{row.vaNumber}</TableCell>
                <TableCell>{format(fromUnixTime(row.expired),'dd-MM-yyyy HH:mm')}</TableCell>
                <TableCell>{row.oneclient && row.oneclient.name }</TableCell>
                <TableCell>{row.transactionStatus}</TableCell>
                <TableCell>{row.onechannel && row.onechannel.name}</TableCell>
                <TableCell>{row.transactionTime}</TableCell>
                <TableCell>{row.statusCode}</TableCell>
                <TableCell>{row.transactionId}</TableCell>
                <TableCell>{row.fraudStatus}</TableCell>
                <TableCell>{row.statusMessage}</TableCell>
                <TableCell>{row.billKey}</TableCell>
                <TableCell>{row.billerCode}</TableCell>
                <TableCell>{row.approvalCode}</TableCell>
                <TableCell>{row.paymentType}</TableCell>                
                <TableCell>{row.store}</TableCell>
                <TableCell>{row.bank}</TableCell>                
                <TableCell>{row.creator}</TableCell>
                
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Grid container justify="flex-end" className={classes.paginationContainer}>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page-1}
              backIconButtonProps={{
                  'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                  'aria-label': 'next page',
              }}
              onChangePage={changePage}
              onChangeRowsPerPage={changeRowsPerPage}
          />
        </Grid>
      </TableContainer>
    );
}

export default PaymentTable;


const useStyles = makeStyles((theme) => ({

  tableContainer: {
    minHeight:400,
  },
  clickable:{
    cursor:'pointer',
    fontWeight:'bold',
    color:theme.palette.primary.main
  },
  buttonContainer:{
    marginTop:theme.spacing(2)
  },

  paginatonContainer: {
    height:40,
  }

}));
