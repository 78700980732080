import React from 'react'
import './App.css'
import { Provider } from "react-redux"
import store from 'reduxs/store'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Login from "./features/login/Login.js"
import Home from "./features/home/Home.js"
import {ThemeProvider} from '@material-ui/core/styles';
import theme from 'utils/theme.js'


function App() {

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/home" component={Home} />
              <Route path="/login" component={Login} />
            </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
