import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Protected   from 'components/Protected.js';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const FormDetail =(props)=> {
  const {title, userActions, user, row, handleClose, changeAction, setting} = props
  const classes = useStyles();

  if(row)
    return (
    <>
      <DialogTitle id="form-dialog-title">Detail {title}</DialogTitle>
      <DialogContent>
        <Grid container item xs={12} justify='center'>
        <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="type"
              label="type"
              fullWidth
              value={row.type}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="name"
              label="name"
              fullWidth
              value={row.name}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="gateway"
              label="gateway"
              fullWidth
              value={row.onegateway && row.onegateway.name}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="corp"
              label="corp"
              fullWidth
              value={row.corp}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="price"
              label="price"
              fullWidth
              value={row.price}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <img src={row.logo} alt={row.alt} style={{height:200}} />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="alt"
              label="alt"
              fullWidth
              value={row.alt}
              readOnly
            />
          </Grid>

          <Grid container justify='flex-start' alignItems='center'>
            <FormControlLabel
              value="status"
              control={
                  <Switch 
                  color="primary" 
                  checked={Number(row.status.value)===1} 
                  />
                }
              label="Status"
              labelPlacement="start"
              style={{marginLeft:0}}
            />
          </Grid>

          
        </Grid>

      </DialogContent>

      { user && setting &&
        <DialogActions>
          <Protected user={user}  userActions={userActions} allowedCodes={['ECHANNEL']} activeMenu={setting.active_menu}>
            <Button onClick={()=>changeAction("edit")} color="primary">
              Edit
            </Button>
          </Protected>
          <Button variant="contained" onClick={handleClose} color="primary">
            Kembali
          </Button>
        </DialogActions>
      }

  </>

    )
    else{
      return null
    }
}

export default FormDetail;

const useStyles = makeStyles((theme) => ({

}));
