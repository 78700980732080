import React from 'react';
import NumberWidget from './NumberWidget';

const WidgetComponent =(props)=> {  
  const {user, widget} = props
  
  if(widget.type===1){
    return (
      <NumberWidget user={user} widget={widget}/>
    );
  }
  else{
    return null
  }

}

export default WidgetComponent;
