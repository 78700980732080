import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';


const SubDetail =(props)=> {
  const {user, userActions, row, toggleRole, pageAction} = props
  const classes = useStyles();
  const [allRoles, setallRoles] = useState([])
  const [selectedRole, setselectedRole] = useState({})

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  useEffect(() => {
      if(user){
          getRoles()
      }
  }, [user])

  const getRoles =async()=>{
    let params = {}
    
    const response = await doGet(endpoint.role, params, getDefaultHeader())
    if(response){
      //const modifiedData = response.data.data.map(row=>({...row, selected:false}))
      //setDataTable(modifiedData)
      setallRoles(response.data)
    }
  }

  const onToggleRole =(role,event)=>{
    if(["add","edit"].includes(pageAction)){
      const toggle = event.target.checked?1:0
      toggleRole(row.id, role.id, toggle)
    }
  }

  const getRoleIdsFromUser=()=>{
    return row.roles.map(item=>(item.id))
  }

  return (      
        <Grid container item xs={12} sm={12} md={7} lg={7} justify='center' alignItems='flex-start' alignContent="flex-start" className={classes.root}>
            <Grid container alignContent="center" justify="center" className={classes.header}>
              <Typography variant="h6">Role</Typography>
            </Grid>
            <List component="nav" className={classes.content}>
              {
                row && allRoles && allRoles.map(item=>(
                  <ListItem button key={item.id}>
                    <ListItemText primary={item.name} />
                    <ListItemSecondaryAction>
                      <Switch
                      color="primary"
                      size="small"
                      checked={getRoleIdsFromUser().includes(item.id)}
                      onChange={(e)=>onToggleRole(item,e)}/>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))

              }

            </List>
        </Grid>      
    );
}

export default SubDetail;

const useStyles = makeStyles((theme) => ({
  root: {

  },
  left:{

    //borderRight: `1px solid ${theme.borderColor}`
  },
  right:{

  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: 4,
    width:'inherit',
    minHeight:300
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },

}));
