import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {selectCustomZindex} from 'utils/theme.js'

const FormAdd =(props)=> {
  const {title, userActions, user, handleClose, handleSave, discountTypes} = props
  const classes = useStyles();
  const [state, setstate] = useState({})

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    if(fieldName==="status"){
      value = eventValue.target.checked? 1:0
    }

    if(fieldName==="expired"){
      value = eventValue.target.checked? 1:0
    }

    newState[fieldName] = value
    setstate(newState)
  }

  const submit = async()=>{
    const payload = {      
      name:state.name,
      discount:state.discount,
      type:state.type.value,
      status:state.status,
      startDate:state.startDate,
      endDate:state.endDate,
      expiredAt:state.expiredAt,      
      expired:state.expired
    }
    const response = await handleSave(payload)    
    if(response.data.message==="success"){
      reset()
    }
  }


  const reset=()=>{
    setstate({})
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">Tambah {title}</DialogTitle>
      <DialogContent>
      <Grid container item xs={12} justify='center'>
        <Grid container justify='center' alignItems='flex-start' >
          <DebouncedTextField
            margin="dense"
            id="Name"
            label="Name"
            fullWidth
            value={state.name}
            onChange={(e)=>handleChange("name",e)}
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <Select 
            name="type"
            placeholder = "type..."
            options={discountTypes}
            onChange={(e)=>handleChange("type",e)}
            value={state.type}
            styles={selectCustomZindex}                                       
              />    
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="discount"
            label="discount"
            fullWidth
            value={state.discount}
            onChange={(e)=>handleChange("discount",e)}
          />
        </Grid>    

        <Grid container alignItems='flex-start'>
            <FormControlLabel
                value="expired"
                control={
                    <Switch 
                    color="primary" 
                    checked={Number(state.expired)===1} 
                    onChange={(e)=>handleChange("expired",e)}
                    />
                  }
                label="Life time"
                labelPlacement="start"
                style={{marginLeft:0}}
              />            
          </Grid>     
  
          <Grid container alignItems='flex-start'>            
            <FormControlLabel
              value="status"
              control={
                  <Switch 
                  color="primary" 
                  checked={Number(state.status)===1} 
                  onChange={(e)=>handleChange("status",e)}
                  />
                }
              label="Status"
              labelPlacement="start"
              style={{marginLeft:0}}
            />
          </Grid>
      </Grid>
        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>
</>

    );
}

export default FormAdd;

const useStyles = makeStyles((theme) => ({

}));
