import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Protected   from 'components/Protected.js';

const FormDetail =(props)=> {
  const {title, userActions, user, row, handleClose, changeAction, setting} = props
  const classes = useStyles();

  if(row)
    return (
    <>
      <DialogTitle id="form-dialog-title">Detail {title}</DialogTitle>
      <DialogContent>
      <Grid container item xs={12} justify='center'>
      <Grid container justify='center' alignItems='flex-start' >
            <DebouncedTextField
              margin="dense"
              id="Name"
              label="Name"
              fullWidth
              value={row.name}
              readOnly
            />
          </Grid>
  
          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="type"
              label="type"
              fullWidth
              value={row.type.name}
              readOnly
            />
          </Grid>
  
          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="discount"
              label="discount"
              fullWidth
              value={row.discount}
              readOnly
            />
          </Grid>    
  
          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="expired"
              label="expiry type"
              fullWidth
              value={row.expired.name}
              readOnly
            />
          </Grid>     
  
          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="status"
              label="status"
              fullWidth
              value={row.status.name}
              readOnly
            />
          </Grid> 

        </Grid>
      </DialogContent>

      { user && setting &&
        <DialogActions>
          <Protected user={user}  userActions={userActions} allowedCodes={['EDISC']} activeMenu={setting.active_menu}>
            <Button onClick={()=>changeAction("edit")} color="primary">
              Edit
            </Button>
          </Protected>
          <Button variant="contained" onClick={handleClose} color="primary">
            Kembali
          </Button>
        </DialogActions>
      }

  </>

    )
    else{
      return null
    }
}

export default FormDetail;

const useStyles = makeStyles((theme) => ({

}));
