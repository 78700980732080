import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {endpoint} from 'utils/constants.js'
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import NumberFormatCustom from 'components/NumberFormatCustom.js';
import Conditional from 'components/Conditional.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Protected   from 'components/Protected.js';
import Select from 'react-select'
import MSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NumberFormat from 'react-number-format';
import TextField from "@material-ui/core/TextField";
import { format,parse } from 'date-fns'
import SmallConfirmation   from 'components/SmallConfirmation.js';
import StatusChip from 'components/StatusChip';


const PaymentGateway =(props)=> {
  const {title, userActions, user, setting, handleClose, pageAction} = props
  const classes = useStyles();
  const [newGateway, setnewGateway] = useState(null)
  const [gateways, setgateways] = useState([])  

  const [anchorSmallConfirmation, setanchorSmallConfirmation] = useState(null);  
  const [todo, settodo] = useState(null)


  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  useEffect(() => {
    getpaymentGateways()  
  }, [])

  

  const addGateway= async(payload)=>{    
    
    
    await doPost(endpoint.payment_gateway, payload,"insert "+setting.active_menu.display ,getDefaultHeader())
    setnewGateway(null)
    getpaymentGateways()
  }

  const removeGateway=async(payload)=>{
        
    
    const response = await doDelete(`${endpoint.payment_gateway}/${payload.id}`, {},"delete"+setting.active_menu.display ,getDefaultHeader())
    getpaymentGateways()
  }

  const updateGateway= async(payload)=>{
    
    await doPut(endpoint.payment_gateway, payload,"update "+setting.active_menu.display ,getDefaultHeader())
    setnewGateway(null)
    getpaymentGateways()
  }  
  
  const addNewClick=()=>{
    settodo("add")
    setnewGateway({
      name:"",
      host:"",
      status:1
    })
  }

  const editClick=(payload)=>{
    
    settodo("edit")
    let recurrenceDate = null
    setnewGateway(payload)

  }
  const removeClick=(payload,e)=>{
    settodo("delete")
    setnewGateway(payload)
    setanchorSmallConfirmation(e.currentTarget)
  }

  const closeSmallConfirmation=()=>{
    setanchorSmallConfirmation(null)
  }

  const newGatewayChange=(fieldName,eventValue)=>{        
    let newnewGateway = {...newGateway}
    let value = eventValue
    
    if(fieldName==="status"){
      value = eventValue.target.checked? 1:0      
    }

    newnewGateway[fieldName] = value
    setnewGateway(newnewGateway)
  }

  const getpaymentGateways =async()=>{
    let params = {}
    
    const response = await doGet(endpoint.payment_gateway, params, getDefaultHeader())    
    if(response){      
      setgateways(response.data)
    }
  } 

  const execute=()=>{

    const payload = {
      ...newGateway      
    }          
    
    if(todo==="add"){
        addGateway(payload)
    }
    else if(todo==="edit"){
        updateGateway(payload)
    }
    else if(todo==="delete"){
        removeGateway(payload)
    }

    setnewGateway(null)

}

  const cancel=()=>{
    setnewGateway(null)
  }


  if(user && setting){
    return (
      <>
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>Payment Gateway</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} justify='space-between' className={classes.root}>
            <Grid container spacing={1} justify='space-between' className={classes.form}>
              <Grid container item xs={12} sm={12} md={10} lg={10} spacing={1} justify='flex-start'>
                {
                  newGateway &&
                <>
                
                <Grid item xs={12} sm={12} md={2} lg={2} >
                  <DebouncedTextField
                    margin="dense"
                    id="name"
                    label="name"
                    variant="outlined"
                    fullWidth
                    value={newGateway.name}
                    onChange={(e)=>newGatewayChange("name",e)}
                  />
                </Grid>
  
                <Grid item xs={12} sm={12} md={3} lg={3} container alignContent='center' >
                  <DebouncedTextField
                    margin="dense"
                    id="host"
                    label="host"
                    variant="outlined"
                    fullWidth
                    value={newGateway.host}
                    onChange={(e)=>newGatewayChange("host",e)}
                  />          
                </Grid>  
                
                <Grid item container alignItems="center" xs={12} sm={12} md={2} lg={2}>
                  <FormControlLabel
                    value="status"
                    control={
                        <Switch 
                        color="primary" 
                        checked={Number(newGateway.status)===1} 
                        onChange={(e)=>newGatewayChange("status",e)}
                        />
                      }
                    label="Status"
                    labelPlacement="start"
                    style={{marginLeft:0}}
                  />
                </Grid>               
                  
                </>
              }
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} container justify="flex-end" alignContent="center">
                <Conditional condition={newGateway===null}>                                      
                      <Protected user={user}  userActions={userActions} allowedCodes={['CGATEWAY']} activeMenu={setting.active_menu}>
                        <Button size="small" variant="contained" color="primary" className={classes.smallButton} onClick={addNewClick}>Tambah</Button>
                      </Protected>                    
                </Conditional>
                <Conditional condition={newGateway!==null}>                  
                      <Button size="small" variant="contained" className={classes.smallButton} onClick={cancel}>Cancel</Button>                  
                      <Button size="small" variant="contained" color="primary" className={classes.smallButton} onClick={execute}>Simpan</Button>                  
                </Conditional>
              </Grid>
            </Grid>
            <Grid container>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead className={classes.backgroundPrimary}>
                  <TableRow>
                    <TableCell style={{color:"#ffffff"}}>ID</TableCell>
                    <TableCell style={{color:"#ffffff"}}>Nama</TableCell>
                    <TableCell style={{color:"#ffffff"}}>Host</TableCell>
                    <TableCell style={{color:"#ffffff"}}>status</TableCell>
                    <TableCell style={{color:"#ffffff"}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gateways && gateways.map((gateway) => (
                    <TableRow key={gateway.id}>
                      <TableCell>{gateway.id}</TableCell>
                      <TableCell>{gateway.name}</TableCell>
                      <TableCell>{gateway.host}</TableCell>
                      <TableCell><StatusChip status={Number(gateway.status)}/></TableCell>
                      <TableCell align="center">                        
                          <Protected user={user}  userActions={userActions} allowedCodes={['EGATEWAY']} activeMenu={setting.active_menu}>
                            <IconButton
                            onClick={()=>editClick(gateway)}
                            size="small"
                            aria-label="close"
                            className={clsx(classes.margin1,classes.closeButton)}>
                              <Edit fontSize="inherit" />
                            </IconButton>
                          </Protected>
                          <Protected user={user}  userActions={userActions} allowedCodes={['DGATEWAY']} activeMenu={setting.active_menu}>
                            <IconButton
                            onClick={(e)=>removeClick(gateway,e)}
                            size="small"
                            aria-label="close"
                            className={clsx(classes.margin1,classes.closeButton)}>
                              <Cancel fontSize="inherit" />
                            </IconButton>
                            <SmallConfirmation
                              anchor={anchorSmallConfirmation}
                              handleClose={closeSmallConfirmation}
                              execute={execute}
                              actionName={todo}
                            />                            
                          </Protected>                        
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </Grid>
          
  
          </Grid>
        </DialogContent>
        <DialogActions>        
          <Button variant="contained" onClick={handleClose} color="primary">
            Kembali
          </Button>
        </DialogActions>
  </>
  
      );
  
  }else{
    return null
  }

  }

export default PaymentGateway;

const useStyles = makeStyles((theme) => ({
  dialogTitle:{    
           
  },

  dialogContent:{
    paddingTop:theme.spacing(2),
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )',    
    borderTop: '1px solid',
    borderColor:theme.borderColor,
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
  backgroundPrimary:{
    backgroundColor:theme.palette.primary.main,
    color:"#ffffff"
  },
  form:{
    marginBottom:8
  }
}));

const selectCustomZindex = {
  control: (base, state) => ({
      ...base,
      minWidth: "150px",
      margin: "0 4px"
  }),
  container: (base, state) => {
      return {
          ...base,
          flex: 1,
          zIndex: state.isFocused ? "1100" : "1" //Only when current state focused
      };
  }
};

