import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';


const SmallConfirmation =(props)=> {
  const {handleClose, anchor, execute, actionName} = props
  const classes = useStyles()

  const yesClick = ()=>{
    execute()
    handleClose()
  }
  const noClick = ()=>{
    handleClose()
  }

  return (
    <Popover
      id="popup"
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      style={
        {margin:8}
      }
    >
      <Grid container style={{padding:8}}>
          <Grid container>
            <Typography variant="body2">
              are you sure want to {actionName} ?
            </Typography>
          </Grid>
          <Grid container justify="space-between">
            <Button onClick={yesClick} color="secondary">Yes</Button>
            <Button onClick={noClick} size="small" variant="contained" color="primary" className={classes.smallButton}>Cancel</Button>
          </Grid>
      </Grid>
    </Popover>
    );
}

export default SmallConfirmation;

const useStyles = makeStyles((theme) => ({
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em'
  }
}));
