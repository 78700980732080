import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {endpoint} from 'utils/constants.js'
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Select from 'react-select'
import TransactionCard from './TransactionCard.js'
import numeral from 'numeral'
import {dummyBills, dummyuserTypes} from './sample.js'
import {selectCustomZindex} from 'utils/theme.js'
import Conditional from "components/Conditional";
import {generalListOptionMapper,parameterListOptionMapper} from 'utils/mappers.js'


const FormAdd =(props)=> {
  const {title, userActions, user, handleClose, handleSave, setting, transactionCategories} = props
  const classes = useStyles();  
  const [transactions, settransactions] = useState([])
  const [userTypes, setuserTypes] = useState([])
  const [userType, setuserType] = useState(null)
  const [bills, setbills] = useState([])  
  const [selectedBills, setselectedBills] = useState([])
  const [users, setusers] = useState([])
  const [selectedUser, setselectedUser] = useState(null)
  const [totalCharge, settotalCharge] = useState(0)  
  const [selectUserLabel, setselectUserLabel] = useState("select...")


  const [flows, setflows] = useState([])
  const [flow, setflow] = useState(null)
  const [modes, setmodes] = useState([])
  const [mode, setmode] = useState(null)
  const [userCountTypes, setuserCountTypes] = useState([
    {value:1, label:'satu'},
    {value:2, label:'banyak'}
  ])
  const [userCountType, setuserCountType] = useState({value:1, label:'satu'})
  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  
  useEffect(() => {
    getUserTypes()
    getflows()
  }, [])

  
  useEffect(() => {
    let charges=0
    transactions.map(trx=>{
      
      if(trx.bill){
        charges = charges + (trx.bill.total - trx.bill.paidAmount)
      }else{
        charges =  charges + trx.category.charge
      }
    })
    settotalCharge(charges)
  }, [transactions])

  const getflows =async()=>{
    let params = {}
    
    const response = await doGet(endpoint.parameter_group+"/flow", params, getDefaultHeader())    
    if(response){      
        setflows(parameterListOptionMapper(response.data))
    }
  }

  const getmodes =async(flow)=>{
    let params = {
      group:'mode_type',
      keyName:'valid_flow',
      key:flow
    }
    
    const response = await doGet(endpoint.parameter_group_key, params, getDefaultHeader())    
    if(response){      
        setmodes(parameterListOptionMapper(response.data))
    }
  }

  const getUsers =async(type)=>{
    let params = {}
    
    let url = ""
    switch(type) {
      case 1:
        url = endpoint.client_options;
        break;
      case 2:
        url = endpoint.employee_options;
        break;
      case 3:
        url = endpoint.vendor_options;
        break;
      default:
        
    }

    const response = await doGet(url, params, getDefaultHeader())    
    if(response){      
        setusers(generalListOptionMapper(response.data))
    }
  }
  

  const getUserTypes =async()=>{
    let params = {
      status:1,
      codemenu:setting.active_menu.code
    } 
    
    //const response = await doGet(endpoint.card_queue, params, getDefaultHeader())
    const response = dummyuserTypes          
    if(response){      
        setuserTypes(parameterListOptionMapper(response.data))
    }
  }


  const transactionChange=(trx,fieldName,eventValue)=>{
    let oldTrx = transactions.filter(t=>t.no===trx.no)[0] 

    let value = eventValue
    if(fieldName==='notes'){
      value = eventValue.target.value
    }

    const newTrx = {...oldTrx, [fieldName]:value}
    const sorted = [...transactions.filter(t=>t.no !== trx.no), newTrx].sort((a, b) => (a.no > b.no) ? 1 : -1)
    settransactions(sorted)
  }

  const getTransactionType=(mode, flow)=>{
    if(mode===2 && flow===0){
      return 3
    }
    else{
      return 1
    }
  }
 

  const submit = async()=>{
    let trxs = transactions.map(trx=>({
      billing:null,
      amount:trx.amount,
      fee:0,
      grossAmount:trx.amount,
      type : getTransactionType(Number(trx.category.mode.value), Number(trx.category.flow.value)),
      creator:user.id,
      username:user.username,
      status:Number(trx.category.mode.value)===1 ? 1 : 0,
      mode : Number(trx.category.mode.value),
      wallet : trx.category.wallet.id,
      category : trx.category.id,
      flow : Number(trx.category.flow.value),     
      userType:trx.userType ? trx.userType.value : null,
      client : trx.userType && trx.userType.value ===1? trx.user.value : null,
      employee : trx.userType && trx.userType.value===2? trx.user.value : null,
      vendor : trx.userType && trx.userType.value===3? trx.user.value : null,
      notes: trx.notes  ?    trx.notes : null
    }))
    const payload = {
      transactions:trxs
    }
    const response = await handleSave(payload)
    if(response.data && response.data.message==="success"){
      reset()
    }
  }


  const reset=()=>{
    settransactions([])
  }

  const flowChange=(e)=>{
    setflow(e)
    getmodes(e.value)    
  }

  const modeChange=(e)=>{
    setmode(e)    
  }

  const userCountTypeChange=(e)=>{
    setuserCountType(e) 
    if(e.value===2){
      setuserType(null)
      setselectedUser(null)
    }
  }

  const userTypeChange=(e)=>{
    setuserType(e)   
    setusers([])
    setselectedUser(null) 
    if(e.value===1){
      setselectUserLabel("client...")
    }
    else if(e.value===2){
      setselectUserLabel("employee...")
    }
    else if(e.value===3){
      setselectUserLabel("vendor...")
    }
    getUsers(e.value)
  }

  const usersChange=(e)=>{
    setselectedUser(e)    
  }

  const addTransaction=(category)=>{
    let userSelected = null
    let userTypeSelected = null
    if(userCountType && userCountType.value===1){
      userSelected = selectedUser
      userTypeSelected = userType
    }

    const trx = {
      no: transactions.length+1,
      category:category,
      amount:0,
      userType:userType,
      user:userSelected
    }    
    settransactions([...transactions, trx])
  }

  const selectBill=(sBill)=>{
    const newSelectedBills = [...selectedBills,sBill]
    setselectedBills(newSelectedBills)    
  }

  const getAvailableBills=()=>{
    return bills.filter(b=> !selectedBills.map(nsb=>(nsb.id)).includes(b.id))
  }

  const removeTransaction=(trx)=>{    
    if(trx.bill){
      setselectedBills(selectedBills.filter(sb=>sb.id!==trx.bill.id))
    }    
    settransactions(transactions.filter(item=>(item.no!==trx.no)))    
  }

  
  if(transactionCategories){

    return (
      <>
        <DialogTitle id="form-dialog-title">
          <Grid container spacing={1} alignItems='center'>
              <Grid item xs={12} sm={12} md={1} lg={1}>
                Tambah 
              </Grid> 
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Select 
                    name="flow"
                    placeholder = "jenis.."
                    options={flows}
                    onChange={flowChange}
                    value={flow}
                    styles={selectCustomZindex}                                       
                  />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Select 
                    name="mode"
                    placeholder = "mode..."
                    options={modes}
                    onChange={modeChange}
                    value={mode}
                    styles={selectCustomZindex}                                       
                  />
              </Grid>
              <Grid item xs={1}>
                {flow && flow.value===1? "Dari":"Ke"}
              </Grid> 
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Select 
                    name="userCountType"
                    options={userCountTypes}
                    onChange={userCountTypeChange}
                    value={userCountType}
                    styles={selectCustomZindex}                                       
                  />
              </Grid>
              <Conditional condition={userCountType.value===1}>
                <Grid item xs={11} sm={11} md={2} lg={2}>
                  <Select 
                      name="userType"
                      placeholder = "jenis user..."
                      options={userTypes}
                      onChange={userTypeChange}
                      value={userType}
                      styles={selectCustomZindex}                                       
                    />
                </Grid> 
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <Select 
                      name="user"
                      placeholder = {selectUserLabel}
                      options={users}
                      onChange={usersChange}
                      value={selectedUser}
                      styles={selectCustomZindex}                                       
                    />
                </Grid>   
              </Conditional>                                    
          </Grid>           
        </DialogTitle>
        <DialogContent>
          <Grid container style={{marginBottom:8}}>
            <Grid item xs={12} sm={12} md={6} lg={6} container justify="flex-end">              
            </Grid>  
            <Grid item xs={12} sm={12} md={6} lg={6} container justify="flex-end">
              <Grid item>
                <Typography color="primary" component="h5" variant="h5">
                  Total Rp. {
                            numeral(transactions.reduce((a, b) => a + (Number(b["amount"]) || 0), 0)).format('0,0')
                            }
                </Typography>                
              </Grid>
            </Grid> 
          </Grid>
        <Grid container style={{marginBottom:8}}>
          <Grid item container xs={12} sm={12} md={9} lg={9} spacing={1} alignItems='center' >
          Kategori :             
          {
          flow && mode &&
           transactionCategories.filter(item=>
            (Number(item.flow.value)===flow.value && Number(item.mode.value)===mode.value)
            ).map(item=>
              (
            <Grid item>
              <Fab
                variant="extended"
                size="medium"
                color="primary"   
                onClick={()=>addTransaction(item)}             
              >                
                {item.name}
              </Fab>
            </Grid>
          ))
          }
          </Grid>                     
        </Grid>

        <Grid container spacing={1} alignItems='flex-start'>
          { transactions.map(item=>(
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TransactionCard 
              transaction={item} 
              transactionChange={transactionChange}
              removeTransaction={removeTransaction}
              bills={getAvailableBills()}
              selectBill={selectBill}
              userTypes = {userTypes}
              user={user}
              />
            </Grid>
          ))            
          }
        </Grid>  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Kembali
          </Button>
          <Button variant="contained" onClick={submit} color="primary">
            Simpan
          </Button>
        </DialogActions>
  </>
  
      );

  }else{
    return null
  }
  
}

export default FormAdd;


const useStyles = makeStyles((theme) => ({

}));

