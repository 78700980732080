import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Protected   from 'components/Protected.js';
import Typography from '@material-ui/core/Typography';
import {selectCustomZindex} from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { FormControlLabel, Switch, TextField } from '@material-ui/core';
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import { generalListOptionMapper, generalOptionMapper, userListOptionMapper } from 'utils/mappers';



const ClientProductForm =(props)=> {
  const {row, product, userActions=[], closeSubDialog, getProductOfClient} = props
  const setting = useSelector(state => state.setting);
  const user = useSelector(state => state.user);
  const classes = useStyles();
  const reset=()=>{    
    return{
      quantity:0
    }
    
  }
  const [state, setstate] = useState(reset())
  const [productOptions, setproductOptions] = useState([])
  const [pageAction, setpageAction] = useState('')


  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  
  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue
    if(fieldName==='quantity'){
      value = eventValue.target.value
    }
    newState[fieldName] = value
    setstate(newState)
  }

  const insert =async(payload)=>{    
    const response = await doPost(endpoint.client_product, payload,"add " ,getDefaultHeader())
    if(response && response.data){      
      getProductOfClient()
    }else{
      
    }    
  }

  const update =async(payload)=>{        
    const response = await doPut(endpoint.client_product, payload,"update product of client",getDefaultHeader())
    if(response && response.data){
      
      getProductOfClient()
    }else{
      
    }     
  }

  const submit = async()=>{
    const params = 
    {              
      client:row.id,
      product:state.product.id,
      count:Number(state.quantity)
    }

    if(pageAction==="CCLIE"){
      insert(params)
    }else{
      update(params)
    }
  }

  const getRowDetail =async()=>{
    let params = {}
    /*
    const response = await axios.get(endpoint.user_id_relation.replace("id",row.id), params)
    if(response){      
      setstate(response.data)
    }
    */
    setstate({
      ...row
    })
  }

  const getProductOptions =async()=>{
    let params = {      
    }
    
    const response = await doGet(endpoint.product_options, params, getDefaultHeader())
    if(response){
      setproductOptions(generalListOptionMapper(response.data))
    }
  }

  useEffect(() => {
    setpageAction(props.pageAction)     
  }, [props.pageAction])
  
  useEffect(() => {
    getProductOptions()
    if(product){
      setstate({
        ...state,
        product:product
      })      
    }        
  }, [product])

  useEffect(() => {
    if(row && product){
      getProductOptions()
      setstate({
        ...state,
        client:row,
        product: generalOptionMapper(product),
        quantity:product.pivot.quantity
      })      
    }
    
    
  }, [row, product])


  

  return (
    <>      
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} justify='space-between' className={classes.root}>
            <Grid container justify='center' alignItems='flex-start' className={classes.content}>              
                <Grid item xs={12}>                
                  <Select
                    isClearable={true}
                    placeholder = "select product..."
                    options={productOptions}
                    value={state.product}
                    onChange={(e)=>handleChange('product',e)}
                    styles={selectCustomZindex}
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    type="number"              
                    fullWidth
                    id="quantity"
                    label="Quantity"
                    name="quantity"
                    value={state.quantity}
                    onChange={(e)=>handleChange('quantity',e)}
                  />
                </Grid>               
              
            </Grid>          
          </Grid>
      </DialogContent>
      <DialogActions>
        <Conditional condition={pageAction==="READ"}>
        <Protected user={user}  userActions={userActions} allowedCodes={['ECLIE']} activeMenu={setting.active_menu}>
            <Button onClick={()=>setpageAction("ECLIE")} color="primary">
              Edit
            </Button>
          </Protected>
        </Conditional>
        <Button onClick={closeSubDialog} color="secondary">
          Kembali
        </Button>
        <Conditional condition={pageAction!=="READ"}>
          <Button variant="contained" onClick={submit} color="primary">
            Simpan
          </Button>
        </Conditional>
      </DialogActions>
</>

    );
}

export default ClientProductForm;

const useStyles = makeStyles((theme) => ({
  root:{
    padding:'16px 0'
  },
  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )'
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
