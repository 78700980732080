import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme.js'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import {doGet} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import {
  DateTimePicker,MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { format,parse } from 'date-fns'
import {generalOptionMapper} from 'utils/mappers.js'

const FormEdit =(props)=> {
  const {row, title, user,categoryOptions, handleClose, handleSave} = props
  const classes = useStyles();
  const [state, setstate] = useState(null)

  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  useEffect(() => {
    if(row){
      getBillingDetail(row.id)
    }
  }, [row])


  const getBillingDetail =async(id)=>{
    let params = {   }
    
    const response = await doGet(endpoint.billing+"/"+id, params, getDefaultHeader())
    if(response){
      const modified = {
        ...response.data, 
        issuedDate: response.data.issuedDate ? parse(response.data.issuedDate, "yyyy-MM-dd HH:mm:ss", new Date()): null,
        dueDate: parse(response.data.dueDate, "yyyy-MM-dd HH:mm:ss", new Date()),
        category:generalOptionMapper(response.data.category)
      }      
      setstate(modified)      
    }
  }

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue
    if(fieldName==="creator"){
      value = {username:eventValue}
    }
    newState[fieldName] = value
    setstate(newState)
  }

  const submit = async()=>{
    const payload = {
      ...state
    }
    const response = await handleSave(payload)
    handleClose()
  }


  if(state)
  return (
  <>
      <DialogTitle id="form-dialog-title">Edit {title}</DialogTitle>
      <DialogContent>
        <Grid container item xs={12} justify='center'>
        <Grid container style={{margin:'0 0 16px 0'}}>
            <Select
              name="category"
              placeholder = "transaction category..."
              options={categoryOptions}
              onChange={(e)=>handleChange("category",e)}
              value={state.category}
              styles={selectCustomZindex}
            />                
          </Grid>                                  
          
          <Grid container>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                ampm={false}
                value={state.dueDate}
                onChange={(e)=>handleChange("dueDate",e)}
                label="Due Date"
                variant="inline"
              />
            </MuiPickersUtilsProvider>
          </Grid>                                  
          
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>
</>
)
else{
  return null
}

}

export default FormEdit;

const useStyles = makeStyles((theme) => ({

}));
