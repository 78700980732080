/*
author alka@2019
*/
import { applyMiddleware,createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import appReducer from "./reducers";

const store = createStore(appReducer,
    composeWithDevTools());

export default store;
