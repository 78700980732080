import React,{useState, useEffect} from 'react';
import {endpoint} from 'utils/constants.js'
import {doGet} from 'utils/apiUtil.js'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Conditional from 'components/Conditional.js';
import NumberFormat from 'react-number-format';
import NumberFormatCustom2 from 'components/NumberFormatCustom2.js';
import TextField from "@material-ui/core/TextField";
import Select from 'react-select'
import {generalListOptionMapper,parameterListOptionMapper} from 'utils/mappers.js'
import {selectCustomZindex} from 'utils/theme.js'

const TransactionCard =(props)=> {
  const {user, transaction, transactionChange, userTypes, removeTransaction, bills, selectBill} = props
  const classes = useStyles();
  const [users, setusers] = useState([])
  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  const getUsers =async(type)=>{
    let params = {}
    
    let url = ""
    switch(type) {
      case 1:
        url = endpoint.client_options;
        break;
      case 2:
        url = endpoint.employee_options;
        break;
      case 3:
        url = endpoint.vendor_options;
        break;
      default:
        
    }

    const response = await doGet(url, params, getDefaultHeader())    
    if(response){      
        setusers(generalListOptionMapper(response.data))
    }
  }

  useEffect(() => {
    if(transaction.userType){
      getUsers(transaction.userType.value)
    }
  }, [transaction.userType])

  

  if(transaction){    
    return (
        <Card>
            <CardHeader
            title={transaction.category.name}
            action={
                <IconButton
                onClick={()=>removeTransaction(transaction)}
                aria-label="settings"
                className={classes.iconButton}
                >
                <Close />
                </IconButton>
            }
            style={{ color: "white", backgroundColor: "#15cd8f" }}
            />
            <CardContent>                
              
              <Grid container>                
              <Grid item xs={11} sm={11} md={6} lg={6}>
                  <Select 
                      name="userType"
                      placeholder = "jenis user..."
                      options={userTypes}
                      onChange={(e)=>transactionChange(transaction,"userType",e)}
                      value={transaction.userType}
                      styles={selectCustomZindex}                                       
                    />
                </Grid> 
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Select 
                      name="user"
                      placeholder = {transaction.userLabel}
                      options={users}
                      onChange={(e)=>transactionChange(transaction,"user",e)}
                      value={transaction.user}
                      styles={selectCustomZindex}                                       
                    />
                </Grid>  
                <Grid container alignContent="center" item xs={3}>
                    Pembayaran
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    margin="dense"
                    id="amount"                                                          
                    name="amount"
                    fullWidth
                    value={transaction.amount}
                    InputProps={{
                      inputComponent: NumberFormatCustom2,
                    }}
                    onChange={(e)=>transactionChange(transaction,"amount",e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline                    
                    margin="dense"
                    id="notes"                                                          
                    name="notes"
                    label="notes"
                    variant="outlined"
                    fullWidth
                    value={transaction.notes}
                    onChange={(e)=>transactionChange(transaction,"notes",e)}
                  />
                </Grid>
                
              </Grid>          
              
            </CardContent>
        </Card>
        );
  }else{
      return null
  }
  
}

export default TransactionCard;

const BillingList = (props)=>{
  const {bills, onSelectItem} = props
  const classes = useStyles();
  return(
        <Grid container>
          Tagihan belum dibayar :
          <List className={classes.billingList}>
            {bills.map(item => (
              <>                
                <ListItem button alignItems="center" onClick={()=>onSelectItem(item)}>
                  <ListItemText
                    primary={item.name}                    
                  />
                </ListItem>                              
              </>
            ))}
          </List>
        </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {

  },
  billingList:{
    width:'100%',
    border:'1px solid',
    borderRadius: theme.borderRadius,
    borderColor:theme.borderColor
  }
}));



  


            