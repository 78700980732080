import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {doGet, doPost, doDelete, doPut, doPatch} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import RoutesDetail from './RoutesDetail.js'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const FormEdit =(props)=> {
  const {row, userActions, getGatewayDetail, title, user, handleClose, generateKey, handleSave, setting, pageAction} = props
  const classes = useStyles();
  const [state, setstate] = useState({})

  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  useEffect(() => {
      if(row && user){
        setstate(row)
      }
  }, [row])

  useEffect(() => {

  }, [])

  
  const addRoute= async (payload)=>{
    
    const response = await doPost(endpoint.payment_gateway_route, payload,"insert route" ,getDefaultHeader())
    getGatewayDetail(state)
  }

  const removeRoute= async(route)=>{
    let payload = null
    
    const response = await doDelete(`${endpoint.payment_gateway_route}/${route.id}`, payload,"delete route" ,getDefaultHeader())
    getGatewayDetail(state)
  }

  const updateRoute= async(payload)=>{
    
    const response = await doPut(endpoint.payment_gateway_route, payload,"update route" ,getDefaultHeader())
    getGatewayDetail(state)
  }

  
  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    if(fieldName==="status"){
      value = eventValue.target.checked? 1:0
    }

    newState[fieldName] = value
    setstate(newState)

  }

  
  const submit = async()=>{
    const response = await handleSave(state)
    if(response.data.message==="success"){
      handleClose()
    }
  }


  return (
    <>
      <DialogTitle id="form-dialog-title">Edit</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} justify='space-between' className={classes.root}>
            <Grid container item xs={12} sm={12} md={4} lg={4} justify='flex-start' alignItems='flex-start' alignContent="flex-start" className={classes.left}>
              <Grid container alignContent="center" justify="center" className={classes.header}>
                <Typography variant="h6">Data {title}</Typography>
              </Grid>
              <Grid container justify='center' alignItems='flex-start' className={classes.content}>
              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="name"
                  label="name"
                  fullWidth
                  value={state.name}
                  onChange={(e)=>handleChange("name",e)}                  
                />
              </Grid>

              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="host"
                  label="host"
                  fullWidth
                  value={state.host}
                  onChange={(e)=>handleChange("host",e)}                  
                />
              </Grid>

              <Grid container alignItems='flex-start'>            
                <FormControlLabel
                  value="status"
                  control={
                      <Switch 
                      color="primary" 
                      checked={Number(state.status)===1} 
                      onChange={(e)=>handleChange("status",e)}
                      />
                    }
                  label="Status"
                  labelPlacement="start"
                  style={{marginLeft:0}}
                />
              </Grid>   

              </Grid>
      </Grid>

      <Grid container item xs={12} sm={12} md={7} lg={7} justify='center' alignItems="flex-start" alignContent="flex-start" className={classes.right}>
        <RoutesDetail 
          userActions={userActions}
          gateway={state} 
          user={user} 
          activeMenu={setting.active_menu} 
          pageAction={pageAction}
          addRoute={addRoute}
          removeRoute={removeRoute}
          updateRoute={updateRoute}
          /> 

      </Grid>

      </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>
</>

    );
}

export default FormEdit;

const useStyles = makeStyles((theme) => ({
  root:{
    padding:theme.spacing(1)
  }, 
  left:{
    marginBottom:theme.spacing(3)
  }, 
  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )',
    borderTop: '1px solid',
    borderColor:theme.borderColor
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
