import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Protected   from 'components/Protected.js';
import StatusChip from 'components/StatusChip';

const FormDetail =(props)=> {
  const {title, userActions, user, row, handleClose, changeAction, setting} = props
  const classes = useStyles();

  if(row)
    return (
    <>
      <DialogTitle id="form-dialog-title">Detail {title}</DialogTitle>
      <DialogContent>
        <Grid container item xs={12} justify='center'>
          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="id"
              label="ID"
              fullWidth
              value={row.id}
              readOnly
            />
          </Grid>
          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="clientId"
              label="clientId"
              fullWidth
              value={row.clientId}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="amount"
              label="amount"
              fullWidth
              value={row.amount}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="beneficiaryName"
              label="beneficiaryName"
              fullWidth
              value={row.beneficiaryName}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="beneficiaryAccount"
              label="beneficiaryAccount"
              fullWidth
              value={row.beneficiaryAccount}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="beneficiaryBank"
              label="beneficiaryBank"
              fullWidth
              value={row.beneficiaryBank}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="notes"
              label="notes"
              fullWidth
              value={row.notes}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="referenceNo"
              label="referenceNo"
              fullWidth
              value={row.referenceNo}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="createBy"
              label="createBy"
              fullWidth
              value={row.createBy}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="created_at"
              label="created_at"
              fullWidth
              value={row.created_at}
              readOnly
            />
          </Grid>

          <Grid container justify='flex-start' alignItems='center'>
            Status : <StatusChip status={Number(row.status.value)}/>
          </Grid>
        </Grid>

      </DialogContent>

      { user && setting &&
        <DialogActions>
          <Protected user={user} userActions={userActions} allowedCodes={['EPAYO']} activeMenu={setting.active_menu}>
            <Button onClick={()=>changeAction("edit")} color="primary">
              Edit
            </Button>
          </Protected>
          <Button variant="contained" onClick={handleClose} color="primary">
            Kembali
          </Button>
        </DialogActions>
      }

  </>

    )
    else{
      return null
    }
}

export default FormDetail;

const useStyles = makeStyles((theme) => ({

}));
