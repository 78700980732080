import React from 'react';
import NumberFormat from 'react-number-format';
function NumberFormatCustom2(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange(values.value);
        }}
        thousandSeparator
        isNumericString
        prefix="Rp."
      />
    );
  }

  export default NumberFormatCustom2