import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {doGet} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Protected   from 'components/Protected.js';
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme.js'
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from '@date-io/date-fns';
import {
  DateTimePicker,MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { format,parse } from 'date-fns'
import {generalOptionMapper} from 'utils/mappers.js'
import NumberFormatCustom2 from 'components/NumberFormatCustom2.js';

const FormDetail =(props)=> {
  const {title, user, userActions, row, handleClose, showEditForm, setting} = props
  const classes = useStyles();
  const [state, setstate] = useState(null)
  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  const getBillingDetail =async(id)=>{
    let params = {   }
    
    const response = await doGet(endpoint.billing+"/"+id, params, getDefaultHeader())
    if(response){
      const modified = {
        ...response.data, 
        issuedDate: response.data.issuedDate ? parse(response.data.issuedDate, "yyyy-MM-dd HH:mm:ss", new Date()): null,
        dueDate: parse(response.data.dueDate, "yyyy-MM-dd HH:mm:ss", new Date()),
        category:generalOptionMapper(response.data.category)
      }      

      //console.log(modified);
      setstate(modified)      
    }
  }

  useEffect(() => {
    if(row){
      getBillingDetail(row.id)
    }
  }, [row])

  const mapToOption = (data)=>{
    return {label:data.name, value:data.id}
  }

  if(state)
    return (
    <>
      <DialogTitle id="form-dialog-title">Detail {title}</DialogTitle>
      <DialogContent >
        <Grid container item xs={12} justify='center'>
          <Grid item container style={{margin:'0 0 16px 0'}}>
            <Select
              name="category"
              placeholder = "transaction category..."
              value={state.category}
              styles={selectCustomZindex}
            />                
          </Grid>
          <Grid item xs={12} container style={{margin:'0 0 16px 0'}}>
            <Select
                value = {mapToOption(state.client)}
                styles={selectCustomZindex}
              />  
          </Grid>
          
          <Grid container style={{margin:'0 0 16px 0'}} >
            <Select
                value = {mapToOption(state.product)}
                styles={selectCustomZindex}
              />            
          </Grid>

          <Grid item container style={{margin:'0 0 16px 0'}}>
            <Select
                value = {mapToOption(state.discount)}
                styles={selectCustomZindex}                
              />  
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>          
            <TextField
                type="number"              
                margin="dense"
                id="quantity"
                label="quantity"
                fullWidth
                value={state.quantity}  
                readOnly  
              />
          
            
          </Grid>
          <Grid container justify='center' alignItems='flex-start'>
            <TextField
                margin="dense"
                id="price"                                                          
                name="price"
                label="price"
                fullWidth
                value={state.price}
                InputProps={{
                  inputComponent: NumberFormatCustom2,
                }}
                readOnly
              />           
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>  
            <TextField
                margin="dense"
                id="discount"                                                          
                name="discount"
                label="total discount"
                fullWidth
                value={state.totalDiscount}
                InputProps={{
                  inputComponent: NumberFormatCustom2,
                }}
                readOnly
              />   
            </Grid>       

          <Grid container justify='center' alignItems='flex-start'>          
          <TextField
                margin="dense"
                id="total"                                                          
                name="total"
                label="total price"
                fullWidth                
                InputProps={{
                  inputComponent: NumberFormatCustom2,
                }}
                value={state.total}  
                readOnly
              />
            
          </Grid>

          <Grid container>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                ampm={false}
                value={state.dueDate}
                label="Due Date"
                variant="inline"
                readOnly
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid container>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                ampm={false}
                value={state.issuedDate}
                label="Issued Date"
                variant="inline"
                emptyLabel="not yet"
                readOnly
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <TextField
              margin="dense"
              id="creator"
              label="creator"
              fullWidth
              value={state.creator.username}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <TextField
              margin="dense"
              id="invoiceNumber"
              label="invoiceNumber"
              fullWidth
              value={state.invoiceNumber}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <TextField
              margin="dense"
              id="paymentstatus"
              label="paymentstatus"
              fullWidth
              value={state.paymentstatus.name}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <TextField
              margin="dense"
              id="issuedstatus"
              label="issuedstatus"
              fullWidth
              value={state.issuedstatus.name}
              readOnly
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <TextField
              margin="dense"
              id="status"
              label="status"
              fullWidth
              value={state.status.name}
              readOnly
            />
          </Grid>

        </Grid>
      </DialogContent>

      { user && setting &&
        <DialogActions>
          <Protected user={user} userActions={userActions} allowedCodes={['EBILL']} activeMenu={setting.active_menu}>
            <Button onClick={showEditForm} color="primary">
              Edit
            </Button>
          </Protected>
          <Button variant="contained" onClick={handleClose} color="primary">
            Kembali
          </Button>
        </DialogActions>
      }

  </>

    )
    else{
      return null
    }
}

export default FormDetail;

const useStyles = makeStyles((theme) => ({

}));
