import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

const Profile =(props)=> {
  const {user,logout} = props
  const classes = useStyles()

  return(
    <Grid container className={classes.profileContainer} >

      <Grid container justify="center" alignContent="center" style={{marginBottom:8}}>
        <Typography
          component="span"
          variant="body2"
          color="textPrimary"
        >
          {user.username}
        </Typography>
      </Grid>

      <Grid container justify="center" spacing={1} style={{marginBottom:8}}>
      {
        user&&user.roles&&
        user.roles.map(role=>(
          <Grid item>
            <Typography
              component="span"
              variant="body2"
              className={classes.role}
              color="textPrimary"
            >
              {role.name}
            </Typography>
          </Grid>
        ))
      }
      </Grid>

      <Grid container justify="center">
        <Button onClick={logout} color="secondary">logout</Button>
      </Grid>


    </Grid>
  )
}

export default Profile;




export const useStyles = makeStyles((theme) => ({
profileContainer:{
    maxWidth:200,
    padding:theme.spacing(1)
  },
  role:{
    padding:4,
    border:'1px solid',
    borderColor:theme.borderColor,
    margin:'0 2px 0 2px',
    borderRadius:4
  }

}));
