import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';


const Sidebar =(props)=> {
  const {user, itemClick, activeMenu, justify, onClose} = props
  const classes = useStyles();

  return (
      <Grid container justify={justify} alignItems='flex-start' alignContent="flex-start" className={classes.sideBarListContainer}>
        <Hidden lgUp>
          <Grid container justify="space-between" alignContent="center" alignItems="center" style={{padding:"0 8px 0 8px"}}>          
            <Typography variant="h6" color="primary">Ziad Core Menu</Typography>
            <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
              <CloseIcon color="primary"/>
            </IconButton>
          </Grid>
        </Hidden>
        <List className={classes.list}>
          {user && user.menus && activeMenu &&
            user.menus.map(menu => (
            <ListItem
              button
              onClick={()=>itemClick(menu)}
              key={menu.id}
              selected={activeMenu.code===menu.code}>
              <ListItemIcon> <Icon>{menu.icon}</Icon> </ListItemIcon>
              <ListItemText primary={menu.display} />
            </ListItem>
          ))}
        </List>
      </Grid>
    );
}

export default Sidebar;





export const useStyles = makeStyles((theme) => ({
  sideBar:{
    height:'100vh',
    borderRight:'1px #e1e2e4 solid'
  },
  list:{
    width:"100%"
  }

}));
