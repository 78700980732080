import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {doGet} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Conditional from 'components/Conditional.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme.js'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BankPrefixDetail from './BankPrefixDetail.js'


const FormAdd =(props)=> {
  const {title, user, userActions,  handleClose, clientOptions, bankOptions, vendorOptions, sellerOptions, handleSave, setting, pageAction} = props
  const classes = useStyles();
  const [state, setstate] = useState({
    status:1,
    client:null,
    employee:null,
    vendor:null,
    seller:null,
    mallID:0,
    sharedKey:"", 
    settlementId:"",
    prefixs: []
  })

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue
    
    if(fieldName==="status"){
      value = eventValue.target.checked? 1:0      
    }
    
    newState[fieldName] = value
    setstate(newState)
  }

  const submit = async()=>{
    let ownership='ZIAD'

    if(state.client){
      ownership='CLIENT'
    }
    else
    if(state.employee){
      ownership='CLIENT'
    }
    else
    if(state.vendor){
      ownership='VENDOR'
    }
    else
    if(state.seller){
      ownership='SELLER'
    }

    let payload = {
      accountNumber : state.accountNumber,
      accountName   : state.accountName,
      bank          : state.bank ? state.bank.id : null,
      client        : state.client ? state.client.id : null,
      employee      : state.employee ? state.employee.id : null,
      vendor        : state.vendor ? state.vendor.id : null,
      seller        : state.seller ? state.seller.id : null,
      ownership     : ownership,
      mallID        : state.mallID,
      sharedKey     : state.sharedKey,
      settlementId  : state.settlementId,
      status        : state.status
    }

    const response = await handleSave(payload)
    if(response.data.message==="success"){
      reset()
    }
  }

  const reset=()=>{
    setstate({})
  }
  const addBankPrefix=(prefix)=>{
    const newPrefix = [...state.prefixs, prefix]
    handleChange('prefixs',newPrefix)
  }

  const removeBankPrefix=(prefix)=>{    
    const newPrefix = state.prefixs.filter(p=>(p.id!==prefix.id))
    handleChange('prefixs',newPrefix)
  }

  const updateBankPrefix= (prefix)=>{
    let others = state.prefixs.filter(item=>(item.id!==prefix.id))
    const sorted = [...others, prefix].sort((a, b) => (a.id > b.id) ? 1 : -1)
    handleChange('prefixs',sorted)
  }  

  return (
    <>
      <DialogTitle id="form-dialog-title">Tambah</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} justify='space-between' className={classes.root}>
            <Grid container item xs={12} sm={12} md={4} lg={4} justify='flex-start' alignItems='flex-start' alignContent="flex-start" className={classes.left}>
              <Grid container alignContent="center" justify="center" className={classes.header}>
                <Typography variant="h6">Data {title}</Typography>
              </Grid>
              <Grid container justify='center' alignItems='flex-start' className={classes.content}>
                
                <Grid item container style={{margin:'0 0 16px 0'}}>
                  <Select
                      placeholder = "select bank..."
                      options={bankOptions}
                      onChange={(e)=>handleChange("bank",e)}
                      value = {state.bank}
                      styles={selectCustomZindex}
                      isClearable={true}
                    />  
                </Grid>
                
                <Grid item container style={{margin:'0 0 16px 0'}}>
                  <Select
                      placeholder = "select client..."
                      options={clientOptions}
                      onChange={(e)=>handleChange("client",e)}
                      value = {state.client}
                      styles={selectCustomZindex}
                      isClearable={true}
                    />  
                </Grid>

                

                <Grid item container style={{margin:'0 0 16px 0'}}>
                  <Select
                      placeholder = "select vendor..."
                      options={vendorOptions}
                      onChange={(e)=>handleChange("vendor",e)}
                      value = {state.vendor}
                      styles={selectCustomZindex}
                      isClearable={true}
                    />  
                </Grid>

                <Grid item container style={{margin:'0 0 16px 0'}}>
                  <Select
                      placeholder = "select seller..."
                      options={sellerOptions}
                      onChange={(e)=>handleChange("seller",e)}
                      value = {state.seller}
                      styles={selectCustomZindex}
                      isClearable={true}
                    />  
                </Grid>

                  <Grid container justify='center' alignItems='flex-start'>
                    <DebouncedTextField
                      margin="dense"
                      id="accountNumber"
                      label="Account Number"
                      fullWidth
                      value={state.accountNumber}
                      onChange={(e)=>handleChange("accountNumber",e)}
                    />
                  </Grid>

                  <Grid container justify='center' alignItems='flex-start'>
                    <DebouncedTextField
                      margin="dense"
                      id="accountName"
                      label="Account Name"
                      fullWidth
                      value={state.accountName}
                      onChange={(e)=>handleChange("accountName",e)}
                    />
                  </Grid>

                  <Grid container justify='center' alignItems='flex-start'>
                    <DebouncedTextField
                      margin="dense"
                      id="mallID"
                      label="mallID"
                      fullWidth
                      value={state.mallID}
                      onChange={(e)=>handleChange("mallID",e)}
                    />
                  </Grid>

                  <Grid container justify='center' alignItems='flex-start'>
                    <DebouncedTextField
                      margin="dense"
                      id="sharedKey"
                      label="sharedKey"
                      fullWidth
                      value={state.sharedKey}
                      onChange={(e)=>handleChange("sharedKey",e)}
                    />
                  </Grid>

                  <Grid container justify='center' alignItems='flex-start'>
                    <DebouncedTextField
                      margin="dense"
                      id="settlementId"
                      label="settlementId"
                      fullWidth
                      value={state.settlementId}
                      onChange={(e)=>handleChange("settlementId",e)}
                    />
                  </Grid>
                  

                  <Grid container alignItems='flex-start' style={{marginTop:16}}>
                    <FormControlLabel
                        value="status"
                        control={
                            <Switch 
                            color="primary" 
                            checked={Number(state.status)===1} 
                            onChange={(e)=>handleChange("status",e)}
                            />
                          }
                        label="Status"
                        labelPlacement="start"
                        style={{marginLeft:0}}
                      />
                  </Grid>
              
              </Grid>
            </Grid>

        <Grid container item xs={12} sm={12} md={7} lg={7} justify='center' alignItems="flex-start" alignContent="flex-start" className={classes.right}>
          
          <BankPrefixDetail
            userActions={userActions}
            row={state} 
            user={user} 
            activeMenu={setting.active_menu} 
            pageAction={pageAction}
            addBankPrefix={addBankPrefix}
            removeBankPrefix={removeBankPrefix}
            updateBankPrefix={updateBankPrefix}
          />
        </Grid>

      </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>
</>

    );
}

export default FormAdd;

const useStyles = makeStyles((theme) => ({
  root:{
    padding:theme.spacing(1)
  }, 
  left:{
    marginBottom:theme.spacing(3)
  },
  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )',
    borderTop: '1px solid',
    borderColor:theme.borderColor
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
