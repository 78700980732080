import React,{useState,useEffect} from 'react';
import {doGet,doPost} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Protected   from 'components/Protected.js';
import {generalOptionMapper} from 'utils/mappers.js'
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme.js'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BankPrefixDetail from './BankPrefixDetail.js'


const FormDetail =(props)=> {
  const {title, userActions, user, row, handleClose, changepageAction, setting, pageAction} = props
  const classes = useStyles();
  const [state, setstate] = useState({})
  

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  const getRowDetail =async(row)=>{
    let params = {
    }
    
    const response = await doGet(endpoint.bank_account_id_relation.replace("id",row.id), params, getDefaultHeader())
    if(response){
      let data = response.data
      setstate({
        id            : data.id,
        accountNumber : data.accountNumber,
        accountName   : data.accountName,
        bank          : generalOptionMapper(data.bank),
        client        : generalOptionMapper(data.client),
        employee      : generalOptionMapper(data.employee),
        vendor        : generalOptionMapper(data.vendor),
        seller        : generalOptionMapper(data.seller),
        prefixs       : data.prefixs,
        ownership     : data.ownership,
        mallID        : data.mallID,
        sharedKey     : data.sharedKey,
        settlementId  : data.settlementId,
        status        : data.status
      })
    }
  }
  
  const syncProduct =async()=>{    
    let payload = {
      clientId:row.id
    }
    
    const response = await doPost(endpoint.client_product_sync, payload,"sync product" ,getDefaultHeader())    
    
  }


  useEffect(() => {
    if(row){
      getRowDetail(row)
    }
}, [row])


  if(row)
    return (
    <>
      <DialogTitle id="form-dialog-title">Detail</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container justify='space-between' className={classes.root}>          
          <Grid container justify='flex-end' style={{marginBottom:8}}>
            <Grid item>
              <Protected user={user}  userActions={userActions} allowedCodes={['SYNCP']} activeMenu={setting.active_menu}>
                <Button variant="contained" onClick={syncProduct} color="primary">
                  Sync product
                </Button>
              </Protected>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={4} lg={4} justify='flex-start' alignItems='flex-start' alignContent="flex-start" className={classes.left}>
            <Grid container alignContent="center" className={classes.header}>
              <Typography variant="h6">Data {title}</Typography>
            </Grid>
            <Grid container justify='center' alignItems='flex-start' className={classes.content}>
              
              <Grid container justify='center' alignItems='flex-start' style={{margin:'0 0 16px 0'}}>
              <Select
                  placeholder = "select bank..."
                  value = {state.bank}
                  styles={selectCustomZindex}
                  readOnly
                /> 
              </Grid>
              
              <Grid container justify='center' alignItems='flex-start' style={{margin:'0 0 16px 0'}}>
                <Select
                  placeholder = "select client..."
                  value = {state.client}
                  styles={selectCustomZindex}
                  readOnly
                />
              </Grid>
              

              <Grid container justify='center' alignItems='flex-start' style={{margin:'0 0 16px 0'}}>
                <Select
                  placeholder = "select vendor..."
                  value = {state.vendor}
                  styles={selectCustomZindex}
                  readOnly
                />   
              </Grid>

              <Grid container justify='center' alignItems='flex-start' style={{margin:'0 0 16px 0'}}>
                <Select
                  placeholder = "select seller..."
                  value = {state.seller}
                  styles={selectCustomZindex}
                  readOnly
                />   
              </Grid>

              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="accountNumber"
                  label="Account Number"
                  fullWidth
                  value={state.accountNumber}
                  readOnly
                />
              </Grid>

              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="accountName"
                  label="Account Name"
                  fullWidth
                  value={state.accountName}
                  readOnly
                />
              </Grid>

              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="mallID"
                  label="mallID"
                  fullWidth
                  value={state.mallID}
                  readOnly
                />
              </Grid>

              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="sharedKey"
                  label="sharedKey"
                  fullWidth
                  value={state.sharedKey}
                  readOnly
                />
              </Grid>

              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="settlementId"
                  label="settlementId"
                  fullWidth
                  value={state.settlementId}
                  readOnly
                />
              </Grid>

              <Grid container alignItems='flex-start' style={{marginTop:16}}>
                <FormControlLabel
                    value="status"
                    control={
                        <Switch 
                        color="primary" 
                        checked={Number(state.status)===1}                   
                        />
                      }
                    label="Status"
                    labelPlacement="start"
                    style={{marginLeft:0}}
                  />
              </Grid>                 

                </Grid>
          </Grid>

          <Grid container item xs={12} sm={12} md={7} lg={7} justify='center' alignItems="flex-start" alignContent="flex-start" className={classes.right}>            
            
            <BankPrefixDetail 
            userActions={userActions}
            row={state} 
            user={user} 
            activeMenu={setting.active_menu} 
            pageAction={pageAction}
            /> 

          </Grid>
        </Grid>


      </DialogContent>

      { user && setting &&
        <DialogActions>
          <Protected user={user}  userActions={userActions} allowedCodes={['EACCOUNT']} activeMenu={setting.active_menu}>
            <Button onClick={()=>changepageAction("edit")} color="primary">
              Edit
            </Button>
          </Protected>
          <Button variant="contained" onClick={handleClose} color="primary">
            Kembali
          </Button>
        </DialogActions>
      }

  </>

    )
    else{
      return null
    }
}

export default FormDetail;

const useStyles = makeStyles((theme) => ({

  root:{
    padding:theme.spacing(1)
  }, 
  left:{
    marginBottom:theme.spacing(3)
  },  
  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )',
    borderTop: '1px solid',
    borderColor:theme.borderColor
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{        
    color:theme.palette.primary.main,
    marginBottom: theme.spacing(1)
  },
  
}));
