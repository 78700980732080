
export const endpoint = {
  user:"user",
  user_id_relation:"user/id/relation",
  user_role:"user/role",
  user_options:"user/options",
  menu:"menu",
  menu_options:"menu/options",
  menu_id_action:"menu/id/action",
  menu_relation:"menu/relation",
  parameter:"param",
  parameter_group:"param/group",
  parameter_group_key:"param/group/key",
  role:"role",
  role_action:"role/action",
  role_menu:"role/menu",
  role_options:"role/options",
  role_id_relation:"role/id/relation",
  product:"product",
  product_options:"product/options",
  product_module:"product/module",
  product_id_relation:"product/id/relation",
  action:"action",
  action_menu_role:"action/menu/role",
  module:"module",
  module_id_relation:"module/id/relation",
  module_menu:"module/menu",
  billing:"billing",
  billing_charge:"billing/charge",
  billing_paid:"billing/paid",
  parent_bill_charge:"parent/bill/charge",
  transaction:"transaction",
  transaction_category:"transaction/category",
  transaction_category_core:"transaction/category/core",
  transaction_category_options:"transaction/category/options",  
  client:"client",
  client_id_relation:"client/id/relation",
  client_options:"client/options",
  client_options_field:"client/options/field",
  client_user:"client/user",
  client_product:"client/product",
  client_route:"client/route",
  client_key:"client/key",
  client_connect:"client/connection",
  client_product_sync:"client/product/sync",
  client_account_sync:"client/account/sync",
  client_bill_charge:"client/bill/charge",
  client_bank_account:"client/bank/account",
  client_payment_channel:"client/payment-channel",
  vendor_options:"vendor/options",
  employee:"employee",
  employee_options:"employee/options",
  pays:"pays",
  pays_forward:"pays/forward",
  wallet:"wallet",
  wallet_options:"wallet/options",
  wallet_balance_client: "wallet/balance/client/id",
  discount:"discount",
  discount_options:"discount/options",
  bank:"bank",
  bank_options:"bank/options",
  bank_account:"bank/account",
  bank_account_id_relation:"bank/account/id/relation",
  bank_prefix:"bank/account/prefix",
  vendor:"vendor",
  vendor_options:"vendor/options",
  payment_gateway:"payment/gateway",
  payment_gateway_id_relation:"payment/gateway/id/relation",
  payment_gateway_options:"payment/gateway/options",
  payment_gateway_route:"payment/gateway/route",
  payment_channel:"payment/channel",
  payment_channel_update:"payment/channel/update",
  payment_channel_options:"payment/channel/options",
  payment_channel_gateway:"payment/channel/id/gateway",
  seller:"seller",
  seller_options:"seller/options",
}
export const answerTypes = [
  {
    "value_code": "D",
    "value_name": "Date",
    "value": "D",
    "label": "Date"
  },
  {
    "value_code": "M",
    "value_name": "Multiple Choice",
    "value": "M",
    "label": "Multiple Choice"
  },
  {
    "value_code": "N",
    "value_name": "Number",
    "value": "N",
    "label": "Number"
  },
  {
    "value_code": "S",
    "value_name": "Single Choice",
    "value": "S",
    "label": "Single Choice"
  },
  {
    "value_code": "T",
    "value_name": "Text",
    "value": "T",
    "label": "Text"
  },
  {
    "value_code": "V",
    "value_name": "Need Sub Question",
    "value": "V",
    "label": "Need Sub Question"
  },
  {
    "value_code": "Y",
    "value_name": "Yes or No",
    "value": "Y",
    "label": "Yes or No"
  }
];
