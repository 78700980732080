import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {doPost} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import SubDetail from './SubDetail.js'
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme.js'


const FormAdd =(props)=> {
  const {title, userActions, user, handleClose, handleSave, pageAction, typeOptions} = props
  const classes = useStyles();
  const [state, setstate] = useState({id:1, roles:[]})

  const toggleRole = (userId, roleId, toggle)=>{
    if(toggle===1){
      setstate({...state, roles:[...state.roles, {id:roleId}]})
    }else{
      setstate({...state, roles:state.roles.filter(item=>(item.id!==roleId))})
    }
  }

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    newState[fieldName] = value
    setstate(newState)
  }

  const submit = async()=>{
    const modifiedData = {
      ...state,
      roles:state.roles.map(r=>(r.id)),
      type:state.type ? state.type.value : null
    }

    const response = await handleSave(modifiedData)
    if(response.data.message==="success"){
      reset()
    }
  }


  const reset=()=>{
    setstate({id:1, roles:[]})
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">Tambah</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} justify='space-between' className={classes.root}>
            <Grid container item xs={12} sm={12} md={4} lg={4} justify='flex-start' alignItems='flex-start' alignContent="flex-start" className={classes.left}>
              <Grid container alignContent="center" justify="center" className={classes.header}>
                <Typography variant="h6">Data {title}</Typography>
              </Grid>
              <Grid container justify='center' alignItems='flex-start' className={classes.content}>
                
                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="username"
                    label="Username"
                    fullWidth
                    value={state.username}
                    onChange={(e)=>handleChange("username",e)}
                  />
                </Grid>

              <Grid container justify='center' alignItems='flex-start' style={{margin:'8px 0 8px 0'}}>                 
                <Select
                  name="type"
                  placeholder = "user type"
                  options={typeOptions}
                  onChange={(e)=>handleChange("type",e)}
                  value={state.type}
                  styles={selectCustomZindex}
                /> 
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={12} md={7} lg={7} justify='center' alignItems="flex-start" alignContent="flex-start" className={classes.right}>
              <SubDetail
                row={state}
                pageAction={pageAction}
                user={user}
                toggleRole={toggleRole}/>
          </Grid>
      </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>
</>

    );
}

export default FormAdd;

const useStyles = makeStyles((theme) => ({
  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )',
    borderTop: '1px solid',
    borderColor:theme.borderColor
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
