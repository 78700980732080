import React,{ useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Container, Tab, Box, Tabs, Typography, Grid } from '@material-ui/core';

import ClientDetail from './ClientDetail';
import ClientProduct from './ClientProduct';
import ClientRoute from './ClientRoute';
import ClientPaymentChannel from './ClientPaymentChannel';
import ClientBankAccount from './ClientBankAccount';

// ----------------------------------------------------------------------

export default function ClientForm(props) {
  const {row, pageAction} = props
  const setting = useSelector(state => state.setting);
  const [currentTab, setCurrentTab] = useState('detail');

  let TABS = [
    {
      value: 'detail',
      component: <ClientDetail {...props} />
    }    
  ];


  if(row && pageAction!=="CCLIE"){
    TABS = [
      ...TABS,
      {
        value: 'product',
        component: <ClientProduct {...props} />
      },
      {
        value: 'route',
        component: <ClientRoute {...props} />
      },
      {
        value: 'payment channel',
        component: <ClientPaymentChannel {...props} />
      },
      {
        value: 'bank account',
        component: <ClientBankAccount {...props} />
      }
    ]
  }

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Container>
      <Grid container item alignContent='center' xs={12} sm={12} md={6} lg={6} style={{padding:8}}>
        <Typography color='primary' component='h5' variant='h5'>
          {
          //setting&&setting.active_menu
          }
        </Typography>
      </Grid>
      <Box
        sx={{
          paddingBottom: 2
        }}
      >
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          {TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.value}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Box>
    </Container>
  );
}
