import React,{useEffect, useState} from 'react';
import { Redirect,useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import {dummyWidgets} from './sample.js'
import WidgetComponent   from 'components/WidgetComponent';

const Dashboard =(props)=> {

    const classes = useStyles();
  const user = useSelector(state => state.user);
  const [widgets, setwidgets] = useState([])

  const getWidgets =()=>{
    setwidgets(dummyWidgets)
  }

  useEffect(() => {
    if(user){
      getWidgets()
    }
  }, [user])

  if(user && user.token){
    return (
      <React.Fragment>        
        <Grid container spacing={1} justify='center' alignContent="flex-start" alignItems='flex-start' className={classes.root}>                      
            {
              widgets.map(widget=>(
                <WidgetComponent user={user} widget={widget}/>
              ))
            }            
        </Grid>
      </React.Fragment>
    );
  }
  else{
    return <Redirect to={{ pathname: '/login' }} />
  }

}

export default Dashboard;


export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));
