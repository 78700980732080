import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Protected   from 'components/Protected.js';
import Typography from '@material-ui/core/Typography';
import {selectCustomZindex} from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { FormControlLabel, Switch, TextField } from '@material-ui/core';
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import { generalListOptionMapper, generalOptionMapper, userListOptionMapper } from 'utils/mappers';



const ClientPaymentChannelForm =(props)=> {
  const {row, paymentChannel, userActions=[], closeSubDialog, getPaymentChannelOfClient} = props
  const setting = useSelector(state => state.setting);
  const user = useSelector(state => state.user);
  const classes = useStyles();
  const reset=()=>{    
    return{
      paymentChannel:null
    }
    
  }
  const [state, setstate] = useState(reset())
  const [paymentChannelOptions, setpaymentChannelOptions] = useState([])
  const [pageAction, setpageAction] = useState('')


  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  
  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue
    newState[fieldName] = value
    setstate(newState)
  }

  const insert =async(payload)=>{    
    const response = await doPost(endpoint.client_payment_channel, payload,"add " ,getDefaultHeader())
    if(response && response.data){      
      getPaymentChannelOfClient()
    }else{
      
    }    
  }


  const submit = async()=>{
    const params = 
    {              
      client_id:row.id,
      channel_id:state.paymentChannel.id
    }
    insert(params)
  }

  const getPaymentChannelOptions =async()=>{
    let params = {      
    }
    
    const response = await doGet(endpoint.payment_channel_options, params, getDefaultHeader())
    if(response){
      setpaymentChannelOptions(generalListOptionMapper(response.data))
    }
  }

  useEffect(() => {
    setpageAction(props.pageAction)     
  }, [props.pageAction])
  
  useEffect(() => {
    getPaymentChannelOptions()
    if(paymentChannel){
      setstate({
        ...state,
        paymentChannel:paymentChannel
      })      
    }        
  }, [paymentChannel])

  useEffect(() => {
    if(row && paymentChannel){
      getPaymentChannelOptions()
      setstate({
        ...state,
        client:row,
        paymentChannel: generalOptionMapper(paymentChannel)
      })      
    }
    
    
  }, [row, paymentChannel])


  

  return (
    <>      
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} justify='space-between' className={classes.root}>
            <Grid container justify='center' alignItems='flex-start' className={classes.content}>              
                <Grid item xs={12}>                
                  <Select
                    isClearable={true}
                    placeholder = "select paymentChannel..."
                    options={paymentChannelOptions}
                    value={state.paymentChannel}
                    onChange={(e)=>handleChange('paymentChannel',e)}
                    styles={selectCustomZindex}
                  />
                </Grid>                             
              
            </Grid>          
          </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeSubDialog} color="secondary">
          Kembali
        </Button>
        <Conditional condition={pageAction!=="READ"}>
          <Button variant="contained" onClick={submit} color="primary">
            Simpan
          </Button>
        </Conditional>
      </DialogActions>
</>

    );
}

export default ClientPaymentChannelForm;

const useStyles = makeStyles((theme) => ({
  root:{
    padding:'16px 0'
  },
  dialogContent:{    
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )'
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
    height:400
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
