import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';


const SubDetail =(props)=> {
  const {user, userActions, row, toggleMenu, toggleAction, pageAction} = props
  const classes = useStyles();
  const [allMenus, setallMenus] = useState([])
  const [selectedMenu, setselectedMenu] = useState({})

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  useEffect(() => {
      if(user){
          getMenuComplete()
      }
  }, [user])

  const getMenuComplete =async()=>{
    let params = {}
    
    const response = await doGet(endpoint.menu_relation, params, getDefaultHeader())
    if(response){
      //const modifiedData = response.data.data.map(row=>({...row, selected:false}))
      //setDataTable(modifiedData)
      setallMenus(response.data)
    }
  }

  const menuClick =(menu)=>{
    setselectedMenu(menu)
  }

  const onToggleAction =(action, event)=>{
    if(["add","edit"].includes(pageAction)){
      const toggle = event.target.checked?1:0
      toggleAction(row.id, action.id, toggle)
    }
  }

  const onToggleMenu =(menu,event)=>{
    if(["add","edit"].includes(pageAction)){
      const toggle = event.target.checked?1:0
      toggleMenu(row.id, menu.id, toggle)
    }
  }

  const getMenuIdsFromRole=(role)=>{
    return role.menus.map(m=>(m.id))
  }

  const getActionIdsFromRole=(role)=>{
    return role.actions.map(a=>(a.id))
  }

  return (
      <>
        <Grid container justify='center' spacing={1} alignItems='flex-start' className={classes.root}>
          <Grid container item xs={6} justify='center' alignItems='flex-start' className={classes.left}>
            <Grid container alignContent="center" justify="center" className={classes.header}>
              <Typography variant="h6">Menu</Typography>
            </Grid>
            <List component="nav" className={classes.content}>
              {
                row && allMenus && allMenus.map(item=>(
                  <ListItem button selected={item.id === selectedMenu.id} onClick={() => menuClick(item)}>
                    <ListItemText primary={item.display} />
                    <ListItemSecondaryAction>
                      <Switch
                      color="primary"
                      size="small"
                      checked={getMenuIdsFromRole(row).includes(item.id)}
                      onChange={(e)=>onToggleMenu(item,e)}/>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))

              }

            </List>
          </Grid>

          <Grid container item xs={6} justify='center' alignItems='flex-start' className={classes.right}>
          <Grid container alignContent="center" justify="center" className={classes.header}>
            <Typography variant="h6">Action</Typography>
          </Grid>
          <List component="nav" aria-label="secondary mailbox folders" className={classes.content}>
            {
              row && selectedMenu.id && allMenus && allMenus.filter(m=>(m.id===selectedMenu.id))[0].actions.map(item=>(
                <ListItem button>
                  <ListItemText primary={item.name} />
                  <ListItemSecondaryAction>
                    <Switch
                    color="primary"
                    size="small"
                    checked={getActionIdsFromRole(row).includes(item.id)}
                    onChange={(e)=>onToggleAction(item,e)}/>
                  </ListItemSecondaryAction>
                </ListItem>
              ))

            }

          </List>
          </Grid>
        </Grid>
      </>
    );
}

export default SubDetail;

const useStyles = makeStyles((theme) => ({
  root: {

  },
  left:{

    //borderRight: `1px solid ${theme.borderColor}`
  },
  right:{

  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: 4,
    width:'inherit',
    minHeight:300
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },

}));
