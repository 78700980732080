import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { format } from 'date-fns'


const FormAdd =(props)=> {
  const {title, user, handleClose, handleSave} = props
  const classes = useStyles();
  const [state, setstate] = useState({
    joinDate:new Date()
  })

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    newState[fieldName] = value
    setstate(newState)
  }

  const submit = async()=>{
    const payload = {
      ...state,
      joinDate:format(state.joinDate, 'yyyy-MM-dd')
    }
    const response = await handleSave(payload)
    if(response.data.message==="success"){
      reset()
    }
  }


  const reset=()=>{
    setstate({
      joinDate:new Date()
    })
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">Tambah {title}</DialogTitle>
      <DialogContent>
      <Grid container item xs={12} justify='center'>
        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="user"
            label="user"
            fullWidth
            value={state.user}
            onChange={(e)=>handleChange("user",e)}
          />
        </Grid>
        
        <Grid container justify='center' alignItems='flex-start' >
          <DebouncedTextField
            margin="dense"
            id="Name"
            label="Name"
            fullWidth
            value={state.name}
            onChange={(e)=>handleChange("name",e)}
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="empNumber"
            label="Number"
            fullWidth
            value={state.empNumber}
            onChange={(e)=>handleChange("empNumber",e)}
          />
        </Grid>        

        <Grid container>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={state.joinDate}
                onChange={(e)=>handleChange("joinDate",e)}
                label="Join Date"
                variant="inline"
              />
            </MuiPickersUtilsProvider>
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="status"
            label="status"
            fullWidth
            value={state.status}
            onChange={(e)=>handleChange("status",e)}
          />
        </Grid>    
            
      </Grid>
        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>
</>

    );
}

export default FormAdd;

const useStyles = makeStyles((theme) => ({

}));
