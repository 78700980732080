import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Protected   from 'components/Protected.js';
import Typography from '@material-ui/core/Typography';
import {selectCustomZindex} from 'utils/theme.js'
import Conditional from 'components/Conditional.js';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { FormControlLabel, Switch, TextField } from '@material-ui/core';
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import { generalListOptionMapper, generalOptionMapper, userListOptionMapper } from 'utils/mappers';



const ClientRouteForm =(props)=> {
  const {row, route, userActions=[], closeSubDialog, getRouteOfClient} = props
  const setting = useSelector(state => state.setting);
  const user = useSelector(state => state.user);
  const classes = useStyles();
  const reset=()=>{    
    return{
      name:'',
      path:''
    }
    
  }
  const [state, setstate] = useState(reset())
  const [pageAction, setpageAction] = useState('')


  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  
  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue
    if(fieldName==='quantity'){
      value = eventValue.target.value
    }
    newState[fieldName] = value
    setstate(newState)
  }

  const insert =async(payload)=>{    
    const response = await doPost(endpoint.client_route, payload,"add " ,getDefaultHeader())
    if(response && response.data){      
      getRouteOfClient()
    }else{
      
    }    
  }

  const update =async(payload)=>{        
    const response = await doPut(endpoint.client_route, payload,"update route of client",getDefaultHeader())
    if(response && response.data){
      
      getRouteOfClient()
    }else{
      
    }     
  }

  const submit = async()=>{
    const params = 
    {              
      client:row.id,      
      id: state.id,
      name: state.name,
      path: state.path
    }

    if(pageAction==="AROUTE"){
      insert(params)
    }else{
      update(params)
    }
  }


  useEffect(() => {
    setpageAction(props.pageAction)    
    
  }, [props.pageAction])
  

  useEffect(() => {
    if(row && route){
      setstate({
        ...state,
        client:row,
        id:route.id,
        name: route.name,
        path:route.path
      })      
    }
    
    
  }, [row, route])
  

  return (
    <>      
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} justify='space-between' className={classes.root}>
            <Grid container justify='center' alignItems='flex-start' className={classes.content}>              
              <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="name"
                    label="name"
                    fullWidth
                    value={state.name}
                    onChange={(e)=>handleChange("name",e)}                    
                  />
                </Grid>
                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="path"
                    label="path"
                    fullWidth
                    value={state.path}
                    onChange={(e)=>handleChange("path",e)}                    
                  />
                </Grid>               
              
            </Grid>          
          </Grid>
      </DialogContent>
      <DialogActions>
        <Conditional condition={pageAction==="READ"}>
        <Protected user={user}  userActions={userActions} allowedCodes={['ECLIE']} activeMenu={setting.active_menu}>
            <Button onClick={()=>setpageAction("ECLIE")} color="primary">
              Edit
            </Button>
          </Protected>
        </Conditional>
        <Button onClick={closeSubDialog} color="secondary">
          Kembali
        </Button>
        <Conditional condition={pageAction!=="READ"}>
          <Button variant="contained" onClick={submit} color="primary">
            Simpan
          </Button>
        </Conditional>
      </DialogActions>
</>

    );
}

export default ClientRouteForm;

const useStyles = makeStyles((theme) => ({
  root:{
    padding:'16px 0'
  },
  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )'
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
