import React from "react";

const Protected = (props) => {
  const { user, children, allowedCodes, userActions, ownerOnly, ownerId } = props
  let granted = false
  
  
	if(user && ownerOnly){
		if(user.id===ownerId){
			granted = true
		}
	}	  

	if (allowedCodes) {
		userActions.map(action=>(action.code)).forEach(cur => {
		if (allowedCodes.includes(cur)) {
			granted = true;
		}
		});
	}

  if (granted === true) {
  	return <>{children}</>
  }
  else{
  	return null
  }
};

export default Protected;
