import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {doGet, doPost, doDelete, doPut,doPatch} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import SubDetail from './SubDetail.js'

const FormEdit =(props)=> {
  const {title, userActions, user, row, handleClose, changepageAction, pageAction, setting, handleSave} = props
  const classes = useStyles();
  const [state, setstate] = useState(null)

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  useEffect(() => {
      if(row){
      	getRowComplete()
      }
  }, [row])

  const getRowComplete =async()=>{
    let params = {}
    
    const response = await doGet(endpoint.product_id_relation.replace("id",row.id), params, getDefaultHeader())
    if(response){
      //const modifiedData = response.data.data.map(row=>({...row, selected:false}))
      //setDataTable(modifiedData)
      setstate(response.data)
    }
  }

  const toggleModule= async(productId, moduleId, toggle)=>{
    const payload = {
      productId:productId,
      moduleId:moduleId,
      toggle:toggle
    }
    
    const response = await doPatch(endpoint.product_module, payload,"toggle module" ,getDefaultHeader())
    getRowComplete()
  }

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    newState[fieldName] = value
    setstate(newState)
  }

  const submit = async()=>{
    const response = await handleSave(state)
    handleClose()
  }

  if(state)
    return (
    <>
      <DialogTitle id="form-dialog-title">Edit</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} justify='space-between' className={classes.root}>
            <Grid container item xs={12} sm={12} md={4} lg={4} justify='flex-start' alignItems='flex-start' alignContent="flex-start" className={classes.left}>
              <Grid container alignContent="center" justify="center" className={classes.header}>
                <Typography variant="h6">Data {title}</Typography>
              </Grid>
              <Grid container justify='center' alignItems='flex-start' className={classes.content}>
                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="Id"
                    label="Id"
                    fullWidth
                    value={state.id}
                    readOnly
                  />
                </Grid>
                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="Name"
                    label="Name"
                    fullWidth
                    value={state.name}
                    onChange={(e)=>handleChange("name",e)}
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="Code"
                    label="Code"
                    fullWidth
                    value={state.code}
                    onChange={(e)=>handleChange("code",e)}
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="subscription"
                    label="subscription"
                    fullWidth
                    value={state.subscription}
                    onChange={(e)=>handleChange("subscription",e)}
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="category"
                    label="Category"
                    fullWidth
                    value={state.category}
                    onChange={(e)=>handleChange("category",e)}
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="price"
                    label="Price"
                    fullWidth
                    value={state.price}
                    onChange={(e)=>handleChange("price",e)}
                  />
                </Grid>
              </Grid>
            </Grid>

      <Grid container item xs={12} sm={12} md={7} lg={7} justify='flex-end' alignItems="flex-start" alignContent="flex-start" className={classes.right}>
          <SubDetail
          userActions={userActions}
          row={state}
          user={user}
          pageAction={pageAction}
          toggleModule={toggleModule}/>
      </Grid>
    </Grid>
  </DialogContent>


      { user && setting &&
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Kembali
          </Button>
          <Button variant="contained" onClick={submit} color="primary">
            Simpan
          </Button>
        </DialogActions>
      }

  </>

    )
    else{
      return null
    }
}

export default FormEdit;

const useStyles = makeStyles((theme) => ({
  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )',
    borderTop: '1px solid',
    borderColor:theme.borderColor
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
