import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Conditional from 'components/Conditional.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ActionDetail from './ActionDetail.js'



const FormAdd =(props)=> {
  const {title, userActions, user, setting, handleClose, handleSave, pageAction} = props
  const classes = useStyles();
  const [state, setstate] = useState({status:1})
  const [actions, setactions] = useState([])

  const addAction=(action)=>{
    setactions([...actions, action])
  }

  const removeAction=(action)=>{
    setactions(actions.filter(a=>a.id!==action.id))
  }

  const updateAction= (action)=>{
    let others = actions.filter(item=>(item.id!==action.id))
    const sorted = [...others, action].sort((a, b) => (a.id > b.id) ? 1 : -1)
    setactions(sorted)
  }

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue
    if(fieldName==="status"){
      value = eventValue.target.checked? 1:0
    }
    newState[fieldName] = value
    setstate(newState)
  }

  const submit = async()=>{
    let actionsPayload = actions.map(a=>{
      delete a.id
      return a
    })
    const payload = {...state, actions:actionsPayload}
    const response = await handleSave(payload)
    if(response.data.message==="success"){
      handleClose()
    }
  }


  const reset=()=>{
    setstate({})
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">Tambah</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} justify='space-between' className={classes.root}>
            <Grid container item xs={12} sm={12} md={4} lg={4} justify='flex-start' alignItems='flex-start' alignContent="flex-start" className={classes.left}>
              <Grid container alignContent="center" justify="center" className={classes.header}>
                <Typography variant="h6">Data {title}</Typography>
              </Grid>
              <Grid container justify='center' alignItems='flex-start' className={classes.content}>
              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="parentId"
                  label="parentId"
                  fullWidth
                  value={state.parentId}
                  onChange={(e)=>handleChange("parentId",e)}
                />
              </Grid>

              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="categoryId"
                  label="categoryId"
                  fullWidth
                  value={state.categoryId}
                  onChange={(e)=>handleChange("categoryId",e)}
                />
              </Grid>

              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="code"
                  label="code"
                  fullWidth
                  value={state.code}
                  onChange={(e)=>handleChange("code",e)}
                />
              </Grid>

              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="display"
                  label="display"
                  fullWidth
                  value={state.display}
                  onChange={(e)=>handleChange("display",e)}
                />
              </Grid>

              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="webPath"
                  label="webPath"
                  fullWidth
                  value={state.webPath}
                  onChange={(e)=>handleChange("webPath",e)}
                />
              </Grid>

              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="androidActivity"
                  label="androidActivity"
                  fullWidth
                  value={state.androidActivity}
                  onChange={(e)=>handleChange("androidActivity",e)}
                />
              </Grid>

              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="iosClass"
                  label="iosClass"
                  fullWidth
                  value={state.iosClass}
                  onChange={(e)=>handleChange("iosClass",e)}
                />
              </Grid>

              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="icon"
                  label="icon"
                  fullWidth
                  value={state.icon}
                  onChange={(e)=>handleChange("icon",e)}
                />
              </Grid>

              <Grid container alignItems='flex-start' style={{marginTop:16}}>
                <FormControlLabel
                    value="status"
                    control={
                        <Switch 
                        color="primary" 
                        checked={Number(state.status)===1} 
                        onChange={(e)=>handleChange("status",e)}
                        />
                      }
                    label="Status"
                    labelPlacement="start"
                    style={{marginLeft:0}}
                  />
              </Grid>
            </Grid>
        </Grid>

        <Grid container item xs={12} sm={12} md={7} lg={7} justify='center' alignItems="flex-start" alignContent="flex-start" className={classes.right}>
          <ActionDetail
            userActions={userActions}
            pageAction={pageAction}
            user={user}
            activeMenu={setting.active_menu}
            actions={actions}
            addAction={addAction}
            updateAction={updateAction}
            removeAction={removeAction}/>
        </Grid>

      </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>
</>

    );
}

export default FormAdd;

const useStyles = makeStyles((theme) => ({
  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )',
    borderTop: '1px solid',
    borderColor:theme.borderColor
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
