import React,{useState, useEffect} from 'react';
import { Redirect,useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { useDebounce } from 'react-use';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Cancel from '@material-ui/icons/Cancel';
import Send from '@material-ui/icons/Send';
import FilterList from 'components/FilterList';
import FormAdd   from './FormAdd.js';
import FormDetail   from './FormDetail.js';
import FormEdit   from './FormEdit.js';
import DataTable   from './DataTable.js';
import BankForm   from './BankForm.js';
import FilterComponent   from 'components/FilterComponent.js';
import DeleteConfirmation   from 'components/DeleteConfirmation.js';
import Protected   from 'components/Protected.js';
import Popover from '@material-ui/core/Popover';
import Select from 'react-select'
import Dialog from '@material-ui/core/Dialog';
import { generalListOptionMapper } from 'utils/mappers.js';



const BankAccount =(props)=> {
  const [userActions, setuserActions] = useState([])
  let { path, url } = useRouteMatch();
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const setting = useSelector(state => state.setting);
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [filters, setFilters] = useState({keyword:""})
  const [filtersAvailable, setfiltersAvailable] = useState([
    {name:'status', display:'status', type:"select"}
  ])
  const [filterBy, setfilterBy] = useState([])
  const [statusOptions, setstatusOptions] = useState([{label:"aktif", value:1}, {label:"nonaktif", value:0}])
  const [gatewayOptions, setgatewayOptions] = useState([])
  const [pageAction, setpageAction] = useState("")
  const [openAddForm, setOpenAddForm] = useState(false);
  const [openForm, setopenForm] = useState(false);
  const [isDialogFull, setisDialogFull] = useState(false);
  const [openDetailForm, setopenDetailForm] = useState(false);
  const [openDeleteConfirmation, setopenDeleteConfirmation] = useState(false);
  const [anchorFilter, setanchorFilter] = useState(null);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  const [clientOptions, setclientOptions] = useState([])
  const [vendorOptions, setvendorOptions] = useState([])
  const [sellerOptions, setsellerOptions] = useState([])
  const [bankOptions, setbankOptions] = useState([])

  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  const getUserActions =async(menu, roles)=>{   
    let params={
      menu:menu,
      roles:roles
    } 
    const response = await doGet(endpoint.action_menu_role, params, getDefaultHeader())
    if(response && response.data){
      setuserActions(response.data)
    }
  }

  
  useEffect(() => {      
    getGatewayOptions()   
    getClientOptions()  
    getBankOptions() 
    getvendorOptions()
    getsellerOptions()
  }, [])


  const getGatewayOptions =async()=>{
       
    const response = await doGet(endpoint.payment_gateway_options, {}, getDefaultHeader())
    if(response){
      setgatewayOptions(generalListOptionMapper(response.data))
    }
  }

  const getClientOptions =async()=>{
    
    const response = await doGet(endpoint.client_options, {}, getDefaultHeader())
    if(response){
      setclientOptions(generalListOptionMapper(response.data))
    }
  }

  const getBankOptions =async()=>{
    
    const response = await doGet(endpoint.bank_options, {}, getDefaultHeader())
    if(response){
      setbankOptions(generalListOptionMapper(response.data))
    }
  }

  const getvendorOptions =async()=>{
     
    const response = await doGet(endpoint.vendor_options, {}, getDefaultHeader())
    if(response){
      setvendorOptions(generalListOptionMapper(response.data))
    }
  }

  const getsellerOptions =async()=>{
     
    const response = await doGet(endpoint.seller_options, {}, getDefaultHeader())
    if(response){
      setsellerOptions(generalListOptionMapper(response.data))
    }
  }

  const getFilterOptions=(field)=>{

    if(field.name==="status"){
      return statusOptions
    }
  }

  const popupFilterShow = (event) => {
    if(filtersAvailable.length>0){
      setanchorFilter(event.currentTarget);
    }
  };

  const popupFilterClose = () => {
    setanchorFilter(null);
  };

  const addFilter =(field)=>{
    let filtersTemp = {...filters}
    filtersTemp[field.name] = null
    setFilters(filtersTemp)
    let newFiltersAvailable = filtersAvailable.filter(f=>f.name!==field.name)
    setfiltersAvailable(newFiltersAvailable)
    setfilterBy([...filterBy,field])
  }

  const removeFilter =(field)=>{
    let filtersTemp = {...filters}
    delete filtersTemp[field.name]
    setFilters(filtersTemp)
    let newFilterBy = filterBy.filter(f=>f.name!==field.name)
    setfilterBy(newFilterBy)
    setfiltersAvailable([...filtersAvailable,field])
  }

  const filterChange =(fieldName, value)=>{
    let filtersTemp = {...filters}
    filtersTemp[fieldName] = value
    setFilters(filtersTemp)
  }

  useDebounce(
        () => {
            getData()
        },
        700,
        [filters.keyword]
      );

  useEffect(() => {
      getData()
  }, [page,rowsPerPage])

  useEffect(() => {
    if(user && setting)  {
      getUserActions(setting.active_menu.id, user.roles.map(r=>r.id))
    }    
  }, [user, setting])


  const getData =async()=>{
    let params = {
      keyword:filters.keyword,
      status:filters.status?filters.status.value:undefined,
      roleId:filters.role?filters.role.value:undefined,
      page:page,
      rowsPerPage:rowsPerPage
    }
    
    const response = await doGet(endpoint.bank_account, params, getDefaultHeader())
    if(response && response.data && response.data.data){
      //const modifiedData = response.data.data.map(row=>({...row, selected:false}))
      //setDataTable(modifiedData)
      setDataTable(response.data.data)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    }
  }

  const getRowDetail =async(row)=>{
    let params = {
    }
    
    const response = await doGet(endpoint.bank_account_id_relation.replace("id",row.id), params, getDefaultHeader())
    if(response){
      setselectedRow(response.data)
    }
  }

  const getDataByFilter =()=>{
    getData()
  }


  const changeKeyword = (event)=> {
      setFilters({...filters, keyword:event.target.value})
  }

  const changePage=(event, newPage)=>{
      setPage(newPage+1)
  }

  const changeRowsPerPage = (event)=> {
      setRowsPerPage(+event.target.value);
      setPage(1);
  }

  const showAddForm =()=>{
    setpageAction("add")
    setopenForm(true)
    setisDialogFull(true)
  }

  const showBankForm =()=>{
    setpageAction("showBank")
    setopenForm(true)
    setisDialogFull(true)
  }

  const showEditForm =()=>{
    setpageAction("edit")
    setopenForm(true)
    setisDialogFull(true)
  }
  const showDetail =(row)=>{
    toggleSelectRow(row)
    setselectedRow(row)
    setpageAction("detail")
    setopenForm(true)
    setisDialogFull(true)
  }

  const showDeleteConfirmation =()=>{
    setpageAction("delete")
    setopenForm(true)
    setisDialogFull(false)
  }

  const handleCloseForm =()=>{
    setopenForm(false)
    if(pageAction==="add"){

    }
    else if(pageAction==="edit"){

    }
    else if(pageAction==="delete"){

    }
    else if(pageAction==="detail"){
      setselectedRow(null)
      setselectedIds([])
    }

  }

  const insert =async(payload)=>{
    
    const response = await doPost(endpoint.bank_account, payload,"insert "+setting.active_menu.display ,getDefaultHeader())
    getData()
    return response
  }

  const update =async(payload)=>{
    
    const response = await doPut(endpoint.bank_account, payload,"update "+setting.active_menu.display ,getDefaultHeader())
    getData()
    return response
  }

  const handleDelete =async()=>{
    
    const payload = {
      ids:selectedIds
    }
    const response = await doDelete(endpoint.bank_account, payload,"delete "+setting.active_menu.display ,getDefaultHeader())
    setselectedIds([])
    getData()
  }

  const toggleSelectRow =(row)=>{
    if(selectedIds.includes(row.id)){
        setselectedIds(selectedIds.filter(item=>item!==row.id))
        setselectedRow(null)
    }else{
        setselectedIds([...selectedIds,row.id])
        setselectedRow(row)
    }
  }



  const toggleSelectAllRow=()=>{
    if(selectedIds.length === dataTable.length){
      setselectedIds([])
    }else{
      setselectedIds(dataTable.map(row=>row.id))
    }

  }

  const changepageAction =(pageAction)=>{
    setpageAction(pageAction)
  }



  if(user && user.token){
    return (
      <>
        <Grid container justify="flex-start" alignItems="flex-start" alignContent="flex-start" className={classes.root}>
            <Grid container className={classes.toolbarContainer}>
              <Grid container item alignContent="center" xs={12} sm={12} md={6} lg={6} className={classes.toolbarLeft}>
                <Typography color="primary" component="h5" variant="h5">
                  {setting.active_menu.display}
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={12} md={6} lg={6} spacing={1}  direction="row-reverse" justify="flex-start" className={classes.toolbarRight}>
                  <Protected user={user}  userActions={userActions} allowedCodes={['CACCOUNT']} activeMenu={setting.active_menu}>
                    <Button onClick={showAddForm} variant="contained" color="primary" size="small" className={classes.smallButton}>
                      Tambah
                    </Button>
                  </Protected>

                  <Protected user={user}  userActions={userActions} allowedCodes={['CBANK']} activeMenu={setting.active_menu}>
                    <Button onClick={showBankForm} variant="contained" color="primary" size="small" className={classes.smallButton}>
                      Data Bank
                    </Button>
                  </Protected>

                  {
                    selectedIds.length===1 &&
                    <Protected user={user}  userActions={userActions} allowedCodes={['EACCOUNT']} activeMenu={setting.active_menu}>
                      <Button onClick={showEditForm} variant="contained" color="default" size="small" className={classes.smallButton}>Edit</Button>
                    </Protected>
                  }
                  {
                    selectedIds.length>0 &&
                    <Protected user={user}  userActions={userActions} allowedCodes={['DACCOUNT']} activeMenu={setting.active_menu}>
                      <Button onClick={showDeleteConfirmation} variant="contained" color="secondary" size="small" className={classes.smallButton}>Hapus</Button>
                    </Protected>
                  }
                </Grid>
            </Grid>           

            <Grid container spacing={1} className={classes.filterContainer}>
              {
               filters && filters.keyword!==undefined &&
               <Grid item xs={12} sm={12} md={2} lg={2}>
                  <TextField
                    fullWidth
                    value={filters.keyword}
                    onChange={changeKeyword}
                    className={classes.margin}
                    id="search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              }
              {
                filterBy && filterBy.map(field=>(
                  <FilterComponent
                  options = {getFilterOptions(field)}
                  removeFilter = {removeFilter}
                  filterChange = {filterChange}
                  field = {field}
                  />
                ))
              }
              <Grid xs={12} sm={12} md={2} lg={2} item container justify="space-between">
                <Grid xs={6} item container justify="flex-start">
                  <IconButton onClick={popupFilterShow} aria-label="delete" className={classes.margin}>
                    <FilterListIcon color="primary"/>
                  </IconButton>
                </Grid>

                <Grid xs={6} item container justify="flex-end">
                  <IconButton onClick={getDataByFilter} aria-label="delete" className={classes.margin}>
                    <Send color="primary"/>
                  </IconButton>
                </Grid>                
              </Grid>              
            </Grid>
            <Grid container className={classes.tableContainer}>
              <DataTable
                user = {user}
                toggleSelectRow={toggleSelectRow}
                toggleSelectAllRow={toggleSelectAllRow}
                showDetail={showDetail}
                selectedIds={selectedIds}
                dataTable={dataTable}
                totalRows={totalRows}
                page={page}
                rowsPerPage={rowsPerPage}
                changePage={changePage}
                changeRowsPerPage={changeRowsPerPage}
              />
            </Grid>

        </Grid>

        <Dialog open={openForm} fullScreen={isDialogFull} onClose={handleCloseForm} aria-labelledby="form-dialog-title">
          <FormContent
          getRowDetail={getRowDetail}
          user={user}
          pageAction={pageAction}
          userActions={userActions}
          title={setting.active_menu.display}
          insert={insert}
          handleClose={handleCloseForm}
          handleDelete={handleDelete}
          selectedIds={selectedIds}
          selectedRow={selectedRow}
          changepageAction={changepageAction}
          clientOptions={clientOptions}
          bankOptions={bankOptions}
          update={update}
          setting={setting}
          gatewayOptions={gatewayOptions}
          vendorOptions={vendorOptions}
          sellerOptions={sellerOptions}
          />
        </Dialog>

        <Popover
          id="popUpFilter"
          open={Boolean(anchorFilter)}
          anchorEl={anchorFilter}
          onClose={popupFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          className={classes.popUpFilter}
          style={
            {marginTop:8}
          }
        >
          <FilterList fields={filtersAvailable} filters={filters} onItemClick={addFilter} onClose={popupFilterClose}/>
        </Popover>


        </>
    );
  }
  else{
    return <Redirect to={{ pathname: '/login' }} />
  }

}

export default BankAccount;

const FormContent=(props)=>{
  const {user, pageAction, userActions, getRowDetail, title, vendorOptions, sellerOptions, gatewayOptions, clientOptions, bankOptions,  insert, update, handleClose, handleDelete, selectedIds, selectedRow, changepageAction, setting} =  props

  if(pageAction==="detail"){

      return(<FormDetail
        getRowDetail={getRowDetail}
        user={user}
        userActions={userActions}
        handleClose={handleClose}
        row = {selectedRow}
        title={title}
        changepageAction={changepageAction}
        setting={setting}
        pageAction={pageAction}
        />
      )

  }
  else if(pageAction==="add"){

    return(
        <FormAdd        
        pageAction={pageAction}
        user={user}
        userActions={userActions}
        setting={setting}        
        handleClose={handleClose}
        clientOptions={clientOptions}
        bankOptions={bankOptions}
        vendorOptions={vendorOptions}
        sellerOptions={sellerOptions}
        handleSave={insert}
        title={title}
        />
      )

  }
  else if(pageAction==="edit"){

    return(<FormEdit
        pageAction={pageAction}
        userActions={userActions}
        setting={setting}              
        getRowDetail={getRowDetail}
        user={user}
        handleClose={handleClose}
        clientOptions={clientOptions}
        bankOptions={bankOptions}
        vendorOptions={vendorOptions}
        sellerOptions={sellerOptions}
        row = {selectedRow}
        title={title}
        handleSave={update}        
        />
      )

  }
  else if(pageAction==="delete"){

    return( <DeleteConfirmation
        handleClose = {handleClose}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title={title}/>
      )
  }
  else if(pageAction==="showBank"){

    return( <BankForm        
        handleClose = {handleClose}
        userActions={userActions}
        title={title}
        user={user}
        setting={setting}
        gatewayOptions={gatewayOptions}
        />
      )
  }
  
  else return null
}



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
 toolbarContainer: {
    marginTop:theme.spacing(1)
  },
  filterContainer: {
    marginBottom:theme.spacing(1)
  },
  tableContainer: {
    minHeight:400,
  },
  clickable:{
    cursor:'pointer',
    fontWeight:'bold',
    color:theme.palette.primary.main
  },
  formContainer: {
    minHeight:'80vh',
    marginTop:theme.spacing(1)
  },
  buttonContainer:{
    marginTop:theme.spacing(2)
  },
  formPaper:{
    width:'inherit',
    padding:theme.spacing(2)
  },
  paginatonContainer: {
    height:40,
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  marginB1:{
    marginBottom:theme.spacing(1)
  },
  reactSelect:{
    width:"100%"
  },
  margin1:{
    margin: theme.spacing(1)
  },
  closeButton:{
    top:-20,
    marginLeft:"auto",
    marginRight:0
  }

}));

const selectCustomZindex = {
    control: (base, state) => ({
        ...base,
        minWidth: "150px",
        margin: "0 4px"
    }),
    container: (base, state) => {
        return {
            ...base,
            flex: 1,
            zIndex: state.isFocused ? "1100" : "1" //Only when current state focused
        };
    }
};
