import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme.js'
import ImageUploader from "react-images-upload";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const FormAdd =(props)=> {
  const {title, userActions, user, gatewayOptions, handleClose, handleSave} = props
  const classes = useStyles();
  const [state, setstate] = useState({
    status:1,
    logo:[]
  })
  const [pictures, setPictures] = useState([]);

  const onDrop = picture => {
    setPictures([...pictures, picture]);
  };

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    if(fieldName==="logo"){
      newState[fieldName] = [...newState[fieldName], eventValue]
    }
    else if(fieldName==="status"){
      value = eventValue.target.checked? 1:0
      newState[fieldName] = value
    }
    else{
      newState[fieldName] = value
    }
    
    setstate(newState)
  }


  const submit = async()=>{
    const formData = new FormData();
    formData.append("type", state.type);
    formData.append("name", state.name);
    formData.append("gateway", state.gateway.value);
    formData.append("price", state.price);
    formData.append("corp", state.corp);
    formData.append("logo", state.logo[0][0], state.logo[0][0].name);
    formData.append("alt", state.alt);
    formData.append("status", state.status);
    
    await handleSave(formData)    
    handleClose()
  }


  const reset=()=>{
    setstate({})
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">Tambah {title}</DialogTitle>
      <DialogContent>
        <Grid container item xs={12} justify='center'>
          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="type"
              label="type"
              fullWidth
              value={state.type}
              onChange={(e)=>handleChange("type",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="name"
              label="name"
              fullWidth
              value={state.name}
              onChange={(e)=>handleChange("name",e)}
            />
          </Grid>

          <Grid item container style={{margin:'8px 0 8px 0'}}>
            <Select
              name="gateway"
              placeholder = "payment gateway..."
              options={gatewayOptions}
              onChange={(e)=>handleChange("gateway",e)}
              value={state.gateway}
              styles={selectCustomZindex}
            />                
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="corp"
              label="corp"
              fullWidth
              value={state.corp}
              onChange={(e)=>handleChange("corp",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="price"
              label="price"
              fullWidth
              value={state.price}
              onChange={(e)=>handleChange("price",e)}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <ImageUploader
              singleImage={true}
              withIcon={true}
              buttonText="Choose images"
              onChange={(e)=>handleChange('logo',e)}
              imgExtension={[".jpg", ".gif", ".png", ".gif"]}
              maxFileSize={5242880}
              withPreview={true}
            />
          </Grid>

          <Grid container justify='center' alignItems='flex-start'>
            <DebouncedTextField
              margin="dense"
              id="alt"
              label="alt"
              fullWidth
              value={state.alt}
              onChange={(e)=>handleChange("alt",e)}
            />
          </Grid>

          <Grid container alignItems='flex-start' style={{marginTop:16}}>
          <FormControlLabel
              value="status"
              control={
                  <Switch 
                  color="primary" 
                  checked={Number(state.status)===1} 
                  onChange={(e)=>handleChange("status",e)}
                  />
                }
              label="Status"
              labelPlacement="start"
              style={{marginLeft:0}}
            />
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>
</>

    );
}

export default FormAdd;

const useStyles = makeStyles((theme) => ({

}));
