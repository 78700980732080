
export const userOptionMapper=(rows)=>{
  if(rows){
    return rows.map(row=>({...row, label:row.username, value:row.id}))
  }
  else{
    return null
  }  
  
  }

  export const userListOptionMapper=(rows)=>{
    if(rows){
      return rows.map(row=>({...row,label:row.username, value:row.id}))
    }
    else{
      return null
    }  
    
  }

  export const generalListOptionMapper=(rows)=>{
  if(rows){
    return rows.map(row=>({...row,label:row.name, value:row.id}))
  }
  else{
    return null
  }  
  
}

export const generalOptionMapper=(row)=>{
  if(row){
    return {...row,label:row.name, value:row.id}
  }
  else{
    return null
  }  
}

export const parameterOptionMapper=(row)=>{
  if(row){
    return {...row,label:row.name, value:row.value}
  }
  else{
    return null
  }
  
}

export const parameterListOptionMapper=(rows)=>{
  if(rows){
    return rows.map(row=>({...row,label:row.name, value:Number(row.value)}))
  }
  else{
    return null
  }
  
}