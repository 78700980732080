import React,{useState,useEffect} from 'react';
import {doGet,doPost} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Protected   from 'components/Protected.js';
import RoutesDetail from './RoutesDetail.js'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const FormDetail =(props)=> {
  const {title, userActions, user, row, handleClose, changepageAction, setting, pageAction} = props
  const classes = useStyles();

  useEffect(() => {
      if(row && user){
      }
  }, [row])

  useEffect(() => {

  }, []) 
  

  if(row)
    return (
    <>
      <DialogTitle id="form-dialog-title">Detail</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container justify='space-between' className={classes.root}>                    
          <Grid container item xs={12} sm={12} md={4} lg={4} justify='flex-start' alignItems='flex-start' alignContent="flex-start" className={classes.left}>
            <Grid container alignContent="center" justify="center" className={classes.header}>
              <Typography variant="h6">Data {title}</Typography>
            </Grid>
            <Grid container justify='center' alignItems='flex-start' className={classes.content}>
              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="id"
                  label="id"
                  fullWidth
                  value={row.id}
                  readOnly                 
                />
              </Grid>
              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="name"
                  label="name"
                  fullWidth
                  value={row.name}
                  readOnly                 
                />
              </Grid>

              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="host"
                  label="host"
                  fullWidth
                  value={row.host}
                  readOnly              
                />
              </Grid>

              <Grid container alignItems='flex-start' style={{marginTop:16}}>
                <FormControlLabel
                    value="status"
                    control={
                        <Switch 
                        color="primary" 
                        checked={Number(row.status)===1}                   
                        />
                      }
                    label="Status"
                    labelPlacement="start"
                    style={{marginLeft:0}}
                  />
              </Grid>               

                </Grid>
          </Grid>

          <Grid container item xs={12} sm={12} md={7} lg={7} justify='center' alignItems="flex-start" alignContent="flex-start" className={classes.right}>                        
            <RoutesDetail 
            userActions={userActions}
            gateway={row} 
            user={user} 
            activeMenu={setting.active_menu} 
            pageAction={pageAction}
            />  
          </Grid>
        </Grid>


      </DialogContent>

      { user && setting &&
        <DialogActions>
          <Protected user={user} userActions={userActions} allowedCodes={['EGATEWAY']} activeMenu={setting.active_menu}>
            <Button onClick={()=>changepageAction("edit")} color="primary">
              Edit
            </Button>
          </Protected>
          <Button variant="contained" onClick={handleClose} color="primary">
            Kembali
          </Button>
        </DialogActions>
      }

  </>

    )
    else{
      return null
    }
}

export default FormDetail;

const useStyles = makeStyles((theme) => ({

  root:{
    padding:theme.spacing(1)
  }, 
  left:{
    marginBottom:theme.spacing(3)
  },  
  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )',
    borderTop: '1px solid',
    borderColor:theme.borderColor
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
