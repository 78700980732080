import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Protected   from 'components/Protected.js';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const FormDetail =(props)=> {
  const {title, userActions, user, row, handleClose, changeAction, setting} = props
  const classes = useStyles();

  if(row)
    return (
    <>
      <DialogTitle id="form-dialog-title">Detail {title}</DialogTitle>
      <DialogContent>
      <Grid container item xs={12} justify='center'>
        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="Id"
            label="Id"
            fullWidth
            value={row.id}
            readOnly
          />
        </Grid>
        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="Name"
            label="Name"
            fullWidth
            value={row.name}
            readOnly
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="Code"
            label="Code"
            fullWidth
            value={row.code}
            readOnly
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="Value"
            label="Value"
            fullWidth
            value={row.value}
            readOnly
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="Group"
            label="Group"
            fullWidth
            value={row.group}
            readOnly
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="Description"
            label="Description"
            fullWidth
            value={row.description}
            readOnly
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="KeyName"
            label="KeyName"
            fullWidth
            value={row.keyName}
            readOnly
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="Key"
            label="Key"
            fullWidth
            value={row.key}
            readOnly
          />
        </Grid>

        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="Role"
            label="Role"
            fullWidth
            value={row.roleId}
            readOnly
          />
        </Grid>

        <Grid container justify='flex-start' alignItems='center'>
          <FormControlLabel
            value="status"
            control={
                <Switch 
                color="primary" 
                checked={Number(row.status)===1} 
                />
              }
            label="Status"
            labelPlacement="start"
            style={{marginLeft:0}}
          />
        </Grid>
        </Grid>
      </DialogContent>

      { user && setting &&
        <DialogActions>
          <Protected user={user} userActions={userActions} allowedCodes={['EPARAM']} activeMenu={setting.active_menu}>
            <Button onClick={()=>changeAction("edit")} color="primary">
              Edit
            </Button>
          </Protected>
          <Button variant="contained" onClick={handleClose} color="primary">
            Kembali
          </Button>
        </DialogActions>
      }

  </>

    )
    else{
      return null
    }
}

export default FormDetail;

const useStyles = makeStyles((theme) => ({

}));
