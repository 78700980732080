import React,{useState,useEffect} from 'react';
import {doGet} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Protected   from 'components/Protected.js';
import ActionDetail from './ActionDetail.js'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const FormDetail =(props)=> {
  const {title, userActions, user, row, handleClose, changepageAction, setting, pageAction} = props
  const classes = useStyles();
  const [actions, setactions] = useState([])


  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  useEffect(() => {
      if(row && user){
        getActions()
      }
  }, [row])

  useEffect(() => {

  }, [])

  const getActions =async()=>{
    let params = {
    }
    
    const response = await doGet(endpoint.menu_id_action.replace("id",row.id), params, getDefaultHeader())
    if(response){

      //const modifiedData = response.data.data.map(row=>({...row, selected:false}))
      //setDataTable(modifiedData)
      setactions(response.data)
    }
  }

  if(row)
    return (
    <>
      <DialogTitle id="form-dialog-title">Detail</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2} justify='space-between' className={classes.root}>
          <Grid container item xs={12} sm={12} md={4} lg={4} justify='flex-start' alignItems='flex-start' alignContent="flex-start" className={classes.left}>
            <Grid container alignContent="center" justify="center" className={classes.header}>
              <Typography variant="h6">Data {title}</Typography>
            </Grid>
            <Grid container justify='center' alignItems='flex-start' className={classes.content}>
                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="Id"
                    label="Id"
                    fullWidth
                    value={row.id}
                    readOnly
                  />
                </Grid>
                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="parentId"
                    label="parentId"
                    fullWidth
                    value={row.parentId}
                    readOnly
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="categoryId"
                    label="categoryId"
                    fullWidth
                    value={row.categoryId}
                    readOnly
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="code"
                    label="code"
                    fullWidth
                    value={row.code}
                    readOnly
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="display"
                    label="display"
                    fullWidth
                    value={row.display}
                    readOnly
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="webPath"
                    label="webPath"
                    fullWidth
                    value={row.webPath}
                    readOnly
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="androidActivity"
                    label="androidActivity"
                    fullWidth
                    value={row.androidActivity}
                    readOnly
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="iosClass"
                    label="iosClass"
                    fullWidth
                    value={row.iosClass}
                    readOnly
                  />
                </Grid>

                <Grid container justify='center' alignItems='flex-start'>
                  <DebouncedTextField
                    margin="dense"
                    id="icon"
                    label="icon"
                    fullWidth
                    value={row.icon}
                    readOnly
                  />
                </Grid>

                <Grid container justify='flex-start' alignItems='center'>
                  <FormControlLabel
                    value="status"
                    control={
                        <Switch 
                        color="primary" 
                        checked={Number(row.status.value)===1} 
                        />
                      }
                    label="Status"
                    labelPlacement="start"
                    style={{marginLeft:0}}
                  />
                </Grid>
                </Grid>
          </Grid>

          <Grid container item xs={12} sm={12} md={7} lg={7} justify='center' alignItems="flex-start" alignContent="flex-start" className={classes.right}>
            <ActionDetail 
            userActions={userActions}
            menu={row} 
            user={user} 
            activeMenu={setting.active_menu} 
            actions={actions} 
            pageAction={pageAction}
            />
          </Grid>
        </Grid>


      </DialogContent>

      { user && setting &&
        <DialogActions>
          <Protected user={user} userActions={userActions} allowedCodes={['EMENU']} activeMenu={setting.active_menu}>
            <Button onClick={()=>changepageAction("edit")} color="primary">
              Edit
            </Button>
          </Protected>
          <Button variant="contained" onClick={handleClose} color="primary">
            Kembali
          </Button>
        </DialogActions>
      }

  </>

    )
    else{
      return null
    }
}

export default FormDetail;

const useStyles = makeStyles((theme) => ({

  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )',
    borderTop: '1px solid',
    borderColor:theme.borderColor
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
