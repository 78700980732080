import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {doGet, doPost, doDelete, doPut,doPatch} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import SubDetail from './SubDetail.js'
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme.js'
import {generalOptionMapper} from 'utils/mappers.js'


const FormEdit =(props)=> {
  const {title, userActions, user, row, handleClose, changepageAction, pageAction, setting, handleSave, typeOptions} = props
  const classes = useStyles();
  const [state, setstate] = useState(null)

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  useEffect(() => {
      if(row){
      	getRowComplete()
      }
  }, [row])

  const getRowComplete =async()=>{
    let params = {}
    
    const response = await doGet(endpoint.user_id_relation.replace("id",row.id), params, getDefaultHeader())
    if(response){
      const modifiedData = {
        ...response.data,
        type:generalOptionMapper(response.data.type)
      }
      setstate(modifiedData)
    }
  }

  const toggleRole= async(userId, roleId, toggle)=>{
    const payload = {
      userId:userId,
      roleId:roleId,
      toggle:toggle
    }
    
    const response = await doPatch(endpoint.user_role, payload,"toggle role" ,getDefaultHeader())
    getRowComplete()
  }

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    newState[fieldName] = value
    setstate(newState)
  }

  const submit = async()=>{
    const modifiedData = {
      ...state,
      type:state.type ? state.type.value : null
    }
    const response = await handleSave(modifiedData)
    handleClose()
  }

  if(state)
    return (
    <>
      <DialogTitle id="form-dialog-title">Edit</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} justify='space-between' className={classes.root}>
            <Grid container item xs={12} sm={12} md={4} lg={4} justify='flex-start' alignItems='flex-start' alignContent="flex-start" className={classes.left}>
              <Grid container alignContent="center" justify="center" className={classes.header}>
                <Typography variant="h6">Data {title}</Typography>
              </Grid>
              <Grid container justify='center' alignItems='flex-start' className={classes.content}>
              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="Id"
                  label="Id"
                  fullWidth
                  value={state.id}
                  readOnly
                />
              </Grid>
             
              <Grid container justify='center' alignItems='flex-start'>
                <DebouncedTextField
                  margin="dense"
                  id="username"
                  label="Username"
                  fullWidth
                  value={state.username}
                  onChange={(e)=>handleChange("username",e)}
                />
              </Grid>

              <Grid container justify='center' alignItems='flex-start' style={{margin:'8px 0 8px 0'}}>                 
                <Select
                  name="type"
                  placeholder = "user type"
                  options={typeOptions}
                  onChange={(e)=>handleChange("type",e)}
                  value={state.type}
                  styles={selectCustomZindex}
                /> 
              </Grid>

              </Grid>
            </Grid>

      <Grid container item xs={12} sm={12} md={7} lg={7} justify='flex-end' alignItems="flex-start" alignContent="flex-start" className={classes.right}>
          <SubDetail
          row={state}
          user={user}
          pageAction={pageAction}
          toggleRole={toggleRole}/>
      </Grid>
    </Grid>
  </DialogContent>


      { user && setting &&
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Kembali
          </Button>
          <Button variant="contained" onClick={submit} color="primary">
            Simpan
          </Button>
        </DialogActions>
      }

  </>

    )
    else{
      return null
    }
}

export default FormEdit;

const useStyles = makeStyles((theme) => ({
  dialogContent:{
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )',
    borderTop: '1px solid',
    borderColor:theme.borderColor
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
}));
