import React,{useState, useEffect, useRef} from 'react';
import { Redirect,useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { useDebounce } from 'react-use';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Cancel from '@material-ui/icons/Cancel';
import Send from '@material-ui/icons/Send';
import FilterList from 'components/FilterList';
import ClientDetail   from './ClientDetail.js';
import FilterComponent   from 'components/FilterComponent.js';
import DeleteConfirmation   from 'components/DeleteConfirmation.js';
import Popover from '@material-ui/core/Popover';
import Select from 'react-select'
import Dialog from '@material-ui/core/Dialog';
import {generalListOptionMapper, parameterListOptionMapper} from 'utils/mappers.js'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination   from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import StatusChip from 'components/StatusChip';
import ActionDisplay from 'components/ActionDisplay.js';
import ClientForm from './ClientForm.js';
import Protected from 'components/Protected.js';


const Client =(props)=> {
  const isInitialMount = useRef(true);
  const [userActions, setuserActions] = useState([])
  let { path, url } = useRouteMatch();
  const user = useSelector(state => state.user);
  const setting = useSelector(state => state.setting);
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [filters, setFilters] = useState({keyword:''})
  const [filtersAvailable, setfiltersAvailable] = useState([
    {name:'status', display:'status', type:'select'},
    {name:'jenjang', display:'jenjang', type:'select'}
  ])
  const [filterBy, setfilterBy] = useState([])
  const [statusOptions, setstatusOptions] = useState([{label:'aktif', value:1}, {label:'nonaktif', value:0}])
  const [pageAction, setpageAction] = useState('')
  const [openDialog, setopenDialog] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [isDialogFull, setisDialogFull] = useState(false);
  const [anchorFilter, setanchorFilter] = useState(null);
  const [selectedIds, setselectedIds] = useState([])
  const [selectedRow, setselectedRow] = useState(null)
  
  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  const getUserActions =async(menu, roles)=>{   
    let params={
      menu:menu,
      roles:roles
    } 

    const response = await doGet(endpoint.action_menu_role, params, getDefaultHeader())
    if(response && response.data){
      setuserActions(response.data)
    }
  }

  const getFilterOptions=(field)=>{
    if(field.name==='status'){
      return statusOptions
    }
  }

  const popupFilterShow = (event) => {
    if(filtersAvailable.length>0){
      setanchorFilter(event.currentTarget);
    }
  };

  const popupFilterClose = () => {
    setanchorFilter(null);
  };

  const addFilter =(field)=>{
    let filtersTemp = {...filters}
    filtersTemp[field.name] = null
    setFilters(filtersTemp)
    let newFiltersAvailable = filtersAvailable.filter(f=>f.name!==field.name)
    setfiltersAvailable(newFiltersAvailable)
    setfilterBy([...filterBy,field])
  }

  const removeFilter =(field)=>{
    let filtersTemp = {...filters}
    delete filtersTemp[field.name]
    setFilters(filtersTemp)
    let newFilterBy = filterBy.filter(f=>f.name!==field.name)
    setfilterBy(newFilterBy)
    setfiltersAvailable([...filtersAvailable,field])
  }

  const filterChange =(fieldName, value)=>{
    let filtersTemp = {...filters}
    filtersTemp[fieldName] = value
    setFilters(filtersTemp)
  } 


  const getData =async(newPage, newRowsPerPage)=>{
    let params = {
      keyword:filters.keyword,
      status:filters.status?filters.status.value:undefined,
      id_jenjang:filters.jenjang?filters.jenjang.value:undefined,
      page:newPage ? newPage : page,
      rowsPerPage:newRowsPerPage ? newRowsPerPage : rowsPerPage
    }
    
    const response = await doGet(endpoint.client, params, getDefaultHeader())
    
    if(response && response.data && response.data.data){
      const modifiedData = response.data.data.map(row=>({
        ...row
      }))
      setDataTable(modifiedData)
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    }
  }

  const getDataByFilter =()=>{
    getData()
  }


  const changeKeyword = (event)=> {
      setFilters({...filters, keyword:event.target.value})
  }

  const changePage=(event, newPage)=>{
      setPage(newPage+1)
      getData(newPage+1,null)
  }

  const changeRowsPerPage = (event)=> {
      setRowsPerPage(+event.target.value);
      setPage(1);
      getData(1,+event.target.value)
  }   

  const showDialog = (actionCode, rowParam) => {
    setpageAction(actionCode)
    let row=undefined
    if(rowParam){
      row = rowParam
    }else{
      row = selectedRow
    }
    setMaxWidth('lg');
    setdialogContent(
      <ClientForm
        row={row}
        getData={getData}
        pageAction={actionCode}
        closeMainDialog={closeMainDialog}
        userActions={userActions}
      />
    );

    setopenDialog(true);
  };

  const showDeleteConfirmation = () => {
    setMaxWidth("sm");
    setdialogContent(
      <DeleteConfirmation
        handleClose={closeMainDialog}
        handleDelete={handleDelete}
        selectedIds={selectedIds}
        title="Client"
      />
    );

    setopenDialog(true);
  };


  const closeMainDialog =()=>{
    setopenDialog(false)
    if(pageAction==='READ'){
      setselectedRow(null)
      setselectedIds([])
    }
  }  


  const handleDelete = async () => {
    const params = {
      ids: selectedIds
    };
    const response = await doDelete(endpoint.client, params,"delete "+setting.active_menu.display ,getDefaultHeader())
    if (response) {
      setselectedIds([])
      setselectedRow(null)
      getData();
    }
  };

  const toggleSelectRow =(row)=>{
    if(selectedIds.includes(row.id)){
      const ids = selectedIds.filter(item=>item!==row.id)
      setselectedIds(ids)
      
      if(ids.length===1){
        const existingRow = dataTable.filter(data=>(data.id===ids[0]))
        setselectedRow(existingRow[0])
      }
      else{
        setselectedRow(null)
      }      
      
    }else{      
      setselectedIds([...selectedIds,row.id])
      setselectedRow(row)
    }
  }

  const toggleSelectAllRow=()=>{
    if(selectedIds.length === dataTable.length){
      setselectedIds([])
    }else{
      setselectedIds(dataTable.map(row=>row.id))
    }
  }
  
  useEffect(() => {
    getData()
  }, [])

  useDebounce(
    () => {
      if (isInitialMount.current) {
          isInitialMount.current = false;
      } else {
        getData()        
      }        
    },
    700,
    [filters.keyword]
  );   

  useEffect(() => {
    if(user && setting)  {
      getUserActions(setting.active_menu.id, user.roles.map(r=>r.id))
    }    
  }, [user, setting])

    return (
      <>
        <Grid container justify='flex-start' alignItems='flex-start' alignContent='flex-start' className={classes.root}>
            <Grid container className={classes.toolbarContainer}>
              <Grid container item alignContent='center' xs={12} sm={12} md={6} lg={6} className={classes.toolbarLeft}>
                <Typography color='primary' component='h5' variant='h5'>
                {
                //setting&&setting.active_menu
                }
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={12} md={6} lg={6} spacing={1}  direction='row-reverse' justify='flex-start' className={classes.toolbarRight}>
                  <Protected user={user}  userActions={userActions} allowedCodes={['CCLIE']} activeMenu={setting.active_menu}>
                    <Button onClick={()=>showDialog('CCLIE')} variant='contained' color='primary' size='small' className={classes.smallButton}>
                      <ActionDisplay userActions={userActions} code='CCLIE'/>
                    </Button>
                  </Protected>
                {
                  selectedIds.length===1 &&
                  <Protected user={user}  userActions={userActions} allowedCodes={['ECLIE']} activeMenu={setting.active_menu}>
                    <Button onClick={()=>showDialog('ECLIE')} variant='contained' color='default' size='small' className={classes.smallButton}>
                      <ActionDisplay userActions={userActions} code='ECLIE'/>
                    </Button>
                  </Protected>
                    
                }
                {
                  selectedIds.length>0 &&
                    <Protected user={user}  userActions={userActions} allowedCodes={['DCLIE']} activeMenu={setting.active_menu}>
                      <Button onClick={showDeleteConfirmation} variant='contained' color='secondary' size='small' className={classes.smallButton}>
                        <ActionDisplay userActions={userActions} code='DCLIE'/>
                      </Button>
                    </Protected>
                }
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.filterContainer}>
              {
               filters && filters.keyword!==undefined &&
               <Grid item xs={12} sm={12} md={2} lg={2}>
                  <TextField
                    fullWidth
                    value={filters.keyword}
                    onChange={changeKeyword}
                    className={classes.margin}
                    id='search'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              }
              {
                filterBy && filterBy.map(field=>(
                  <FilterComponent
                  options = {getFilterOptions(field)}
                  removeFilter = {removeFilter}
                  filterChange = {filterChange}
                  field = {field}
                  />
                ))
              }
              <Grid xs={12} sm={12} md={2} lg={2} item container justify='space-between'>
                <Grid xs={6} item container justify='flex-start'>
                  <IconButton onClick={popupFilterShow} aria-label='delete' className={classes.margin}>
                    <FilterListIcon color='primary'/>
                  </IconButton>
                </Grid>

                <Grid xs={6} item container justify='flex-end'>
                  <IconButton onClick={getDataByFilter} aria-label='delete' className={classes.margin}>
                    <Send color='primary'/>
                  </IconButton>
                </Grid>                
              </Grid>
            </Grid>
            <Grid container className={classes.tableContainer}>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          inputProps={{ 'aria-label': 'select all' }}
                          onChange={toggleSelectAllRow}
                          checked={selectedIds.length === dataTable.length && dataTable.length>0}
                        />
                      </TableCell>
                      <TableCell>id</TableCell>
                      <TableCell>name</TableCell>
                      <TableCell>active student</TableCell>
                      <TableCell>address</TableCell>
                      <TableCell>email</TableCell>
                      <TableCell>phone</TableCell>
                      <TableCell>code</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataTable.map((row) => (
                      <TableRow key={row.id} onClick={()=>toggleSelectRow(row)}>
                        <TableCell>
                        <Checkbox
                            checked={selectedIds.includes(row.id)}                            
                            color="primary"
                            inputProps={{ 'aria-label': 'select all' }}
                          />
                        </TableCell>
                        <TableCell >{row.id}</TableCell>
                        <TableCell onClick={()=>showDialog('READ',row)} className={classes.clickable}>{row.name}</TableCell>                
                        <TableCell >{(row.userstudent - row.orphanStudent)}</TableCell>
                        <TableCell >{row.address}</TableCell>
                        <TableCell >{row.email}</TableCell>
                        <TableCell >{row.phone}</TableCell>
                        <TableCell >{row.code}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Grid container justify="flex-end" className={classes.paginationContainer}>
                  <TablePagination
                      rowsPerPageOptions={[10,  25, 50]}
                      component="div"
                      count={totalRows}
                      rowsPerPage={rowsPerPage}
                      page={page-1}
                      backIconButtonProps={{
                          'aria-label': 'previous page',
                      }}
                      nextIconButtonProps={{
                          'aria-label': 'next page',
                      }}
                      onChangePage={changePage}
                      onChangeRowsPerPage={changeRowsPerPage}
                  />
                </Grid>
              </TableContainer>
            </Grid>

        </Grid>

        <Dialog
        open={openDialog}
        maxWidth={maxWidth}
        onClose={closeMainDialog}
        fullWidth
        fullScreen={isDialogFull}
        scroll="body"
        >
          {dialogContent}
        </Dialog>

        <Popover
          id='popUpFilter'
          open={Boolean(anchorFilter)}
          anchorEl={anchorFilter}
          onClose={popupFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          className={classes.popUpFilter}
          style={
            {marginTop:8}
          }
        >
          <FilterList fields={filtersAvailable} filters={filters} onItemClick={addFilter} onClose={popupFilterClose}/>
        </Popover>


        </>
    );
  }
  

export default Client;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
 toolbarContainer: {
    marginTop:theme.spacing(1)
  },
  filterContainer: {
    marginBottom:theme.spacing(1)
  },
  tableContainer: {
    minHeight:400,
  },
  clickable:{
    cursor:'pointer',
    fontWeight:'bold',
    color:theme.palette.primary.main
  },
  formContainer: {
    minHeight:'80vh',
    marginTop:theme.spacing(1)
  },
  buttonContainer:{
    marginTop:theme.spacing(2)
  },
  formPaper:{
    width:'inherit',
    padding:theme.spacing(2)
  },
  paginatonContainer: {
    height:40,
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  marginB1:{
    marginBottom:theme.spacing(1)
  },
  reactSelect:{
    width:'100%'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  closeButton:{
    top:-20,
    marginLeft:'auto',
    marginRight:0
  },
  tableContainer: {
    minHeight:400,
  },
  clickable:{
    cursor:'pointer',
    fontWeight:'bold',
    color:theme.palette.primary.main
  },
  buttonContainer:{
    marginTop:theme.spacing(2)
  },

  paginatonContainer: {
    height:40,
  }

}));

const selectCustomZindex = {
    control: (base, state) => ({
        ...base,
        minWidth: '150px',
        margin: '0 4px'
    }),
    container: (base, state) => {
        return {
            ...base,
            flex: 1,
            zIndex: state.isFocused ? '1100' : '1' //Only when current state focused
        };
    }
};