export const dummyWidgets = [
    {
        id:1,
        size:3,
        type:1,
        label:"user",
        path:"",
        color:"green"
    },
    {
        id:2,
        size:3,
        type:1,
        label:"user",
        path:"",
        color:"green"
    },
    {
        id:3,
        size:3,
        type:1,
        label:"user",
        path:"",
        color:"green"
    },
    {
        id:4,
        size:12,
        type:1,
        label:"user",
        path:"",
        color:"green"
    }
]


