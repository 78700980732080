import React,{useState, useEffect} from 'react';
import { Redirect,useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select'
import {generalListOptionMapper} from 'utils/mappers.js'
import numeral from 'numeral'
import TextField from "@material-ui/core/TextField";


const Tester =(props)=> {
  let { path, url } = useRouteMatch();
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const setting = useSelector(state => state.setting);
  const [state, setstate] = useState({
    client:null,
    testCase:null,
    balance:0,
    channel:null,
    gateway:"",
    amount:0,
    grossAmount:0,
    va:"",
    status:""
  })
  
  const [channelOptions, setchannelOptions] = useState([])
  const [clientOptions, setclientOptions] = useState([])
  
  const [caseOptions, setcaseOptions] = useState([
    {value :"parent_bill_charge",label:"parent bill charge"},
    { value :"client_bill_charge",label:"client bill charge"},
    {value :"parent_saving_charge",label:"parent saving charge"}
  ])
  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  

  const getBalance =async()=>{
    let params = { 
    
    }
    
    const response = await doGet(endpoint.wallet_balance_client.replace("id",state.client.value), params, getDefaultHeader())
    if(response){
      setstate({...state, balance:response.data})
    }
  }

  const getGateway =async(channelId)=>{
    let params = { 
    
    }
    
    const response = await doGet(endpoint.payment_channel_gateway.replace("id",channelId), params, getDefaultHeader())
    if(response){
      setstate({...state, gateway:response.data})
    }
  }

  
  const getClientOptions =async()=>{
    let params = {      
    }
    
    const response = await doGet(endpoint.client_options_field.replace("field","key"), params, getDefaultHeader())
    if(response){
      setclientOptions(generalListOptionMapper(response.data))
    }
  }

  const getChannelOptions =async()=>{
    let params = {      
    }
    
    const response = await doGet(endpoint.payment_channel_options, params, getDefaultHeader())
    if(response){
      setchannelOptions(generalListOptionMapper(response.data))
    }
  }
  
  

  const submit =async()=>{
    
    let payload=null
    let url=""
    const testCase = state.testCase? state.testCase.value : null
    const channel = state.channel? state.channel.value : null
    const client = state.client? state.client.value : null
    const amount = state.amount
    if(client && channel && testCase){

      if(testCase==="parent_bill_charge"){
        url = endpoint.parent_bill_charge
        payload={
          creator: 1,
          channel: channel,
          username: "tester",
          transaction_details: {
          amount: amount,
          order_id: `${client}-2-${Date.now()}`
          },
          customer_details: {
          email: "",
          first_name: "tester",
          last_name: "tester",
          phone: ""
          },
          item_details: [
            {
            id: "1",
            price: amount,
            quantity: 1,
            name: "test bayar"
            }
          ]
        }
      }

    }
    let headers = {
      "Authorization":`Bearer ${user.token}`,
      "client-key": state.client.key
    }
    const response = await doPost(url, payload,"charge",getDefaultHeader())  
    if(response)  {
      const chargeData = response.data
      if(chargeData.status_code==="200" || chargeData.status_code==="201"){
        if(chargeData.va_numbers){
          setstate({
            ...state, 
            va:chargeData.va_numbers[0].va_number,
          grossAmount:Number(chargeData.gross_amount),
          billKey:null,
          billerCode:null,
          status:chargeData.status_message}) 
        }
        else if(chargeData.permata_va_number){
          setstate({
            ...state, 
            va:chargeData.permata_va_number,
          grossAmount:Number(chargeData.gross_amount),
          billKey:null,
          billerCode:null,
          status:chargeData.status_message}) 
        }
        else if(chargeData.bill_key){
          setstate({
            ...state, 
            va:null,
          grossAmount:Number(chargeData.gross_amount),
          billKey:chargeData.bill_key,
          billerCode:chargeData.biller_code,
          status:chargeData.status_message}) 
        }

      }else{
        setstate({...state,status:chargeData.status_message})
      }

      
      
      
    }
  }


  const handleStateChange = (fieldName, eventValue)=>{
    let newState = {...state}
    let value = eventValue
    if(fieldName==="amount"){
      newState[fieldName] = eventValue.target.value
    }else{
      newState[fieldName] = value
    }
    
    setstate(newState)
  }



  useEffect(() => {
    getClientOptions()
    getChannelOptions()
  }, [])

  useEffect(() => {
    if(state.client){
      getBalance()
    }
  }, [state.client])

  useEffect(() => {
    if(state.channel){
      getGateway(state.channel.value)
    }
  }, [state.channel])
  

 



  if(user && user.token){
    return (
      <>
        <Grid container justify="flex-start" alignItems="flex-start" alignContent="flex-start" className={classes.root}>
            <Grid container className={classes.toolbarContainer}>
              <Grid container item alignContent="center" xs={6} className={classes.toolbarLeft}>
                <Typography color="primary" component="h5" variant="h5">
                  {setting.active_menu.display}
                </Typography>
              </Grid>              
            </Grid>           
            <Grid container spacing={1}>
              <Grid item xs={6} container style={{margin:'8px 0'}}>
                <Select
                  name="client"
                  placeholder = "client..."
                  options={clientOptions}
                  onChange={(e)=>handleStateChange("client",e)}
                  value={state.client}
                  styles={selectCustomZindex}
                />                
              </Grid>
              <Grid item xs={6} spacing={1} justify="center" alignItems="center" container style={{margin:'8px 0'}}>
                <Grid item>
                  Wallet : 
                </Grid>
                <Grid item>
                  <Typography component="h4" variant="h4">
                    {numeral(state.balance).format('0,0')}
                  </Typography>                  
                </Grid>
              </Grid>
              <Grid item container style={{margin:'8px 0'}}>
                <Select
                  name="case"
                  placeholder = "select test case..."
                  options={caseOptions}
                  onChange={(e)=>handleStateChange("testCase",e)}
                  value={state.testCase}
                  styles={selectCustomZindex}
                />                
              </Grid>
              <Grid item xs={6} container style={{margin:'8px 0'}}>
                <Select
                  name="channel"
                  placeholder = "select payment channel..."
                  options={channelOptions}
                  onChange={(e)=>handleStateChange("channel",e)}
                  value={state.channel}
                  styles={selectCustomZindex}
                />                
              </Grid>

              <Grid item xs={6} spacing={1} justify="center" alignItems="center" container style={{margin:'8px 0'}}>
                <Grid item>
                  Gateway : 
                </Grid>
                <Grid item>
                  <Typography component="h4" variant="h4">
                    {state.gateway && state.gateway.name}
                  </Typography>                  
                </Grid>
              </Grid>

              <Grid item xs={6} container style={{margin:'8px 0'}}>
                <TextField
                  type="number"              
                  margin="dense"
                  variant="outlined"
                  id="amount"
                  label="amount"
                  value={state.amount} 
                  onChange={(e)=>handleStateChange("amount",e)}
                />  
              </Grid>
              <Grid item xs={6} container justify="flex-end" style={{margin:'8px 0'}}>
                <Button variant="contained" onClick={submit} color="primary">
                  Send
                </Button>
              </Grid>
              
              <Grid item spacing={1} justify="center" alignItems="center" container style={{margin:'8px 0'}}>
                <Grid item>
                  please pay
                </Grid>
                <Grid item>
                  <Typography component="h4" variant="h4">
                    Rp. {numeral(state.grossAmount).format('0,0')}
                  </Typography>                  
                </Grid>
                <Grid item>
                  to                 
                </Grid>
                <Grid item>
                  <Typography component="h4" variant="h4">
                    {state.va}
                  </Typography>               
                </Grid>
                <Grid item>
                  <Typography component="h4" variant="h4">
                    {state.billKey}
                  </Typography>               
                </Grid>
                <Grid item>
                  <Typography component="h4" variant="h4">
                    {state.billerCode}
                  </Typography>               
                </Grid>
                <Grid container justify="center">
                  <Typography component="h5" variant="h5">
                    {state.status}
                  </Typography>               
                </Grid>
              </Grid>
              
            </Grid>
        </Grid>
        </>
    );
  }
  else{
    return <Redirect to={{ pathname: '/login' }} />
  }

}

export default Tester;



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
 toolbarContainer: {
    marginTop:theme.spacing(1)
  },
  filterContainer: {
    marginBottom:theme.spacing(1)
  },
  tableContainer: {
    minHeight:400,
  },
  clickable:{
    cursor:'pointer',
    fontWeight:'bold',
    color:theme.palette.primary.main
  },
  formContainer: {
    minHeight:'80vh',
    marginTop:theme.spacing(1)
  },
  buttonContainer:{
    marginTop:theme.spacing(2)
  },
  formPaper:{
    width:'inherit',
    padding:theme.spacing(2)
  },
  paginatonContainer: {
    height:40,
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  marginB1:{
    marginBottom:theme.spacing(1)
  },
  reactSelect:{
    width:"100%"
  },
  margin1:{
    margin: theme.spacing(1)
  },
  closeButton:{
    top:-20,
    marginLeft:"auto",
    marginRight:0
  }

}));

const selectCustomZindex = {
    control: (base, state) => ({
        ...base,
        minWidth: "150px",
        margin: "0 4px"
    }),
    container: (base, state) => {
        return {
            ...base,
            flex: 1,
            zIndex: state.isFocused ? "1100" : "1" //Only when current state focused
        };
    }
};
