import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {endpoint} from 'utils/constants.js'
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import NumberFormatCustom from 'components/NumberFormatCustom.js';
import Conditional from 'components/Conditional.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Protected   from 'components/Protected.js';
import Select from 'react-select'
import MSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NumberFormat from 'react-number-format';
import TextField from "@material-ui/core/TextField";
import { format,parse } from 'date-fns'
import SmallConfirmation   from 'components/SmallConfirmation.js';
import StatusChip from 'components/StatusChip';
import { generalListOptionMapper } from 'utils/mappers.js';


const BankForm =(props)=> {
  const {title, user, userActions, setting, handleClose, pageAction, gatewayOptions} = props
  const classes = useStyles();
  const [newBank, setnewBank] = useState(null)
  const [banks, setbanks] = useState([])  

  const [anchorSmallConfirmation, setanchorSmallConfirmation] = useState(null);  
  const [todo, settodo] = useState(null)

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  useEffect(() => {
    getbanks()  
  }, [])

  

  const addBank= async(payload)=>{    
    
    
    await doPost(endpoint.bank, payload,"insert "+setting.active_menu.display ,getDefaultHeader())
    setnewBank(null)
    getbanks()
  }

  const removeBank=async(payload)=>{
        
    
    const response = await doDelete(`${endpoint.bank}/${payload.id}`, {},"delete"+setting.active_menu.display ,getDefaultHeader())
    getbanks()
  }

  const updateBank= async(payload)=>{
    
    await doPut(endpoint.bank, payload,"update "+setting.active_menu.display ,getDefaultHeader())
    setnewBank(null)
    getbanks()
  }  
  
  const addNewClick=()=>{
    settodo("add")
    setnewBank({
      name:"",
      namecode:"",
      numbercode:"",
      status:1,
      gateways:[]
    })
  }

  const editClick=(payload)=>{    
    settodo("edit")
    const modified = {
      ...payload,      
      gateways:payload.gateways?generalListOptionMapper(payload.gateways):[]
    }    
    setnewBank(modified)
  }

  const removeClick=(payload,e)=>{
    settodo("delete")    
    setnewBank(payload)
    setanchorSmallConfirmation(e.currentTarget)
  }

  const closeSmallConfirmation=()=>{
    setanchorSmallConfirmation(null)
  }

  const newBankChange=(fieldName,eventValue)=>{        
    let newnewBank = {...newBank}
    let value = eventValue
    newnewBank[fieldName] = value
    setnewBank(newnewBank)
  }

  const getbanks =async()=>{
    let params = {}
    
    const response = await doGet(endpoint.bank, params, getDefaultHeader())    
    if(response){      
      setbanks(response.data)
    }
  } 

  const execute=()=>{

    const payload = {
      ...newBank,
      gateways: newBank.gateways?newBank.gateways.map(g=>g.value):[]
    }          
    
    if(todo==="add"){
        addBank(payload)
    }
    else if(todo==="edit"){
        updateBank(payload)
    }
    else if(todo==="delete"){
        removeBank(payload)
    }

    setnewBank(null)

}

  const cancel=()=>{
    setnewBank(null)
  }
  
  if(user && setting){
    return (
      <>
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>Bank</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} justify='space-between' className={classes.root}>
            <Grid container spacing={1} justify='space-between' className={classes.form}>
              <Grid container item xs={12} sm={12} md={10} lg={10} spacing={1} justify='flex-start'>
                {
                  newBank &&
                <>                

                <Grid item xs={12} sm={12} md={2} lg={2} >
                  <DebouncedTextField
                    margin="dense"
                    id="name"
                    label="name"
                    variant="outlined"
                    fullWidth
                    value={newBank.name}
                    onChange={(e)=>newBankChange("name",e)}
                  />
                </Grid>
  
                <Grid item xs={12} sm={12} md={2} lg={2} container alignContent='center' >
                  <DebouncedTextField
                    margin="dense"
                    id="namecode"
                    label="namecode"
                    variant="outlined"
                    fullWidth
                    value={newBank.namecode}
                    onChange={(e)=>newBankChange("namecode",e)}
                  />          
                </Grid> 

                <Grid item xs={12} sm={12} md={2} lg={2} container alignContent='center' >
                  <DebouncedTextField
                    margin="dense"
                    id="numbercode"
                    label="numbercode"
                    variant="outlined"
                    fullWidth
                    value={newBank.numbercode}
                    onChange={(e)=>newBankChange("numbercode",e)}
                  />          
                </Grid>  

                <Grid item xs={12} sm={12} md={3} lg={3} container alignContent="center">
                  <Select
                    name="gateway"
                    placeholder = "payment gateway..."
                    options={gatewayOptions}
                    onChange={(e)=>newBankChange("gateways",e)}
                    value={newBank.gateways}
                    styles={selectCustomZindex}
                    isMulti={true}
                  />                
                </Grid> 
                
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <DebouncedTextField
                    margin="dense"
                    id="status"
                    label="status"
                    variant="outlined"
                    fullWidth
                    value={newBank.status}
                    onChange={(e)=>newBankChange("status",e)}
                  />
                </Grid>               
                  
                </>
              }
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} container justify="flex-end" alignContent="center">
                <Conditional condition={newBank===null}>                                      
                      <Protected user={user}  userActions={userActions} allowedCodes={['CBANK']} activeMenu={setting.active_menu}>
                        <Button size="small" variant="contained" color="primary" className={classes.smallButton} onClick={addNewClick}>Tambah</Button>
                      </Protected>                    
                </Conditional>
                <Conditional condition={newBank!==null}>                  
                      <Button size="small" variant="contained" className={classes.smallButton} onClick={cancel}>Cancel</Button>                  
                      <Button size="small" variant="contained" color="primary" className={classes.smallButton} onClick={execute}>Simpan</Button>                  
                </Conditional>
              </Grid>
            </Grid>
            <Grid container>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead className={classes.backgroundPrimary}>
                  <TableRow>
                    <TableCell style={{color:"#ffffff"}}>ID</TableCell>
                    <TableCell style={{color:"#ffffff"}}>Name</TableCell>
                    <TableCell style={{color:"#ffffff"}}>Numbercode</TableCell>
                    <TableCell style={{color:"#ffffff"}}>Namecode</TableCell>
                    <TableCell style={{color:"#ffffff"}}>Gateways</TableCell>
                    <TableCell style={{color:"#ffffff"}}>Status</TableCell>
                    <TableCell style={{color:"#ffffff"}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {banks && banks.map((bank) => (
                    <TableRow key={bank.id}>
                      <TableCell>{bank.id}</TableCell>
                      <TableCell>{bank.name}</TableCell>
                      <TableCell>{bank.numbercode}</TableCell>
                      <TableCell>{bank.namecode}</TableCell>
                      <TableCell>{
                      bank.gateways.map(g=>
                        (
                        <span style={{margin:4}}>{g.name}</span>
                        )
                      )
                      }</TableCell>
                      <TableCell>{bank.status}</TableCell>
                      <TableCell align="center">                        
                          <Protected user={user}  userActions={userActions} allowedCodes={['EBANK']} activeMenu={setting.active_menu}>
                            <IconButton
                            onClick={()=>editClick(bank)}
                            size="small"
                            aria-label="close"
                            className={clsx(classes.margin1,classes.closeButton)}>
                              <Edit fontSize="inherit" />
                            </IconButton>
                          </Protected>
                          <Protected user={user}  userActions={userActions} allowedCodes={['DBANK']} activeMenu={setting.active_menu}>
                            <IconButton
                            onClick={(e)=>removeClick(bank,e)}
                            size="small"
                            aria-label="close"
                            className={clsx(classes.margin1,classes.closeButton)}>
                              <Cancel fontSize="inherit" />
                            </IconButton>
                            <SmallConfirmation
                              anchor={anchorSmallConfirmation}
                              handleClose={closeSmallConfirmation}
                              execute={execute}
                              actionName={todo}
                            />                            
                          </Protected>                        
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </Grid>
          
  
          </Grid>
        </DialogContent>
        <DialogActions>        
          <Button variant="contained" onClick={handleClose} color="primary">
            Kembali
          </Button>
        </DialogActions>
  </>
  
      );
  
  }else{
    return null
  }

  }

export default BankForm;

const useStyles = makeStyles((theme) => ({
  dialogTitle:{    
           
  },

  dialogContent:{
    paddingTop:theme.spacing(2),
    background:'linear-gradient(90deg, #e0eafc 0%,#cfdef3 100% )',    
    borderTop: '1px solid',
    borderColor:theme.borderColor,
  },
  smallButton:{
    height:30,
    padding:'0 4px 0 4px',
    fontSize:'0.7em',
    margin:'0 4px 0 4px'
  },
  margin1:{
    margin: theme.spacing(1)
  },
  content:{
    backgroundColor:"#ffffff",
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    width:'inherit',
  },
  header:{
    borderRadius: theme.borderRadius,
    backgroundColor:theme.palette.primary.main,
    color:'#ffffff',
    marginBottom: theme.spacing(1)
  },
  backgroundPrimary:{
    backgroundColor:theme.palette.primary.main,
    color:"#ffffff"
  },
  form:{
    marginBottom:8
  }
}));

const selectCustomZindex = {
  control: (base, state) => ({
      ...base,
      minWidth: "150px",
      margin: "0 4px"
  }),
  container: (base, state) => {
      return {
          ...base,
          flex: 1,
          zIndex: state.isFocused ? "1100" : "1" //Only when current state focused
      };
  }
};

